import React, { useEffect, useState } from "react";
import DynamicForm from "src/components/organisms/DynamicForm";
import CommonPopup from "src/components/molecules/CommonPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClinicians,
  manageProvider,
  fetchSpecialties,
  fetchProviderFee
} from "src/store/actions/clinicianActions"; // Assuming manageProvider handles both add and edit
import { ColorPicker } from "@progress/kendo-react-inputs"; // Color Picker for Schedule
import { clinicianModel } from "src/models/clinicianModel";
const clientId = 1;


const staticStateLicense = [
  { label: "None", value: "None" },
  { label: "License 1", value: "License 1" },
  { label: "License 2", value: "License 2" }
];

const staticStateWhereLicensed = [
  { label: "Alaska", value: "Alaska" },
  { label: "California", value: "California" },
  { label: "Texas", value: "Texas" }
];

const ClinicianPopup = ({ show, onClose, mode = "add", initialData = {}, onSave }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  console.log("initialdata", initialData, formData);
  // Fetch specialties from the store
  const specialties =
    useSelector((state) =>
      state.clinicians.specialties?.map((x) => {
        return { label: x.name, value: x.id };
      })
    ) || [];

  const providerFee =
    useSelector((state) =>
      state.clinicians.providerFee?.map((x) => {
        return { label: x.name, value: x.id };
      })
    ) || [];

  console.log("providerFee", providerFee);

  useEffect(() => {
    console.log("here in disp....");
    dispatch(fetchSpecialties())
    dispatch(fetchProviderFee(clientId)).then((x) => {
      console.log("fetchProviderFee", x);
    });
  }, [dispatch]);
  useEffect(() => {
    if (mode === "edit" && initialData) {
      // Populate formData for edit mode
      setFormData({
        ...clinicianModel,
        ...initialData
      });
    } else {
      // Set defaults for add mode
      setFormData({});
    }
  }, [mode, initialData]);

  const handleSave = async (formDataprop) => {
    let formDataapi;
    if (mode === "add") formDataapi = { ...clinicianModel, ...formDataprop };
    else formDataapi = { ...formData, ...formDataprop };

    console.log("formdataaa", formDataapi);
    const requestType = mode === "add" ? 1 : 2; // Set requestType to 1 for Add, 2 for Edit

    // Prepare FormData
    const formDataToSend = new FormData();
    console.log("formDataToSend", formDataToSend);

    formDataapi.createdBy = 1;
    formDataapi.updatedBy = 1;
    console.log("formdata", formData);

    // Append all form data including files and empty fields
    Object.keys(formDataapi).forEach((key) => {
      console.log("Key:", key); // Log the key being appended to FormData
      console.log("Value:", formDataapi[key]); // Log the value of the key
      if (mode === "add" && (key === "id" || key === "providerID")) {
        formDataToSend.append(`Provider.${key}`, 0); // Append the file (e.g., photoPath, docPath)
      } else if (formDataapi[key] instanceof File) {
        formDataToSend.append(`${key}`, formDataapi[key]); // Append the file (e.g., photoPath, docPath)
      } else {
        // For non-file fields, we append their value (empty string if not set)
        formDataToSend.append(`Provider.${key}`, formDataapi[key] || "");
      }
    });

    console.log("formdataa", formData, formDataapi, formDataToSend);
    // Add requestType to FormData
    formDataToSend.append("requestType", requestType);

    // Dispatch the action for Add/Edit
    await dispatch(manageProvider(formDataToSend)); // Dispatch manageProvider action for both Add and Edit
    dispatch(fetchClinicians(clientId));
    onSave(formDataToSend); // Trigger onSave callback after saving
  };

  const tabs = [
    {
      name: mode === "add" ? "Add Clinician" : "Edit Clinician",
      sections: [
        {
          fields: [
            {
              name: "fName",
              type: "input",
              label: "First Name",
              value: formData.fName,
              required: true
            },
            {
              name: "mi",
              type: "input",
              label: "Middle Name",
              value: formData.mi
            },
            {
              name: "lName",
              type: "input",
              label: "Last Name",
              value: formData.lName,
              required: true
            },
            {
              name: "abbr",
              type: "input",
              label: "Title",
              value: formData.abbr || ""
            },
            {
              name: "suffix",
              type: "input",
              label: "Initials",
              value: formData.suffix,
              required: true
            },
            {
              name: "username",
              type: "input",
              label: "Username",
              value: formData.username || "N/A"
            },
            {
              name: "ssn",
              type: "input",
              label: "SSN or TIN",
              value: formData.ssn,
              required: true
            },
            {
              name: "mobilePhone",
              type: "input",
              label: "Mobile Phone",
              value: formData.mobilePhone
            },
            {
              name: "stateLicense",
              type: "select",
              label: "State License",
              options: staticStateLicense,
              value: formData.stateLicense
            },
            {
              name: "nationalProvID",
              type: "input",
              label: "NPI",
              value: formData.nationalProvID || ""
            },
            {
              name: "deaNum",
              type: "input",
              label: "DEA License",
              value: formData.deaNum || ""
            },
            {
              name: "stateRxID",
              type: "input",
              label: "State Rx ID",
              value: formData.stateRxID || ""
            },
            {
              name: "feeSched",
              type: "select",
              label: "Fees",
              options: providerFee,
              value: formData.feeSched
            },
            {
              name: "specialty",
              type: "select",
              label: "Specialty",
              options: specialties, // Use fetched specialties here
              value: formData.specialty
            },
            {
              name: "schedule",
              type: "color",
              label: "Schedule",
              value: formData.provColor || "#000000" // default color
            },
            {
              name: "sigOnFile",
              type: "checkbox",
              label: "Signature on File",
              value: formData.sigOnFile
            },
            {
              name: "isNotPerson",
              type: "checkbox",
              label: "Corporation",
              value: formData.isNotPerson
            },
            {
              name: "isInstructor",
              type: "checkbox",
              label: "Hygienist",
              value: formData.isInstructor
            },
            {
              name: "isActive",
              type: "checkbox",
              label: "Inactive",
              value: formData.isActive
            },
            {
              name: "timePunch",
              type: "switch",
              label: "Time Punch",
              value: formData.timePunch
            },
            {
              name: "providerNote",
              type: "textarea",
              label: "Note",
              value: formData.providerNote || ""
            },
            {
              name: "file",
              type: "fileupload",
              label: "Photo",
              value: formData.file
            },
            {
              name: "docPath",
              type: "file",
              label: "Document",
              value: formData.docPath
            },
            {
              name: "billUnder",
              type: "select",
              label: "Bill Under",
              options: [
                { label: "Afredo Lim", value: "Afredo Lim" },
                { label: "Other", value: "Other" }
              ],
              value: formData.billUnder || "Afredo Lim"
            },
            {
              name: "emailAddressNum",
              type: "input",
              label: "Email Address",
              value: formData.emailAddressNum || ""
            },
            {
              name: "stateWhereLicensed",
              type: "select",
              label: "State Where Licensed",
              options: staticStateWhereLicensed,
              value: formData.stateWhereLicensed || "Alaska"
            },
            {
              name: "preferredName",
              type: "input",
              label: "Preferred Name",
              value: formData.preferredName || ""
            },
            {
              name: "webSchedDescript",
              type: "input",
              label: "Web Schedule Description",
              value: formData.webSchedDescript || ""
            },
            {
              name: "noteSignature",
              type: "fileupload",
              label: "Note Signature",
              value: formData.noteSignature || ""
            },
            {
              name: "showmySchedule",
              type: "switch",
              label: "Default to My Schedule",
              value: formData.showmySchedule
            },
            {
              name: "specialBilling",
              type: "checkbox",
              label: "Special Billing",
              value: formData.specialBilling
            }
          ]
        }
      ],
      footer: {
        right: [
          {
            type: "button",
            icon: "cancel",
            text: "Cancel",
            themeColor: "secondary",
            onClick: onClose
          },
          {
            type: "button",
            icon: "save",
            text: mode === "add" ? "Add" : "Update",
            themeColor: "primary"
            // onClick: handleSave
          }
        ]
      }
    }
  ];

  return (
    <CommonPopup
      show={show}
      onClose={onClose}
      title={mode === "add" ? "Add Clinician" : "Edit Clinician"}
    >
      <DynamicForm
        tabs={tabs}
        formData={formData}
        setFormData={setFormData}
        mode={mode}
        handleFormSubmit={handleSave}
      />
    </CommonPopup>
  );
};

export default ClinicianPopup;
