export const getGenderLabel = (gender) => {
  switch (gender) {
    case 0:
      return "Male";
    case 1:
      return "Female";
    case 2:
      return "Other";
    case 3:
      return "Unknown";
    default:
      return "Unknown";
  }
};
