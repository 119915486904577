import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchFeeScheduleByClient = createAsyncThunk(
  "patient/fetchFeeScheduleByClient",
  async (clientId) => {
    try {
      const response = await httpClient.post(
        `/FeeSchedule/getFeeScheduleByclient?clientId=${clientId}`
      ); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching fee schedule code list");
    }
  }
);
