import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  depositslips: [],
  loading: false,
  error: null
};

const depositslipSlice = createSlice({
  name: "depositslip",
  initialState,
  reducers: {
    setDepositslips: (state, action) => {
      state.depositslips = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setDepositslips, setLoading, setError } = depositslipSlice.actions;
export default depositslipSlice.reducer;
