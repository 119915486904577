import AdminDepositslipService from "src/services/admin/depositslip";
import { setDepositslips, setError, setLoading } from "src/store/slices/admin/depositslipSlice";

export const fetchDepositslips = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AdminDepositslipService.getDepositslipsList("/admin/depositslips");
    dispatch(setDepositslips(response));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
