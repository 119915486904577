import axios from "axios";
import { showError, setLoading } from "src/store/slices/appSlice";
import store from "src/store/store";
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5093/api";

// Add request counter
let requestCounter = 0;

const updateLoadingState = (increment = true) => {
  requestCounter = increment ? requestCounter + 1 : requestCounter - 1;
  store.dispatch(setLoading(requestCounter > 0));
};

console.log("Base URL:", process.env);

const axiosInstance = axios.create({
  baseURL: BASE_URL
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const httpClient = async (
  url,
  options = { method: "GET", body: {}, headers: {}, responseType: "" }
) => {
  const contentType = options.body instanceof FormData ? "multipart/form-data" : "application/json";
  const headers = {
    "Content-Type": contentType,
    ...options.headers
  };

  try {
    const opts = {
      url,
      method: options.method,
      data: options.body,
      headers,
      responseType: options.responseType || "json"
    };

    updateLoadingState(true); // Increment counter and set loading

    const response = await axiosInstance(opts);
    if (options.responseType === "blob") {
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition.split(";")[1].split("=")[1].replace(/['"]/g, "");
      if (options.inline) {
        return {
          blob: response.data,
          fileName
        };
      } else {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        return true;
      }
    }

    return response.data;
  } catch (error) {
    console.error("HTTP client error:", error);
    store.dispatch(showError(error.message)); // Use showError directly
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    throw error;
  } finally {
    updateLoadingState(false); // Decrement counter and update loading state
  }
};

// Add method-specific APIs
httpClient.get = (url, options = {}) => {
  return httpClient(url, { ...options, method: "GET" });
};

httpClient.post = (url, body, options = {}) => {
  return httpClient(url, { ...options, method: "POST", body });
};

httpClient.put = (url, body, options = {}) => {
  return httpClient(url, { ...options, method: "PUT", body });
};

httpClient.delete = (url, options = {}) => {
  return httpClient(url, { ...options, method: "DELETE" });
};

httpClient.patch = (url, body, options = {}) => {
  return httpClient(url, { ...options, method: "PATCH", body });
};

export default httpClient;
export { httpClient };
