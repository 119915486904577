import React, { useEffect, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "./PatientPage.css"; // Import custom styles
import Profile from "./TabComponents/Profile";
import ExamsAndHygiene from "./TabComponents/ExamsAndHygiene";
import PatientBackground from "./TabComponents/PatientBackground";
import Insurance from "./TabComponents/Insurance";
import HealthInfoTabs from "./HealthInfo";
import CommonPopup from "../../molecules/CommonPopup";
import InsurancePlan from "./PopUps/Insurance/InsurancePlan"; // Import AddInsurancePlan component
import AddEditPatient from "./PopUps/AddPatient";
import PatientForms from "./Forms/PatientForms";
import usePatient from "src/hooks/use-patient";
import Conversations from "./Conversations";
import Letters from "./Letters";
import NavbarTemplate from "src/components/templates/NavbarTemplate";
import ActionButton from "src/components/atoms/ActionButton";
import PatientVisitPopup from "./PopUps/PatientVisit";
import FillChairHygieneRecarePopup from "./PopUps/HygineRecare";
import NoteTemplatePopup from "./PopUps/NoteTemplates";
import AlertListComponent from "./PopUps/AlertListComponent";
import usePopupVisibility from "src/hooks/usePopupVisibility"; // Import usePopupVisibility hook
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInsuranceDetails,
  fetchProfileDetails,
  fetchProfileStrip
} from "src/store/actions/patientAction";
import {
  selectInsuranceDetails,
  selectProfileDetails,
  selectProfileStrip
} from "src/store/slices/patientSlice";
import PatientCard from "./PatientPanel/PatientCard";
import { useParams } from "react-router-dom";

const PatientPage = () => {
  const { patientId } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);
  const { popups, openPopup, closePopup } = usePopupVisibility();

  const dispatch = useDispatch();

  const profile = useSelector((state) => selectProfileDetails(state));
  const insurance = useSelector((state) => selectInsuranceDetails(state));

  useEffect(() => {
    console.log("patientId in patientpage", patientId);
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchProfileDetails(patientId));
    dispatch(fetchInsuranceDetails(patientId));
  }, [dispatch, patientId]);

  const profileStrip = useSelector((state) => selectProfileStrip(state));

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchProfileStrip(patientId));
  }, [dispatch, patientId]);

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const onHealthInfoClose = () => {
    setSelectedTab(0);
  };

  const closePatientPopup = (x) => {
    closePopup("editPatient");
    if (x == 1)
      setTimeout(() => {
        dispatch(fetchProfileStrip(patientId));
        dispatch(fetchProfileDetails(patientId));
      }, 2000);
  };

  // Rendering different buttons for each tab and showing relevant popups
  const renderTabButtons = () => {
    if (selectedTab === 0) {
      return (
        <>
          <ActionButton icon="edit" onClick={() => openPopup("editPatient")}>
            Edit
          </ActionButton>
          <ActionButton icon="delete">Delete</ActionButton>
        </>
      );
    } else if (selectedTab === 1) {
      return (
        <>
          <ActionButton icon="calendar" onClick={() => openPopup("patientVisit")}>
            All Visit
          </ActionButton>
          <ActionButton icon="plus" onClick={() => openPopup("hygieneRecare")}>
            Recare
          </ActionButton>
        </>
      );
    } else if (selectedTab === 2) {
      return (
        <>
          <ActionButton icon="chat" onClick={() => openPopup("noteTemplate")}>
            Note template
          </ActionButton>
          <ActionButton icon="bell" onClick={() => openPopup("AlertListComponent")}>
            Alert
          </ActionButton>
        </>
      );
    } else {
      return (
        <>
          <ActionButton icon="edit" onClick={() => openPopup("editInsurancePlan")}>
            Edit
          </ActionButton>
          <ActionButton icon="plus" onClick={() => openPopup("addInsurancePlan")}>
            Add Plan
          </ActionButton>
        </>
      );
    }
  };

  return (
    <NavbarTemplate>
      {/* Main content */}
      <PatientCard patient={profileStrip} />
      <div className="patient-page-main-content">
        <div className="tab-buttons-container">
          <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            <TabStripTab title="Profile" />
            <TabStripTab title="Exams and Hygiene" />
            <TabStripTab title="Patient Background" />
            <TabStripTab title="Insurance" />
            <TabStripTab title="Health Info" />
            <TabStripTab title="Conversations" />
            <TabStripTab title="Letters" />
            <TabStripTab title="Patient Forms" />
          </TabStrip>
          <div className="patient-tab-buttons">{renderTabButtons()}</div>
        </div>

        {/* Tab Content */}
        <div className="patient-tab-content">
          {selectedTab === 0 && <Profile patient={profile} />}
          {selectedTab === 1 && <ExamsAndHygiene patientId={patientId} />}
          {selectedTab === 2 && <PatientBackground />}
          {selectedTab === 3 && <Insurance insurance={insurance} />}
          {selectedTab === 4 && (
            <CommonPopup title={"Health Info"} onClose={onHealthInfoClose} show>
              <HealthInfoTabs patientId={patientId} />
            </CommonPopup>
          )}
          {selectedTab === 5 && (
            <CommonPopup title={"Conversations"} onClose={onHealthInfoClose} show>
              <Conversations />
            </CommonPopup>
          )}
          {selectedTab === 6 && (
            <CommonPopup title={"Letters"} onClose={onHealthInfoClose} show>
              <Letters />
            </CommonPopup>
          )}
          {selectedTab === 7 && (
            <CommonPopup
              title={`Patient Forms For ${profile?.name}`}
              onClose={onHealthInfoClose}
              show
            >
              <PatientForms />
            </CommonPopup>
          )}
        </div>
      </div>

      {/* Popups */}
      {popups.editPatient && (
        <AddEditPatient
          show={popups.editPatient}
          onClose={closePatientPopup}
          mode="edit"
          initialData={{ ...profile, birthdate: new Date(profile?.birthdate) }}
        />
      )}

      {popups.patientVisit && (
        <PatientVisitPopup show={popups.patientVisit} onClose={() => closePopup("patientVisit")} />
      )}

      {popups.hygieneRecare && (
        <FillChairHygieneRecarePopup
          show={popups.hygieneRecare}
          onClose={() => closePopup("hygieneRecare")}
        />
      )}

      {popups.noteTemplate && (
        <NoteTemplatePopup show={popups.noteTemplate} onClose={() => closePopup("noteTemplate")} />
      )}

      {popups.AlertListComponent && (
        <AlertListComponent
          show={popups.AlertListComponent}
          onClose={() => closePopup("AlertListComponent")}
        />
      )}

      {popups.addInsurancePlan && (
        <InsurancePlan
          show={popups.addInsurancePlan}
          onClose={() => closePopup("addInsurancePlan")}
        />
      )}

      {popups.editInsurancePlan && (
        <InsurancePlan
          show={popups.editInsurancePlan}
          onClose={() => closePopup("editInsurancePlan")}
          mode="edit"
          initialData={insurance}
        />
      )}
    </NavbarTemplate>
  );
};

export default PatientPage;
