import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProfileDetails,
  fetchInsuranceDetails,
  fetchProfileStrip,
  fetchSearchPatient,
  getPatientRecall
} from "../actions/patientAction";

const patientSlice = createSlice({
  name: "patient",
  initialState: {
    profileDetails: null,
    profileStrip: null,
    patientSearchResults: null,
    patientInsurance: null,
    patientRecall: [],
    loading: {
      profileStrip: false,
      profileDetails: false,
      patientRecall: false
    },
    error: {
      profileStrip: null,
      profileDetails: null,
      patientInsurance: null,
      patientRecall: []
    }
  },

  reducers: {
    setPatient(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileDetails.pending, (state) => {
        state.loading.profileDetails = true;
        state.error.profileDetails = null;
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        state.loading.profileDetails = false;
        state.profileDetails = action.payload;
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        state.loading.profileDetails = false;
        state.error.profileDetails = action.payload;
      });

    builder
      .addCase(fetchProfileStrip.pending, (state) => {
        state.loading.profileStrip = true;
        state.error.profileStrip = null;
      })
      .addCase(fetchProfileStrip.fulfilled, (state, action) => {
        state.loading.profileStrip = false;
        state.profileStrip = action.payload;
      })
      .addCase(fetchProfileStrip.rejected, (state, action) => {
        state.loading.profileStrip = false;
        state.error.profileStrip = action.payload;
      });

    builder
      .addCase(fetchSearchPatient.pending, (state) => {
        state.loading.patientSearch = true;
        state.error.patientSearch = null;
      })
      .addCase(fetchSearchPatient.fulfilled, (state, action) => {
        state.loading.patientSearch = false;
        state.patientSearchResults = action.payload;
      })
      .addCase(fetchSearchPatient.rejected, (state, action) => {
        state.loading.patientSearch = false;
        state.error.patientSearch = action.payload;
      });

    builder
      .addCase(fetchInsuranceDetails.pending, (state) => {
        state.loading.profileDetails = true;
        state.error.patientInsurance = null;
      })
      .addCase(fetchInsuranceDetails.fulfilled, (state, action) => {
        state.loading.profileDetails = false;
        state.patientInsurance = action.payload[0];
      })
      .addCase(fetchInsuranceDetails.rejected, (state, action) => {
        state.loading.profileDetails = false;
        state.error.patientInsurance = action.payload;
      });

    builder
      .addCase(getPatientRecall.pending, (state) => {
        state.loading.patientRecall = true;
        state.error.patientRecall = null;
      })
      .addCase(getPatientRecall.fulfilled, (state, action) => {
        state.loading.patientRecall = false;
        state.patientRecall = action.payload;
      })
      .addCase(getPatientRecall.rejected, (state, action) => {
        state.loading.patientRecall = false;
        state.error.patientRecall = action.payload;
      });
  }
});

export const selectProfileStrip = (state) => state.patient.profileStrip;
export const selectProfileDetails = (state) => state.patient.profileDetails;
export const selectPatientSearchResults = (state) => state.patient.patientSearchResults;
export const selectInsuranceDetails = (state) => state.patient.patientInsurance;
export const selectPatientLoading = (state) => state.patient.loading;
export const selectPatientError = (state) => state.patient.error;
export const selectPatientRecall = (state) => state.patient.patientRecall;
export const { setPatient } = patientSlice.actions;
export default patientSlice.reducer;
