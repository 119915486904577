import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonPopup from "src/components/molecules/CommonPopup";
import DynamicForm from "src/components/organisms/DynamicForm";
import { fetchVitalSignsByPatientId, saveVitalSigns } from "src/store/actions/vitalSignsActions";
import { VitalSignsModel } from "src/models/request/vitalSignsModel";
import usePatient from "src/hooks/use-patient";

const VitalSignsPopup = ({ show, onClose, mode = "add", initialData = {}, onSubmit }) => {
  const dispatch = useDispatch();

  // Retrieve global state values for patientId, clinicId, and createdBy
  const patientId = usePatient()?.patientId;
  const clinicId = useSelector((state) => state.clinics.selectedClinic?.id || 0);
  // const createdBy = useSelector((state) => state.auth?.user?.email || 0);
  const createdBy = 1;

  const [formData, setFormData] = useState({ ...VitalSignsModel });

  useEffect(() => {
    if (mode === "edit" && initialData) {
      // Populate formData for edit mode
      setFormData({
        requestType: 2,
        patientVitalSign: {
          ...initialData,
          patientId,
          clinicId,
          createdBy
        }
      });
    } else {
      // Set defaults for add mode
      setFormData({
        requestType: 1,
        patientVitalSign: {
          ...VitalSignsModel.patientVitalSign,
          patientId,
          clinicId,
          createdBy
        }
      });
    }
  }, [mode, initialData, patientId, clinicId, createdBy]);

  const handleInputChange = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      patientVitalSign: {
        ...prev.patientVitalSign,
        [name]: value
      }
    }));
  };

  const handleFormSubmit = async () => {
    try {
      await dispatch(saveVitalSigns(formData));
      dispatch(fetchVitalSignsByPatientId(patientId)); // Refresh grid after save
      onSubmit && onSubmit();
      onClose();
    } catch (error) {
      console.error("Error saving vital signs:", error);
    }
  };

  const tabs = [
    {
      name: mode === "add" ? "Add Vital Signs" : "Edit Vital Signs",
      sections: [
        {
          fields: [
            {
              name: "dateTaken",
              type: "date",
              label: "Date",
              value: formData.patientVitalSign.dateTaken || new Date(),
              required: true
            },
            {
              name: "pulse",
              type: "numerictextbox",
              label: "Pulse (BPM)",
              value: formData.patientVitalSign.pulse || 0,
              step: 1
            },
            {
              name: "bpSystolic",
              type: "numerictextbox",
              label: "Systolic BP",
              value: formData.patientVitalSign.bpSystolic || 0,
              step: 1
            },
            {
              name: "bpDiastolic",
              type: "numerictextbox",
              label: "Diastolic BP",
              value: formData.patientVitalSign.bpDiastolic || 0,
              step: 1
            },
            {
              name: "height",
              type: "numerictextbox",
              label: "Height (in)",
              value: formData.patientVitalSign.height || 0.0,
              step: 0.1
            },
            {
              name: "weight",
              type: "numerictextbox",
              label: "Weight (lbs)",
              value: formData.patientVitalSign.weight || 0.0,
              step: 0.1
            },
            {
              name: "bmiPercentile",
              type: "numerictextbox",
              label: "BMI",
              value: formData.patientVitalSign.bmiPercentile || 0.0,
              step: 0.1,
              disabled: true
            }
          ]
        }
      ],
      footer: {
        right: [
          {
            type: "button",
            icon: "save",
            className: "save-button",
            text: "Save",
            onClick: handleFormSubmit
          }
        ]
      }
    }
  ];

  return (
    <CommonPopup show={show} onClose={onClose} title={tabs[0].name}>
      <DynamicForm
        tabs={tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData.patientVitalSign}
        handleInputChange={handleInputChange}
        mode={mode}
      />
    </CommonPopup>
  );
};

export default VitalSignsPopup;
