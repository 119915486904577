// ScheduleHours.js
import React from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";

const timeOptions = [
  "12:00 AM",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM"
].map((time) => ({ text: time, value: time }));

const ScheduleHours = ({ hours, handleToggle, handleTimeChange }) => {
  const borderStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    position: "relative",
    margin: "20px"
  };

  const titleStyle = {
    position: "absolute",
    top: "-12px",
    left: "20px",
    fontWeight: "bold",
    backgroundColor: "#1a262f",
    padding: "0 5px",
    color: "#ddd"
  };

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  return (
    <div style={borderStyle}>
      <div style={titleStyle}>Schedule Hours</div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          marginTop: "15px",
          marginBottom: "15px"
        }}
      >
        {daysOfWeek.map((day) => (
          <div key={day} style={{ width: "calc(100% / 7 - 20px)" }}>
            <label style={{ display: "flex", alignItems: "center" }}>
              <strong style={{ marginRight: "10px" }}>{day}:</strong>
              <Switch checked={hours[day].isOpen} onChange={() => handleToggle(day)} />
            </label>
            <div style={{ marginTop: "10px" }}>
              <ComboBox
                data={timeOptions}
                textField="text"
                dataItemKey="value"
                value={timeOptions.find((opt) => opt.value === hours[day].startTime)}
                onChange={(e) => handleTimeChange(day, "startTime", e.target.value)}
                style={{ width: "100%" }}
                clearButton={false}
                placeholder="Select Start Time"
                filterable={true}
              />
              <div style={{ marginTop: "10px" }}>
                <ComboBox
                  data={timeOptions}
                  textField="text"
                  dataItemKey="value"
                  value={timeOptions.find((opt) => opt.value === hours[day].endTime)}
                  onChange={(e) => handleTimeChange(day, "endTime", e.target.value)}
                  style={{ width: "100%" }}
                  clearButton={false}
                  placeholder="Select End Time"
                  filterable={true}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleHours;
