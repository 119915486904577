import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import {
  fetchInsuranceCompanies,
  deleteInsuranceComapny
} from "src/store/actions/admin/insuranceActions";
import { setInsuranceCompanyStatus } from "src/store/slices/admin/insuranceSlice";
import AdminAddEditPopup from "../AdminAddEditPopup";

const AdminInsuranceCompanies = () => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);

  const dispatch = useDispatch();
  const [localCompanies, setLocalCompanies] = useState([]);
  const { insuranceCompanies, loading, insuranceCompanyStatusIsActive } = useSelector(
    (state) => state.admin.insurance
  );

  useEffect(() => {
    // Only fetch if insuranceCompanies is empty
    if (!insuranceCompanies?.length) {
      dispatch(fetchInsuranceCompanies(insuranceCompanyStatusIsActive));
    }
  }, [dispatch, insuranceCompanies]);

  useEffect(() => {
    setLocalCompanies(insuranceCompanies);
  }, [insuranceCompanies]);

  const formFields = [
    { field: "insuranceCompanyName", label: "Insurance" },
    { field: "phone", label: "Phone" },
    { field: "address", label: "Address" },
    { field: "city", label: "City" },
    { field: "state", label: "State" },
    { field: "zip", label: "Zip" },
    { field: "eclaimid", label: "eClaim ID" },
    { field: "plans", label: "Plans" },
    {
      field: "isActive",
      label: "Status",
      type: "select",
      options: [
        { text: "Active", value: true },
        { text: "Inactive", value: false }
      ]
    },
    { field: "members", label: "Members" }
  ];

  const columns = [
    { field: "insuranceCompanyName", title: "Insurance" },
    { field: "phone", title: "Phone" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "zip", title: "Zip" },
    { field: "eclaimid", title: "eClaim ID" },
    { field: "plans", title: "Plans" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => {
        setShowEditPopup(true);
        setSelectedCompany(dataItem);
        console.log("Edit company:", dataItem);
      }
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => {
        if (confirm(`Are you sure you want to delete?`)) {
          dispatch(deleteInsuranceComapny(dataItem.id)).then(() =>
            setLocalCompanies((prev) => prev.filter((company) => company.id !== dataItem.id))
          );
          dispatch(fetchInsuranceCompanies(insuranceCompanyStatusIsActive));
        }
      }
    }
  ];

  const onStatusChange = (status) => {
    const isActive = status === "Active";
    dispatch(setInsuranceCompanyStatus(isActive));
    dispatch(fetchInsuranceCompanies(isActive));
  };

  const handleClose = () => {
    setShowEditPopup(false);
    setSelectedCompany(null);
  };

  const handleUpdate = (updatedData) => {
    // Handle the update logic here
    console.log("Updated company:", updatedData);
    setShowEditPopup(false);
    setSelectedCompany(null);
  };
  const handleAdd = () => {
    setShowAddPopup(true);
  };

  return (
    <DatatableLayout
      title="Insurance Companies"
      statusOptions={["Active", "Inactive"]}
      status={insuranceCompanyStatusIsActive === true ? "Active" : "Inactive"}
      onStatusChange={onStatusChange}
      onAdd={handleAdd}
      addBtnLabel="Insurance"
      extraBtnLabel={["Merge"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      {showEditPopup && (
        <AdminAddEditPopup
          title="Edit Insurance Company"
          show={showEditPopup}
          onClose={handleClose}
          data={selectedCompany}
          actionType="editcompany"
          formFields={formFields}
          onUpdate={(updatedData) => {
            handleUpdate(updatedData);
            handleClose();
            dispatch(fetchInsuranceCompanies(insuranceCompanyStatusIsActive));
          }}
        />
      )}
      {showAddPopup && (
        <AdminAddEditPopup
          title="Add Insurance Company"
          actionType="addcompany"
          show={showAddPopup}
          formFields={formFields}
          onClose={() => {
            setShowAddPopup(false);
            dispatch(fetchInsuranceCompanies(insuranceCompanyStatusIsActive));
          }}
          data={{}}
        />
      )}

      <DynamicGrid
        columns={columns}
        rows={localCompanies}
        allowSort={false}
        allowFilter={false}
        actions={actions}
        loading={loading}
        pageable={true}
        pageSize={10}
      />
    </DatatableLayout>
  );
};

export default AdminInsuranceCompanies;
