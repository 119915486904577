import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchAppointmentTypeList = createAsyncThunk(
  "patient/fetchAppointmentTypeList",
  async () => {
    try {
      const response = await httpClient.get(`/AppointmentType/getWithProcedureCodes`); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching Provider details");
    }
  }
);
