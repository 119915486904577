import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field } from "@progress/kendo-react-form";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Button from "src/components/atoms/Button";
import {
  addSAClient,
  editSAClient,
  fetchStates,
  fetchZipcode,
  getUserData
} from "src/services/admin/saclient";
import { useDispatch } from "react-redux";
import { showNotification } from "src/store/slices/appSlice";

const ClientAddEditpopup = ({ title, actionType, show, onClose, data }) => {
  const [stateOptions, setStateOptions] = useState([]);
  const [zipOptions, setZipOptions] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectedZip, setSelectedZip] = useState("");
  const [admin, setAdmin] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.adminId) {
      // fetch user data
      //  use data.adminId to fetch user data use id 22 for testing then email is remove from admin
      getUserData(data.adminId).then((userData) => {
        setAdmin(userData);
      });
    }
  }, [data]);

  useEffect(() => {
    const getStates = async () => {
      try {
        const statesData = await fetchStates();
        const options =
          statesData?.map((state) => ({
            text: state?.stateName || "",
            value: state?.stateID || "",
            dropdowntype: "state"
          })) || [];
        setStateOptions(options);

        if (data.stateId) {
          setSelectedStateId(data.stateId);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
        setStateOptions([]);
      }
    };

    getStates();
  }, [data.stateId]);

  useEffect(() => {
    if (selectedStateId) {
      const getCities = async () => {
        try {
          const Data = await fetchZipcode(selectedStateId);
          const options =
            Data?.map((citydata) => ({
              text: citydata?.zipCodeDigits || "",
              value: citydata?.zipCodeId || "",
              city: citydata?.city || "",
              dropdowntype: "zip"
            })) || [];
          setZipOptions(options);
          if (data.zipCodeId) {
            const selectedZipData = options.find((city) => city.value === data.zipCodeId);
            setSelectedZip(selectedZipData || "");
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
          setZipOptions([]);
        }
      };

      getCities();
    }
  }, [selectedStateId, data.zipCodeId]);

  const formFields = [
    { field: "clientName", label: "Client" },
    { field: "owner", label: "Owner" },
    { field: "contactPhone", label: "Contact Phone" },
    { field: "contactEmail", label: "Contact Email" },
    { field: "address", label: "Address" },
    {
      field: "state",
      label: "State",
      type: "select",
      options: stateOptions || []
    },
    {
      field: "zipCodeId",
      label: "Zip",
      type: "select",
      options: zipOptions || []
    }
  ];

  const adminFields = [
    { field: "firstName", label: "First Name" },
    { field: "lastName", label: "Last Name" },
    { field: "username", label: "Username" },
    { field: "email", label: "Email" },
    {
      field: "role",
      label: "Role",
      type: "radio",
      options: [
        { label: "Clinician", value: "clinician" },
        { label: "Staff", value: "staff" }
      ]
    }
  ];

  const handleSubmit = async (formData) => {
    formData.city = selectedZip?.city || formData.city || "";
    try {
      switch (actionType) {
        case "addclient":
          await addSAClient(formData);
          dispatch(showNotification({ message: "Client added successfully", type: "success" }));
          break;
        case "editclient":
          await editSAClient(formData);
          dispatch(showNotification({ message: "Client edited successfully", type: "success" }));
          break;
        default:
          console.warn("Unknown action type:", actionType);
      }
    } catch (error) {
      dispatch(showNotification({ message: "An error occurred", type: "error" }));
    }
    onClose();
  };

  if (!show) return null;

  const CustomInput = ({ label, type, onChange, value, options, ...props }) => {
    const containerStyle = {
      display: "grid",
      gridTemplateColumns: "150px 1fr",
      gap: "10px",
      alignItems: "center",
      marginBottom: "15px"
    };

    const labelStyle = {
      textAlign: "right",
      paddingRight: "10px",
      color: "white"
    };

    const handleInputChange = (e) => {
      if (type === "select") {
        const selectedItem = e.target.value;
        if (typeof selectedItem === "object" && selectedItem !== null) {
          if (selectedItem.dropdowntype === "state") {
            onChange({ value: selectedItem.value });
            setSelectedStateId(selectedItem.value);
            setSelectedZip("");
          }
          if (selectedItem.dropdowntype === "zip") {
            onChange({ value: selectedItem.value });
            setSelectedZip(selectedItem);
          }
        }
      } else {
        onChange({ value: e.target.value });
      }
    };

    return (
      <div style={containerStyle}>
        <label style={labelStyle}>{label}:</label>
        {type === "select" ? (
          <ComboBox
            data={options}
            textField="text"
            dataItemKey="value"
            value={options.find((opt) => opt.value === value)}
            onChange={handleInputChange}
            style={{ width: "100%" }}
            clearButton={false}
            placeholder={`Select ${label}`}
            filterable={true}
          />
        ) : type === "radio" ? (
          <div style={{ display: "flex", gap: "10px" }}>
            {options.map((option) => (
              <label
                key={option.value}
                style={{ display: "flex", alignItems: "center", color: "white" }}
              >
                <input
                  type="radio"
                  name={props.name}
                  value={option.value}
                  checked={value === option.value}
                  onChange={handleInputChange}
                  style={{ accentColor: "#ff6358" }}
                />
                {option.label}
              </label>
            ))}
          </div>
        ) : type === "toggle" ? (
          <Switch checked={value || false} onChange={(e) => onChange({ value: e.target.value })} />
        ) : (
          <Input {...props} value={value || ""} onChange={handleInputChange} />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!data) return;

    setInitialValues({
      ...data,
      inactive: !data?.isActive,
      state: data.stateId || "",
      zipCodeId: data.zipCodeId || "",
      city: selectedZip?.city || data.city || "",
      username: admin?.username || "",
      email: admin?.email || "",
      firstName: admin?.firstName || "",
      lastName: admin?.lastName || "",
      role: admin?.role || ""
    });
  }, [data, admin]);

  if (actionType === "editclient" && !initialValues) return null;

  return (
    <Dialog title={title} onClose={onClose} width={900}>
      <Form
        key={JSON.stringify(initialValues)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <form onSubmit={formRenderProps.onSubmit}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                padding: "20px"
              }}
            >
              <div>
                {formFields.slice(0, 4).map((field) => (
                  <Field
                    key={field.field}
                    name={field.field}
                    component={CustomInput}
                    label={field.label}
                    type={field.type}
                    options={field.options}
                    value={formRenderProps.valueGetter(field.field)}
                  />
                ))}
              </div>

              <div>
                {formFields.slice(4).map((field) => (
                  <Field
                    key={field.field}
                    name={field.field}
                    component={CustomInput}
                    label={field.label}
                    type={field.type}
                    options={field.options}
                    value={formRenderProps.valueGetter(field.field)}
                  />
                ))}
                <Field
                  name="city"
                  component={({ value, onChange, ...props }) => (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "150px 1fr",
                        gap: "10px",
                        alignItems: "center",
                        marginBottom: "15px"
                      }}
                    >
                      <label style={{ textAlign: "right", paddingRight: "10px", color: "white" }}>
                        City:
                      </label>
                      <Input
                        {...props}
                        value={selectedZip?.city || value || ""}
                        onChange={onChange}
                      />
                    </div>
                  )}
                  value={selectedZip?.city}
                />
              </div>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                padding: "20px"
              }}
            >
              <span>
                <h3 style={{ color: "white" }}>Admin</h3>
                <div
                  style={{
                    display: "grid",
                    gap: "15px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "20px",
                    margin: "20px 0",
                    position: "relative"
                  }}
                >
                  {adminFields.map((field) => (
                    <Field
                      key={field.field}
                      name={field.field}
                      component={CustomInput}
                      label={field.label}
                      type={field.type}
                      options={field.options}
                      value={formRenderProps.valueGetter(field.field)}
                    />
                  ))}
                </div>
              </span>
              <span style={{ marginLeft: "100px" }}>
                {actionType === "editclient" && (
                  <Field
                    name="inactive"
                    type="checkbox"
                    component={({ value, onChange }) => (
                      <div
                        style={{
                          marginLeft: "28px",
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          transform: "scale(1.2)",
                          accentColor: "#ff6358"
                        }}
                      >
                        <label style={{ color: "white" }}>Inactive:</label>
                        <input
                          type="checkbox"
                          checked={value || false}
                          onChange={(e) => onChange({ value: e.target.checked })}
                        />
                      </div>
                    )}
                  />
                )}
                <h3 style={{ color: "white" }}>Subscriptions</h3>
                <div
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "20px",
                    margin: "20px 0",
                    position: "relative"
                  }}
                >
                  <Field
                    name="ePrescribing"
                    component={CustomInput}
                    label="ePrescribing"
                    type="toggle"
                  />
                </div>
              </span>
            </div>
            <div style={{ borderTop: "1px solid #ddd", margin: "10px 0" }}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
                padding: "20px"
              }}
            >
              <Button type="submit" themeColor="primary" disabled={!formRenderProps.modified}>
                Save
              </Button>
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

export default ClientAddEditpopup;
