import React from "react";
import PatientCarousel from "./PatientCarousel";
import IconButton from "src/components/atoms/IconButton";
import "./PatientCard.css";
import { getGenderLabel } from "src/utils/genderUtils";

const PatientCard = ({ patient }) => {
  return (
    <div className="patient-card">
      {/* Patient Info Section */}
      <div className="patient-info-section">
        <div className="patient-info">
          <div className="patient-image-wrapper">
            {/* Placeholder for patient image */}
            <div className="patient-image ">{patient?.firstName?.[0] || ""}</div>
          </div>
          <div className="patient-name-id">
            <h3>{`${patient?.firstName} ${patient?.middleName || ""} ${patient?.lastName}`}</h3>
            <p>
              {patient?.birthdate} (
              {new Date().getFullYear() - new Date(patient?.birthdate).getFullYear()} years), (
              {getGenderLabel(patient?.gender)})
            </p>
            <div className="recare-visit">
              Recare Visit: {patient?.recareDue || "N/A"}
              <IconButton icon="calendar" className="recare-visit-icon" />
            </div>
          </div>
        </div>
      </div>

      {/* Patient Details Section */}
      <div className="patient-details-section">
        <hr className="section-divider" />
        {/* Section 1: Patient Since and Next Visit */}
        <div className="detail-section">
          <div className="detail-item">
            <p className="label">Patient Since</p>
            <p>{patient?.patientSince}</p>
          </div>
          <div className="detail-item">
            <p className="label">Last Visit</p>
            <p>
              {patient?.lastVisit || "N/A"} <IconButton icon="calendar" className="icon" />
            </p>
          </div>
          <div className="detail-item">
            <p className="label">Source</p>
            <p>{patient?.source || "N/A"}</p>
          </div>
          <div className="detail-item">
            <p className="label">Mobile</p>
            <p>
              {patient?.mobile || "N/A"} <IconButton icon="phone" className="icon" />
            </p>
          </div>
        </div>
        <hr className="section-divider" />

        {/* Section 2: Total Production and Family Balance */}
        <div className="detail-section">
          <div className="detail-item">
            <p className="label">Total Production</p>
            <p>{patient?.totalProduction || "N/A"}</p>
          </div>
          <div className="detail-item">
            <p className="label">Insurance</p>
            <p>{patient?.insurance || "N/A"}</p>
          </div>
          <div className="detail-item">
            <p className="label">Family Balance</p>
            <p>{patient?.familyBalance || "N/A"}</p>
          </div>
          <div className="detail-item">
            <p className="label">Benefits</p>
            <p>{patient?.benefits || "N/A"}</p>
          </div>
        </div>
        <hr className="section-divider" />
      </div>

      {/* Family Members Carousel */}
      <div className="family-carousel-section">
        <PatientCarousel
          familyMembers={
            patient?.familyDetailsList?.map((member) => ({
              image: "/default-avatar.png", // Placeholder image
              name: `${member.firstName} ${member.middleName || ""} ${member.lastName}`,
              ageGender: `${new Date().getFullYear() - new Date(member.birthdate).getFullYear()} years, (${getGenderLabel(patient?.gender)})`,
              id: member.id
            })) || []
          }
        />
      </div>
    </div>
  );
};

export default PatientCard;
