import React, { useState } from "react";
import DynamicGrid from "../../../organisms/DynamicGrid";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import TreatmentPopup from "./Treatment";

const VisitInsuranceGrid = ({ gridRef, data = [] }) => {
  // Prepare rows by transforming the input data structure
  const rows = data?.map((treatment, index) => ({
    id: index + 1, // Generate unique ID for each row
    treatment: treatment.treatment || "No Description", // Map treatment name
    code: treatment.code || "-", // Map code
    tooth: treatment.tooth || "-", // Map tooth (null replaced with "-")
    area: treatment.area || "-", // Map area (null replaced with "-")
    fee: treatment.fee || "-" // Map fee
  }));

  // Dynamic columns configuration for the parent grid
  const parentColumns = [
    { field: "insurance", title: "Insurance", width: "150px" },
    {
      field: "rank",
      title: "Rank",
      width: "50px",
      sortable: true,
      filterable: true
    },
    {
      field: "status",
      title: "Status",
      width: "50px",
      sortable: true,
      filterable: true
    },
    { field: "copay", title: "Copay", width: "50px", sortable: true, filterable: false },
    { field: "deductible", title: "Deductible", width: "70px", sortable: true, filterable: false },
    { field: "estimate", title: "Estimate", width: "70px", sortable: true, filterable: false },
    { field: "payment", title: "Estimate", width: "70px", sortable: true, filterable: false },
    { field: "writeoff", title: "Write-Off", width: "70px", sortable: true, filterable: false },
    { field: "noBill", title: "NoBill", width: "50px", sortable: true, filterable: false }
  ];

  // Custom action buttons for the action column
  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => {
        setSelectedTreatmentId(dataItem.id);
        openPopup("editTreatment");
      }
    },
    {
      title: "Delete",
      icon: "delete",
      onClick: (dataItem) => alert(`Delete `)
    }
  ];

  // Custom action buttons for the action column

  // Use DynamicGrid as both parent and detail grid
  return data ? (
    <>
      <DynamicGrid
        ref={gridRef}
        columns={parentColumns}
        actions={actions}
        rows={rows}
        allowExpand={true} // Enable row expand/collapse
        allowSort={true} // Enable sorting
      />
    </>
  ) : (
    ""
  );
};

export default VisitInsuranceGrid;
