import React, { useEffect, useState } from "react";
import Button from "src/components/atoms/Button";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import FormActionsBar from "src/components/organisms/FormActionBar";
import FamilyHealthPopup from "./FamilyHealthPopup"; // Popup component for add/edit
import { useDispatch, useSelector } from "react-redux";
import { getFamilyHealth, saveFamilyHealth } from "src/store/actions/familHealthAction";
import { selectFamilyHealth } from "src/store/slices/familyHealthSlice";
import usePatient from "src/hooks/use-patient";
import { fetchVitalSignsByPatientId } from "src/store/actions/vitalSignsActions";

const FamilyHealth = () => {
  const [mode, setMode] = useState("add");
  const dispatch = useDispatch();
  const patientId = usePatient()?.patientId;
  const clinicId = useSelector((state) => state.clinics.selectedClinic.id || 0);
  const familyHealth = useSelector(selectFamilyHealth) || []; // Get family health data from Redux
  console.log("familHealth", familyHealth);
  const [showPopup, setShowPopup] = useState(false);
  const [editData, setEditData] = useState(null);
  useEffect(() => {
    console.log("fetchFamilyHealth action"); // Log the action
    dispatch(getFamilyHealth(patientId)).then((x) => {
      console.log("fetchFamilyHealth action..", x); // Log the action
    }); // Dispatch action
  }, [dispatch, patientId]);

  const handleAdd = () => {
    setMode("add");

    setEditData(null); // Reset edit data for add mode
    setShowPopup(true); // Show popup
  };

  const handleEdit = (dataItem) => {
    console.log("edit dataItem", dataItem);
    setMode("edit");
    setEditData(dataItem); // Set data for edit mode
    setShowPopup(true); // Show popup
  };

  const handleSave = async (formData) => {
    if (editData) {
      // Dispatch update action
      await dispatch(
        saveFamilyHealth({
          ...formData,
          requestType: 2
        })
      );
    } else {
      // Dispatch add action
      await dispatch(
        saveFamilyHealth({
          ...formData,
          requestType: 1
        })
      );
    }
    dispatch(getFamilyHealth(patientId));
    setShowPopup(false); // Close popup after saving
  };

  const columns = [
    { field: "familyMember", title: "Family Member" },
    { field: "relationShipName", title: "Relation" },
    { field: "diseaseName", title: "Health Issue" }
  ];
  const actions = [
    {
      icon: "edit",
      title: "Edit",
      onClick: (dataItem) => handleEdit(dataItem)
    }
  ];
  return (
    <div className="family-health">
      <FormActionsBar>
        <Button icon="plus" className="add-button" onClick={handleAdd}>
          Add Family Health
        </Button>
      </FormActionsBar>

      <DynamicGrid
        columns={columns}
        rows={familyHealth}
        noRecordMessage="There are no family health issues"
        actions={actions}
        pageSize={5}
        pageable={true}
      />

      {showPopup && (
        <FamilyHealthPopup
          show={showPopup}
          onClose={() => setShowPopup(false)}
          mode={mode}
          initialData={editData}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default FamilyHealth;
