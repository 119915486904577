import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFamilyHistory, manageFamilyHistory } from "src/services/familyhealth-service";

// Action to save family health data
export const saveFamilyHealth = createAsyncThunk(
  "familyHealth/save",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await manageFamilyHistory(payload);
      return response; // Returning the response after saving
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to save family health history");
    }
  }
);

export const getFamilyHealth = createAsyncThunk(
  "familyHealth/get",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getFamilyHistory(payload);
      return response; // Returning the response after saving
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to get family health history");
    }
  }
);
