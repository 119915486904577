import React, { useRef, useEffect, useState } from "react";
import CommonPopup from "../../../molecules/CommonPopup"; // Import the reusable CommonPopup component
import DynamicForm from "../../../organisms/DynamicForm"; // Import the dynamic form component
import PatientVisitTreatmentGrid from "./PatientVisitTreatmentGrid"; // Import the custom patient visit grid component
import PatientVisitFollowupGrid from "./PatientVisitFollowupGrid";
import { useSelector, useDispatch } from "react-redux";
import { selectProvidersByClientId } from "src/store/slices/providerSlice";
import { fetchEditVisitDetails, updateVisitDetails } from "src/store/actions/visitAction";
import { selectEditVisitDetails } from "src/store/slices/visitSlice";
import { AppointmentStatusEnum } from "src/models/enum";
import { fetchProvidersByClientId } from "src/store/actions/providerAction";
import { getGenderLabel } from "src/utils/genderUtils";
import { selectAppointmentTypeList } from "src/store/slices/appointmentTypeSlice";
import { fetchAppointmentTypeList } from "src/store/actions/appointmentTypeAction";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import TreatmentAndFeesPopup from "./TreatmentAndFeesPopup";
import { fetchProcedureCodeList } from "src/store/actions/procedureCodeAction";
import { selectProcedureCodeList } from "src/store/slices/procedureCodeSlice";
import { addPatientProcedure } from "src/store/actions/patientProcedureAction";

const PatientVisitEditPopup = ({ show, onClose, visitId }) => {
  const gridRef = useRef();
  const editVisitDetails = useSelector((state) => selectEditVisitDetails(state));
  const providersByClientId = useSelector((state) => selectProvidersByClientId(state));
  const appointmentTypeList = useSelector((state) => selectAppointmentTypeList(state));
  const procedureCodeList = useSelector((state) => selectProcedureCodeList(state));
  const dispatch = useDispatch();
  const { popups, openPopup, closePopup } = usePopupVisibility();
  const convertToDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date; // Check if it's a valid date
  };
  //const [formData, setFormData] = useState(() => transformApiDataToFormData(editVisitDetails));

  const convertToFormattedDateTime = (dateString) => {
    if (!dateString) return null;

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return null;

    const dateOptions = { year: "numeric", month: "long", day: "numeric" }; // Customize date format
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true }; // Customize time format

    const formattedDate = date.toLocaleDateString(undefined, dateOptions);
    const formattedTime = date.toLocaleTimeString(undefined, timeOptions);

    return `${formattedDate} ${formattedTime}`;
  };

  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setFormData(transformApiDataToFormData(editVisitDetails));
    };
    fetchData();
  }, [editVisitDetails]);
  const [patientProcedure, setPatientProcedure] = useState(null);
  const clientId = 1;
  // Handle patient change (e.g., through dropdown selection)

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchEditVisitDetails(visitId));
  }, [dispatch, visitId]);

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    if (patientProcedure)
      dispatch(addPatientProcedure(patientProcedure))
        .unwrap() // Unwrap the promise to handle success/failure
        .then(() => {
          // Success: Fetch updated visit details
          dispatch(fetchEditVisitDetails(visitId));
        });
  }, [dispatch, patientProcedure]);

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchProvidersByClientId(clientId));
  }, [dispatch, clientId]);

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchAppointmentTypeList());
    dispatch(fetchProcedureCodeList());
  }, [dispatch]);

  const onPatientProcedureDelete = (success) => {
    if (success) dispatch(fetchEditVisitDetails(visitId));
  };
  const handlePrint = () => {
    gridRef.current.printGrid();
  };

  const MinutesEnum = {};
  for (let i = 0; i <= 180; i += 5) {
    MinutesEnum[`${i} min`] = i;
  }
  const providerOptions = providersByClientId?.map((provider) => ({
    label: `${provider.fName} ${provider.lName || ""}`.trim(), // Full name
    value: provider.id // Provider ID
  }));

  const dentatlVisitOptions = appointmentTypeList?.map((appointmentType) => ({
    label: `${appointmentType.appointmentTypeName}`.trim(), // Full name
    value: appointmentType.id // Provider ID
  }));

  const treatmentOptions = procedureCodeList?.results?.map((procedureCode) => ({
    label: `${procedureCode.description}`.trim(), // Full name
    value: procedureCode.id // Provider ID
  }));

  // Define the dynamic form structure for the selected patient
  const formStructure = {
    title: "Visit",
    tabs: [
      {
        name: "Patient Visit",
        sections: [
          {
            sectionName: "Treatment",
            sectionHeader: {
              left: [
                {
                  label: "DentalVisits",
                  name: "dentalVisits",
                  type: "select",
                  placeholder: "Select Dental Visits",
                  options: dentatlVisitOptions
                },
                {
                  label: "SelectTreatment",
                  name: "selectTreatment",
                  type: "select",
                  placeholder: "Select Treatment",
                  options: treatmentOptions
                }
              ],
              right: [
                {
                  type: "button",
                  text: "Treatment",
                  onClick: (dataItem) => {
                    openPopup("treatmentAndFees");
                  }
                },
                {
                  type: "button",
                  text: "Diagnosed",
                  onClick: () => alert("Patient Recare clicked")
                },
                {
                  type: "button",
                  text: "Ortho Visit",
                  onClick: () => handlePrint()
                }
              ]
            },
            customSection: (
              <PatientVisitTreatmentGrid
                onDelete={onPatientProcedureDelete}
                gridRef={gridRef}
                data={editVisitDetails?.treatments} // Pass visit data to the grid
              />
            )
          }
        ]
      },
      {
        name: "Patient And Contact",
        sections: [
          {
            sectionName: "patientAndContact",
            fields: [
              { label: "Patient", name: "patientName", type: "input", isDisabled: true },
              {
                label: "Moile Phone",
                name: "mobilePhone",
                type: "input",
                isDisabled: true
              },
              { label: "BirthDate", name: "birthDate", type: "input", isDisabled: true },
              { label: "Home Phone", name: "homePhone", type: "input", isDisabled: true },
              { label: "Gender", name: "gender", type: "input", isDisabled: true },
              { label: "Email", name: "email", type: "input", isDisabled: true },
              { label: "Status", name: "status", type: "input", isDisabled: true },
              { label: "Reach By", name: "reachby", type: "input", isDisabled: true }
            ]
          },
          {
            sectionName: "Visit Note",
            fields: [
              {
                label: "Note",
                name: "visitNote",
                type: "textarea"
              }
            ]
          }
        ]
      },
      {
        name: "Follow up",
        sections: [
          {
            sectionName: "Follow Up",
            customSection: (
              <PatientVisitFollowupGrid
                gridRef={gridRef}
                data={editVisitDetails?.followUps} // Pass visit data to the grid
              />
            ),
            sectionFooter: {
              left: [
                {
                  type: "button",
                  text: "Conversation",
                  onClick: () => alert("Family Recare clicked")
                },
                {
                  type: "button",
                  text: "Send Text",
                  onClick: () => alert("Patient Recare clicked")
                },
                {
                  type: "button",
                  text: "Request Review",
                  onClick: () => handlePrint()
                },
                {
                  type: "button",
                  text: "To Schedule",
                  onClick: () => handlePrint()
                },
                {
                  type: "button",
                  text: "All Visits",
                  onClick: () => handlePrint()
                }
              ]
            }
          }
        ]
      },
      {
        name: "VisitStatus And Treatment",
        sections: [
          {
            sectionName: "Visit Status",
            fields: [
              {
                label: "Phase",
                name: "phase",
                type: "select",
                options: Object.keys(AppointmentStatusEnum).map((key) => ({
                  label: key, // Enum label as the name
                  value: AppointmentStatusEnum[key] // Enum value as the number
                }))
              },
              { label: "Scheduling", name: "scheduling", type: "select" },
              { label: "Status", name: "status", type: "select" }
            ]
          },
          {
            sectionName: "Treatment",
            fields: [
              { label: "Dentist", name: "dentist", type: "select", options: providerOptions },
              { label: "Assistant", name: "assistant", type: "select", options: providerOptions },
              { label: "Hygienist", name: "hygienist", type: "select", options: providerOptions },
              { label: "Chair", name: "chair", type: "input", isDisabled: true }
            ],
            sectionFooter: {
              left: [
                {
                  type: "button",
                  text: "Labwork",
                  onClick: () => alert("Family Recare clicked")
                }
              ]
            }
          }
        ]
      },
      {
        name: "Schedule",
        sections: [
          {
            sectionName: "Schedule",
            fields: [
              { label: "Date/time", name: "schedule", type: "input", isDisabled: true },
              {
                label: "Duration",
                name: "duration",
                type: "select",
                options: Object.keys(MinutesEnum).map((key) => ({
                  label: key, // "0 min", "5 min", etc.
                  value: MinutesEnum[key] // 0, 5, 10, ..., 180
                }))
              },
              {
                label: "Clinician Time",
                name: "clinicianTime",
                type: "select",
                options: Object.keys(MinutesEnum).map((key) => ({
                  label: key, // "0 min", "5 min", etc.
                  value: MinutesEnum[key] // 0, 5, 10, ..., 180
                }))
              },
              { label: "Checked In", name: "checkedIn", type: "datetime" },
              { label: "In Chair", name: "chairIn", type: "datetime" },
              { label: "Checked Out", name: "checkedOut", type: "datetime" },
              { label: "Visit Color", name: "visitColor", type: "select" }
            ]
          }
        ]
      }
    ]
  };

  function transformApiDataToFormData(apiData) {
    if (apiData != null)
      return {
        dentalVisits: apiData.dentalVisits || "",
        selectTreatment: apiData.selectTreatment || "",
        patientName: `${apiData.firstName || ""} ${apiData.lastName || ""}`.trim(),
        mobilePhone: apiData.mobilePhone || "",
        birthDate: apiData.birthdate || "",
        homePhone: apiData.homePhone || "",
        gender: getGenderLabel(apiData.gender),
        email: apiData.email || "",
        status: apiData.status || "",
        reachby: apiData.reachBy || "",
        visitNote: apiData.visitNote || "",
        followUps: apiData.followUps || [],
        phase: apiData.phase || "",
        schedule: convertToFormattedDateTime(apiData.schedule) || "",
        status: apiData.visitStatus || "",
        dentist: apiData.dentist || "",
        assistant: apiData.assistant || "",
        hygienist: apiData.hygienist || "",
        chair: apiData.chair || "",
        duration: apiData.durationInMinutes,
        clinicianTime: apiData.clinicianTime || "",
        checkedIn: convertToDate(apiData.checkedIn) || "",
        chairIn: convertToDate(apiData.inChair) || "",
        checkedOut: convertToDate(apiData.checkedOut) || "",
        visitColor: apiData.visitColor || ""
      };
  }

  function transformFormDataToApiData(formData) {
    return {
      appointmentId: visitId,
      status: formData.status || "",
      reachBy: formData.reachby || null,
      visitNote: formData.visitNote || "",
      phase: formData.phase || "",
      dentist: formData.dentist || null,
      assistant: formData.assistant || null,
      hygienist: formData.hygienist || null,
      durationInMinutes: formData.duration || 0,
      clinicianTime: formData.clinicianTime || null,
      checkedIn: formData.checkedIn ? convertToDate(formData.checkedIn) : null, // Conditional conversion
      inChair: formData.chairIn ? convertToDate(formData.chairIn) : null,
      checkedOut: formData.checkedOut ? convertToDate(formData.checkedOut) : null,
      visitColor: formData.visitColor || null
    };
  }

  const handleInputChange = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
    if (name == "selectTreatment") {
      setPatientProcedure({
        appointmentId: visitId,
        providerId: 1,
        procedureCode: [procedureCodeList?.results?.find((x) => x.id == value)?.procedureCode],
        clientId: 1,
        clinicId: 1
      });
    }
    if (name == "dentalVisits") {
      setPatientProcedure({
        appointmentId: visitId,
        providerId: 1,
        procedureCode: appointmentTypeList
          ?.find((x) => x.id == value)
          ?.procedureCode?.split(",")
          ?.filter((code) => code.trim() !== ""),
        clientId: 1,
        clinicId: 1
      });
    }
  };

  const handleSubmit = () => {
    const updatedData = transformFormDataToApiData(formData);
    dispatch(updateVisitDetails(updatedData));
  };
  return (
    <>
      <CommonPopup show={show} onClose={onClose} title={"Visit"}>
        {/* Dynamic Form for Patient Information */}
        <DynamicForm
          formData={formData}
          tabs={formStructure.tabs}
          handleFormSubmit={handleSubmit}
          handleInputChange={handleInputChange}
        />
      </CommonPopup>
      {popups.treatmentAndFees && (
        <TreatmentAndFeesPopup
          show={popups.treatmentAndFees}
          onClose={() => closePopup("treatmentAndFees")}
        />
      )}
    </>
  );
};

export default PatientVisitEditPopup;
