import httpClient from "./http-client"; // Import the httpClient

const API_BASE_URL = "/Provider"; // The base URL for your API

// Fetch clinicians data by client ID from the API
export const fetchCliniciansApi = async (clientId) => {
  try {
    const response = await httpClient(`${API_BASE_URL}/getproviderbyclientid/${clientId}`, {
      method: "GET" // Fetch request method
    });
    return response; // Return the response data
  } catch (error) {
    console.error("Error fetching clinicians:", error);
    throw error; // Rethrow the error for higher-level handling
  }
};

// Add a new clinician
export const manageProviderApi = async (clinicianData) => {
  try {
    const response = await httpClient(`${API_BASE_URL}/manageProvider`, {
      method: "POST", // Post request to add clinician
      body: clinicianData // Body with clinician data
      // headers: {
      //   "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryD0jVjyBNJTACyzbq"
      // }
    });
    return response; // Return the response data
  } catch (error) {
    console.error("Error adding clinician:", error);
    throw error; // Rethrow the error for higher-level handling
  }
};

export const fetchSpecialtiesApi = async () => {
  try {
    const response = await httpClient(`${API_BASE_URL}/getproviderspeciality`, {
      method: "GET" // Fetch request method
    });
    return response; // Assuming API returns the list of specialties
  } catch (error) {
    console.error("Error fetching specialities:", error);

    throw error;
  }
};

export const fetchProviderFeeApi = async (clientId) => {
  try {
    const response = await httpClient(`${API_BASE_URL}/getproviderfee/` + clientId, {
      method: "GET" // Fetch request method
    });
    return response; // Assuming API returns the list of specialties
  } catch (error) {
    console.error("Error fetching specialities:", error);

    throw error;
  }
};
