export const ProfileDetail = {
  firstName: null,
  middleName: null,
  lastName: null,
  title: null,
  type: null, // Can be an enum or specific string/number type if needed
  gender: null, // Can be an enum or specific string/number type if needed
  lifeStatus: null, // Can be an enum or specific string/number type if needed
  birthdate: "2000-01-01", // Could be a Date object or a string in the format YYYY-MM-DD
  ssn: null,
  mrn: null,
  guarantor: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  mobilePhone: null,
  homePhone: null,
  workPhone: null,
  email: null,
  reachBy: null, // Can be an enum or specific string/number type if needed
  language: null,
  notes: null,
  dentist: null,
  hygienist: null,
  fees: null,
  contactNotes: null,
  isActive: true,
  guarantorId: null,
  patientId: 0,
  clientId: 1,
  clinicId: 1,
  createdBy: 1
};

export const ProfileStrip = {
  FirstName: "", // string
  MiddleName: "", // string
  LastName: "", // string
  Type: null, // Position or Type (nullable)
  Gender: null, // Gender (nullable)
  PatientSince: null, // Date (nullable)
  LastVisit: null, // Date (nullable)
  Mobile: "", // string
  TotalProduction: null, // long (nullable)
  FamilyBalance: null, // long (nullable)
  Insurance: "", // string
  Benefits: null, // long (nullable)
  Birthdate: null, // Date (nullable)
  Alerts: "", // string (nullable)
  Title: "", // string (nullable)
  RecareDue: null, // Date (nullable)
  LifeStatus: null, // LifeStatus (nullable)
  Source: "", // string (nullable)
  FamilyDetailsList: [] // Array of family details (initialize empty)
};

export const FamilyDetails = {
  FamilyMemberName: "", // Example field for family details; customize as per API structure
  Relationship: "", // Relationship with the patient
  Birthdate: null // Date (nullable)
};

export const Patient = {
  id: 0,
  createdBy: 0,
  updatedBy: 0,
  createdAt: "",
  updatedAt: "",
  mrn: "",
  lName: "",
  fName: "",
  middleI: "",
  preferred: "",
  patStatus: 0,
  gender: 0,
  position: 0,
  birthdate: "",
  ssn: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  hmPhone: "",
  wkPhone: "",
  wirelessPhone: "",
  guarantor: 0,
  creditType: "",
  email: "",
  salutation: "",
  estBalance: 0,
  priProv: 0,
  secProv: 0,
  feeSched: 0,
  billingType: 0,
  imageFolder: "",
  addrNote: "",
  famFinUrgNote: "",
  medUrgNote: "",
  apptModNote: "",
  studentStatus: "",
  schoolName: "",
  chartNumber: "",
  medicaidID: "",
  bal_0_30: 0,
  bal_31_60: 0,
  bal_61_90: 0,
  balOver90: 0,
  insEst: 0,
  balTotal: 0,
  employerNum: 0,
  employmentNote: "",
  county: "",
  gradeLevel: 0,
  urgency: 0,
  dateFirstVisit: "",
  clinicNum: 0,
  hasIns: "",
  trophyFolder: "",
  plannedIsDone: 0,
  premed: 0,
  ward: "",
  preferConfirmMethod: 0,
  preferContactMethod: 0,
  preferRecallMethod: 0,
  schedBeforeTime: {
    ticks: 0
  },
  schedAfterTime: {
    ticks: 0
  },
  schedDayOfWeek: 0,
  language: "",
  admitDate: "",
  title: "",
  payPlanDue: 0,
  siteNum: 0,
  dateTStamp: "",
  responsParty: 0,
  canadianEligibilityCode: 0,
  askToArriveEarly: 0,
  preferContactConfidential: 0,
  superFamily: 0,
  txtMsgOk: 0,
  smokingSnoMed: "",
  country: "",
  dateTimeDeceased: "",
  billingCycleDay: 0,
  secUserNumEntry: 0,
  secDateEntry: "",
  hasSuperBilling: 0,
  patNumCloneFrom: 0,
  discountPlanNum: 0,
  hasSignedTil: 0,
  shortCodeOptIn: 0,
  securityHash: "",
  isActive: true
};
