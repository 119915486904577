export const getContactMethodLabel = (contactMethod) => {
  switch (contactMethod) {
    case 0:
      return "None";
    case 1:
      return "Phone";
    case 2:
      return "Email";
    case 3:
      return "Text";
    case 4:
      return "Mail";
    default:
      return "Unknown";
  }
};
