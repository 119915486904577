import { createAsyncThunk } from "@reduxjs/toolkit";
import { VitalSignsService } from "src/services/vitalsigns-service";

// Fetch vital signs by patient ID
export const fetchVitalSignsByPatientId = createAsyncThunk(
  "vitalSigns/fetchByPatientId",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await VitalSignsService.getVitalSignsByPatientId(patientId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch vital signs");
    }
  }
);

// Save a new or existing vital sign record
export const saveVitalSigns = createAsyncThunk(
  "vitalSigns/save",
  async (vitalSignData, { rejectWithValue }) => {
    try {
      const response = await VitalSignsService.saveVitalSign(vitalSignData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to save vital signs");
    }
  }
);
