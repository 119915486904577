import React, { useRef, useEffect } from "react";
import CommonPopup from "../../../molecules/CommonPopup"; // Import the reusable CommonPopup component
import DynamicForm from "../../../organisms/DynamicForm"; // Import the dynamic form component
import PatientVisitsGrid from "./PatientVisitsGrid"; // Import the custom patient visit grid component
import { useSelector, useDispatch } from "react-redux";
import { selectProfileDetails } from "src/store/slices/patientSlice";
import { fetchFamilyVisitDetails } from "src/store/actions/visitAction";
import { selectFamilyVisitDetails } from "src/store/slices/visitSlice";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import PaymentsGrid from "./PaymentsGrid";
import AdjustmentsGrid from "./AdjustmentsGrid";
import VisitInsuranceGrid from "./VisitInusranceGrid";

const TreatmentPopup = ({ show, onClose, treatmentId }) => {
  const profile = useSelector((state) => selectProfileDetails(state));
  const gridRef = useRef();
  const familyVisitDetails = useSelector((state) => selectFamilyVisitDetails(state));
  const dispatch = useDispatch();
  // Handle patient change (e.g., through dropdown selection)

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchFamilyVisitDetails(profile.patientId));
  }, [dispatch, profile]);

  const handlePrint = () => {
    gridRef.current.printGrid();
  };
  // Define the dynamic form structure for the selected patient
  const formStructure = {
    title: "Visit",
    tabs: [
      {
        name: "Treatment And Note",
        layout: "grid",
        sections: [
          {
            sectionName: "Treatment",
            layout: "grid",
            minWidth: "200px",
            maxWidth: "500px",
            fields: [
              { label: "ADA Code", name: "procedureCode", type: "input", isDisabled: true },
              {
                label: "Procedure",
                name: "mobilePhone",
                type: "input",
                isDisabled: true
              },
              { label: "Tooth", name: "birthDate", type: "input" },
              { label: "Clinician", name: "homePhone", type: "select" },
              { label: "Refer out", name: "gender", type: "select" },
              { label: "Place of Service", name: "email", type: "select" },
              { label: "Concern", name: "email", type: "select" }
            ]
          },
          {
            sectionName: "Note",
            layout: "grid",
            minWidth: "200px",
            maxWidth: "500px",
            fields: [
              { label: "Clinician Note", name: "clinicianNote", type: "textarea", rows: 5 },
              { label: "Signature", name: "procedureCode", type: "textarea", rows: 5 }
            ]
          },
          {
            sectionName: "Fee",
            layout: "grid",
            minWidth: "200px",
            maxWidth: "500px",
            fields: [
              { label: "Fee", name: "procedureCode", type: "numerictextbox" },
              { label: "Rank", name: "procedureCode", type: "select" },
              { label: "Phase", name: "procedureCode", type: "select" },
              { label: "Phase Date", name: "procedureCode", type: "date" },
              { label: "Created On", name: "procedureCode", type: "input", isDisabled: true },
              { label: "Created By", name: "procedureCode", type: "input", isDisabled: true }
            ]
          }
        ]
      },
      {
        name: "Payment and Insurance",
        layout: "grid",
        sections: [
          {
            sectionName: "Payments",
            layout: "grid",
            minWidth: "500px",
            customSection: (
              <div>
                <PaymentsGrid
                  gridRef={gridRef}
                  // Pass visit data to the grid
                />
              </div>
            )
          },
          {
            sectionName: "Adjustments",
            layout: "grid",
            minWidth: "500px",
            sectionFooter: {
              right: [
                {
                  type: "button",
                  text: "Adjustment",
                  onClick: () => alert("Family Recare clicked")
                },
                {
                  type: "numerictextbox",
                  label: "Savings"
                }
              ]
            },
            customSection: (
              <div>
                <AdjustmentsGrid
                  gridRef={gridRef}
                  // Pass visit data to the grid
                />
              </div>
            )
          },
          {
            sectionName: "Treatment",
            layout: "grid",
            minWidth: "800px",
            customSection: (
              <div>
                <VisitInsuranceGrid
                  gridRef={gridRef}
                  // Pass visit data to the grid
                />
              </div>
            )
          },
          {
            sectionName: "Claim Note",
            layout: "grid",
            minWidth: "200px",

            fields: [{ label: "Claim Note", name: "clinicianNote", type: "textarea", rows: 5 }]
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title={"Patient Visit"}>
      {/* Dynamic Form for Patient Information */}
      <DynamicForm
        formData={{}}
        tabs={formStructure.tabs}
        handleFormSubmit={() => alert("Form Submitted")}
      />
    </CommonPopup>
  );
};

export default TreatmentPopup;
