import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import SchedulerDrawer from "src/components/organisms/Drawer/HomePageDrawer";
import { Icon, SvgIcon } from "@progress/kendo-react-common";
import docFolderIcon from "src/assets/images/docFolderIcon.png";
import { InputClearValue, InputSeparator, TextBox } from "@progress/kendo-react-inputs";
import { searchIcon } from "@progress/kendo-svg-icons";
import CommonBtn from "src/components/atoms/Button";
import DocumentsService from "src/services/doucments";
import { ReactComponent as DentalIcon } from "src/assets/icons/dental.svg";
import "./PatientDocuments.css";
import { Loader } from "@progress/kendo-react-indicators";

const FileList = ({
  files,
  isDropzoneOpen,
  setIsDropzoneOpen,
  selectedDir,
  afterUpload,
  loading,
  handleitemdelete
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleDelete = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDrop = async (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map((file) => ({
      ...file,
      preview: URL.createObjectURL(file)
    }));

    setUploadedFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);

    try {
      const uploadPromises = acceptedFiles.map(async (file, index) => {
        try {
          await DocumentsService.uploadFile(selectedDir, file);
          filesWithPreview[index].preview && URL.revokeObjectURL(filesWithPreview[index].preview);
        } catch (error) {
          console.error(`Failed to upload ${file.name}:`, error);
        }
      });
      await Promise.all(uploadPromises);
      afterUpload();
      alert("All selected files uploaded successfully!");
      setUploadedFiles([]);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const downloadFile = (id) => {
    DocumentsService.downloadFile(id)
      .then(() => {
        alert("File downloaded successfully!");
      })
      .catch((error) => {
        console.error("Failed to download file:", error);
        alert("Failed to download file.");
      });
  };

  return (
    <div className="main-documents">
      {isDropzoneOpen && (
        <div className="dropzone-and-gallery">
          <div className="dropzone-container">
            <UploadDropzone
              isOpen={isDropzoneOpen}
              onClose={() => setIsDropzoneOpen(false)}
              onDrop={handleDrop}
            />
          </div>
          {uploadedFiles && uploadedFiles.length > 0 && (
            <div className="gallery-container">
              <FileGallery files={uploadedFiles} onDelete={handleDelete} />
            </div>
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignContent: "flex-start"
        }}
      >
        {loading ? <Loader size="small" type="pulsing" /> : null}
        {files.map(({ id, fileName, dateCreated, thumbnail }) => (
          <div className="document-item" key={id}>
            <img src={thumbnail || docFolderIcon} alt="Document" className="document-image" />
            <div className="document-details">
              <span className="document-name" title={fileName}>
                {fileName.length > 15 ? fileName.slice(0, 15) + "..." : fileName}
              </span>
              <Icon
                name="download"
                className="delete-icon"
                onClick={() => {
                  downloadFile(id);
                }}
                style={{ color: "darkgray", cursor: "pointer" }}
              />

              <Icon
                name="trash"
                className="delete-icon"
                onClick={() => {
                  confirm("Are you sure you want to delete this file?") && handleitemdelete(id);
                }}
                style={{ color: "red", cursor: "pointer" }}
              />
            </div>
            <div className="document-date">
              <span>{dateCreated}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Button = ({ children, ...props }) => (
  <CommonBtn
    className="header-btn"
    {...props}
    style={{
      backgroundColor: "rgba(167, 176, 69, 0.9)",
      marginRight: "4px"
    }}
  >
    {children}
  </CommonBtn>
);

const UploadDropzone = ({ isOpen, onClose, onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  if (!isOpen) return null;

  return (
    <div className="dropzone-overlay">
      <div
        className="dropzone-content"
        {...getRootProps()}
        style={{
          height: "80px",
          maxWidth: "unset",
          width: "auto",
          margin: "auto",
          color: "white",
          backgroundColor: "#24363e"
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>Drag & drop some files here, or click to select files</p>
        )}
      </div>
    </div>
  );
};

const DocumentHeader = ({ onSearch, value, onChange, onUploadClick }) => (
  <header className="document-header">
    <h1 className="header-title">Patient Documents</h1>
    <TextBox
      value={value}
      onChange={onChange}
      placeholder="Search Documents"
      style={{ width: "30%" }}
      suffix={() => (
        <>
          <InputClearValue onClick={onSearch}>
            <SvgIcon icon={searchIcon} />
          </InputClearValue>
          <InputSeparator />
        </>
      )}
    />
    <div className="header-buttons">
      <Button icon="download">Download</Button>
      <Button icon="upload" onClick={onUploadClick}>
        Upload
      </Button>
      <Button icon="barcode-scanner">Scan</Button>
      <Button startIcon={<DentalIcon />}>Imaging</Button>
    </div>
  </header>
);

const DirectoryList = ({ directories, onSelect, loadingDirs }) => (
  <aside className="document-sidebar">
    <ul>
      {loadingDirs && <Loader size="small" type="pulsing" />}
      {directories.map(({ id, itemName }) => (
        <li key={id} onClick={() => onSelect(id)}>
          <Icon size="large" name="folder" style={{ color: "rgba(167, 176, 69, 1)" }} />
          {/* show only first 15 characters and add ellipsis */}
          {itemName.length > 15 ? itemName.slice(0, 15) + "..." : itemName}
        </li>
      ))}
    </ul>
  </aside>
);

const FileGallery = ({ files, onDelete }) => {
  const handleFileClick = (file) => {
    if (file.preview) {
      window.open(file.preview, "_blank");
    } else {
      alert("No preview available for this file.");
    }
  };

  return (
    <div className="gallery">
      {files.length > 0 ? (
        files.map((file, index) => (
          <div className="gallery-item card" key={index}>
            <div className="card-content">
              <Icon
                name="trash"
                className="delete-icon"
                onClick={() => onDelete(index)}
                style={{ color: "red", cursor: "pointer" }}
              />
              <img
                src={file.preview || docFolderIcon}
                alt={file.name}
                className="gallery-image"
                onClick={() => handleFileClick(file)}
                style={{ cursor: "pointer", width: "100px", height: "100px", objectFit: "cover" }}
              />
              <div className="gallery-name">{file.name}</div>
            </div>
          </div>
        ))
      ) : (
        <p>No files uploaded yet.</p>
      )}
    </div>
  );
};

const PatientDocuments = () => {
  const [searchValue, setSearchValue] = useState("");
  const [directories, setDirectories] = useState([]);
  const [directoryFiles, setDirectoryFiles] = useState([]);
  const [selectedDir, setSelectedDir] = useState(null);
  const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDirs, setLoadingDirs] = useState(true);

  useEffect(() => {
    setLoadingDirs(true);
    DocumentsService.getDirectories()
      .then(setDirectories)
      .finally(() => setLoadingDirs(false));
  }, []);

  useEffect(() => {
    if (selectedDir) {
      setIsDropzoneOpen(false);
      setDirectoryFiles([]);
      setLoading(true);
      DocumentsService.getDirectoryFiles(selectedDir)
        .then(setDirectoryFiles)
        .finally(() => setLoading(false));
    }
  }, [selectedDir]);

  const onUploadClick = () => {
    if (selectedDir === null) {
      alert("Please select a directory first.");
      return;
    }
    setIsDropzoneOpen(!isDropzoneOpen);
  };

  const afterAllUploads = () => {
    // refetch files
    DocumentsService.getDirectoryFiles(selectedDir).then(setDirectoryFiles);
  };
  const handleitemdelete = (id) => {
    DocumentsService.deleteFile(id)
      .then(() => {
        setDirectoryFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
      })
      .catch((error) => {
        console.error("Failed to delete file:", error);
        alert("Failed to delete file.");
      });
  };

  return (
    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>
      <div className="main-content">
        <DocumentHeader
          onSearch={() => console.log("searchValue", searchValue)}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onUploadClick={onUploadClick}
        />
        <div className="body-section">
          <DirectoryList
            directories={directories}
            onSelect={setSelectedDir}
            loadingDirs={loadingDirs}
          />
          <FileList
            files={directoryFiles}
            isDropzoneOpen={isDropzoneOpen}
            setIsDropzoneOpen={setIsDropzoneOpen}
            selectedDir={selectedDir}
            afterUpload={afterAllUploads}
            loading={loading}
            handleitemdelete={handleitemdelete}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientDocuments;
