import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchCodeDefinitionForProcedure = createAsyncThunk(
  "patient/fetchCodeDefinitionForProcedure",
  async () => {
    try {
      const response = await httpClient.post(`/CodeDefinition/getCodeDefinitionForProcedure`); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching code definition for procedure"
      );
    }
  }
);
