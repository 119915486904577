import React, { useState, useEffect } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import DynamicGrid from "../../../../organisms/DynamicGrid";
import FormActionsBar from "../../../../organisms/FormActionBar";
import Button from "../../../../atoms/Button";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import { useSelector, useDispatch } from "react-redux";
import { getPatientDiseases } from "src/store/actions/diseaseAction";
import { selectPatientDisease } from "src/store/slices/diseaseSlice";
import MasterDisease from "../../PopUps/MasterDisease";
import ManagePatientDisease from "./ManagePatientDisease";

const HealthIssues = ({ patientId }) => {
  const { popups, openPopup, closePopup } = usePopupVisibility(); // Using the hook for managing popups
  const dispatch = useDispatch();
  const patientDisease = useSelector((state) => selectPatientDisease(state));
  const [editDisease, setEditDisease] = useState({});

  useEffect(() => {
    dispatch(getPatientDiseases(patientId.patientId));
  }, [patientId]);

  const columns = [
    {
      field: "diseaseName",
      title: "Health Issue",
      width: "250px"
    },
    {
      field: "patNote",
      title: "Comment",
      width: "250px"
    },
    {
      field: "recordStatus",
      title: "Status",
      width: "250px"
    }
  ];

  const closeDiseasePopup = () => {
    closePopup("addDisease");
    setTimeout(() => dispatch(getPatientDiseases(patientId.patientId)), 2000);
  };

  const saveEditDiseasePopup = () => {
    closePopup("editDisease");
  };

  const openEditDisease = (patientDisease) => {
    setEditDisease(patientDisease);
    openPopup("editDisease");
  };

  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => openEditDisease(dataItem)
    }
  ];

  return (
    <div>
      <FormActionsBar>
        <Checkbox label={"Inactive"} />
        <Button icon="plus" themeColor="primary" onClick={() => openPopup("addDisease")}>
          {" "}
          Health Issues{" "}
        </Button>
      </FormActionsBar>
      <DynamicGrid
        columns={columns}
        rows={patientDisease}
        key={patientDisease.length}
        actions={actions}
        pageSize={5}
        pageable={true}
      ></DynamicGrid>
      {/* <div className="k-form-buttons" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '10px' }}>
      <Button themeColor="primary">Save</Button>
    </div> */}
      {popups.addDisease && (
        <MasterDisease show={popups.addDisease} patientId={patientId} onClose={closeDiseasePopup} />
      )}
      {popups.editDisease && (
        <ManagePatientDisease
          show={popups.editDisease}
          patientId={patientId}
          onClose={() => closePopup("editDisease")}
          initialData={editDisease}
          onSubmit={saveEditDiseasePopup}
        />
      )}
    </div>
  );
};

export default HealthIssues;
