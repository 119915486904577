import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Dropdown from "src/components/atoms/Dropdown";
import Label from "src/components/atoms/Label";
import Button from "src/components/atoms/Button";
import "./OnlineScheduling.css";
import { useNavigate } from "react-router-dom";

const doctors = [
  {
    id: 1,
    name: "Dr. John Smith",
    description:
      "As a passionate advocate for oral health and a committed dental professional, Dr. John brings a wealth of knowledge and experience to the field of dental hygiene.",
    timeSlots: [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
      "7:00 PM"
    ]
  },
  {
    id: 2,
    name: "Dr. Jason Smith",
    description:
      "Dr. Jason, born into a family of two physicians, was instilled with a deep sense of compassion and a dedication to creating a positive influence on people's lives from an early age.",
    timeSlots: [
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
      "7:00 PM"
    ]
  }
];

const OnlineScheduling = () => {
  const navigate = useNavigate();
  const [preferredTime, setPreferredTime] = useState("All");
  const [typeOfVisit, setTypeOfVisit] = useState("First Adult Visit (60 min)");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [patientType, setPatientType] = useState("New Patient"); // Default to "New Patient"
  const [visibleDoctor, setVisibleDoctor] = useState(doctors[0]);
  const preferredTimeOptions = ["All", "Morning", "Afternoon", "Evening"];
  const typeOfVisitOptions = [
    "Adult Periodic Cleaning and Exam (60 min)",
    "First Adult Visit (60 min)",
    "Emergency Exam (30 min)",
    "Orthodontic Consultation (60 min)",
    "Phillip Testing (60 min)",
    "Dental (60 min)"
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePatientTypeChange = (type) => {
    setPatientType(type);
    if (type === "Returning Patient") {
      setVisibleDoctor(doctors[1]); // Update the doctor for Returning Patient
    }
  };

  const filterTimeSlots = (timeSlots) => {
    if (preferredTime === "All") return timeSlots;

    return timeSlots.filter((slot) => {
      const [time, period] = slot.split(" ");
      let [hours, minutes] = time.split(":").map(Number);
      if (period === "PM" && hours !== 12) hours += 12; // Convert to 24-hour format

      if (preferredTime === "Morning" && hours >= 6 && hours < 12) return true;
      if (preferredTime === "Afternoon" && hours >= 12 && hours < 17) return true;
      if (preferredTime === "Evening" && hours >= 17 && hours <= 21) return true;

      return false;
    });
  };

  const handleSlotClick = (doctor, slot) => {
    const bookingDetails = {
      doctorName: doctor.name,
      timeSlot: slot,
      selectedService: typeOfVisit,
      date: selectedDate.toISOString() // Send the date in ISO format
    };

    navigate("/bookmyvisit", { state: bookingDetails });
  };
  return (
    // <NavbarTemplate>
    <div className="online-scheduling-container">
      <h3>Schedule Appointment</h3>
      <div className="patient-type-plus-help">
        <div className="lable-plus-dropdown-online-scheduling">
          <Label style={{ width: "300px" }}>Have You Visited Us Before?</Label>
          <section className="patient-buttons-online-scheduling">
            <Button
              style={{
                backgroundColor: patientType === "New Patient" ? "#007BFF" : "#f1f1f1",
                color: patientType === "New Patient" ? "#fff" : "#000"
              }}
              onClick={() => handlePatientTypeChange("New Patient")}
            >
              New Patient
            </Button>
            <Button
              style={{
                backgroundColor: patientType === "Returning Patient" ? "#007BFF" : "#f1f1f1",
                color: patientType === "Returning Patient" ? "#fff" : "#000"
              }}
              onClick={() => handlePatientTypeChange("Returning Patient")}
            >
              Returning Patient
            </Button>
          </section>
        </div>

        <div className="lable-plus-dropdown-online-scheduling">
          <Label style={{ width: "350px" }}>How can we help you:</Label>
          <Dropdown
            style={{ width: "350px" }}
            options={typeOfVisitOptions}
            value={typeOfVisit}
            onChange={setTypeOfVisit}
          />
        </div>
      </div>

      <div className="lable-plus-dropdown-online-scheduling">
        <Label style={{ width: "300px" }}>Preferred Time:</Label>
        <Dropdown
          style={{ width: "300px" }}
          options={preferredTimeOptions}
          value={preferredTime}
          onChange={setPreferredTime}
        />
      </div>

      <div className="patient-type-plus-help">
        <div className="lable-plus-dropdown-online-scheduling">
          <Label style={{ width: "350px" }}>Preferred Date:</Label>
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </div>

        <div className="doctors-plus-available-slots">
          {patientType === "New Patient"
            ? doctors.map((doctor) => (
                <div key={doctor.id} style={{ marginBottom: "30px" }}>
                  {filterTimeSlots(doctor.timeSlots).length > 0 ? (
                    <>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                        <img
                          src={`https://via.placeholder.com/80?text=${doctor.name.split(" ")[1]}`}
                          alt={doctor.name}
                          style={{
                            borderRadius: "50%",
                            marginRight: "15px"
                          }}
                        />
                        <div>
                          <h4>{doctor.name}</h4>
                          <p>{doctor.description}</p>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {filterTimeSlots(doctor.timeSlots).map((slot, index) => (
                          <Button
                            key={index}
                            style={{ padding: "10px" }}
                            onClick={() => handleSlotClick(doctor, slot)}
                          >
                            {slot}
                          </Button>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p>
                      Dental does not have any openings/slots on this date. Please select another
                      date from the calendar.
                    </p>
                  )}
                </div>
              ))
            : visibleDoctor && (
                <div>
                  {filterTimeSlots(visibleDoctor.timeSlots).length > 0 ? (
                    <>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                        <img
                          src={`https://via.placeholder.com/80?text=${visibleDoctor.name.split(" ")[1]}`}
                          alt={visibleDoctor.name}
                          style={{
                            borderRadius: "50%",
                            marginRight: "15px"
                          }}
                        />
                        <div>
                          <h4>{visibleDoctor.name}</h4>
                          <p>{visibleDoctor.description}</p>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {filterTimeSlots(visibleDoctor.timeSlots).map((slot, index) => (
                          <Button
                            key={index}
                            style={{ padding: "10px" }}
                            onClick={() => handleSlotClick(visibleDoctor, slot)}
                          >
                            {slot}
                          </Button>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p style={{ color: "red !important" }}>
                      Dental does not have any openings/slots on this date. Please select another
                      date from the calendar.
                    </p>
                  )}
                </div>
              )}
        </div>
      </div>
    </div>
  );
};

export default OnlineScheduling;
