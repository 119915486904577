import React, { useEffect, useState } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import DynamicGrid from "../../../../organisms/DynamicGrid";
import FormActionsBar from "../../../../organisms/FormActionBar";
import Button from "../../../../atoms/Button";
import MasterMedication from "../../PopUps/MasterMedication";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import { useSelector, useDispatch } from "react-redux";
import { getPatientMedications } from "src/store/actions/medicationAction";
import { selectPatientMedication } from "src/store/slices/medicationSlice";
import ManagePatientMedication from "./ManagePatientMedication";

const Medications = ({ patientId }) => {
  const { popups, openPopup, closePopup } = usePopupVisibility(); // Using the hook for managing popups
  const dispatch = useDispatch();
  const patientMedication = useSelector((state) => selectPatientMedication(state));
  const [editMedication, setEditMedication] = useState({});

  useEffect(() => {
    dispatch(getPatientMedications(patientId.patientId));
  }, [patientId]);

  const columns = [
    {
      field: "medicationName",
      title: "Medication",
      width: "250px"
    },
    {
      field: "medDescript",
      title: "Prescription",
      width: "250px"
    },
    {
      field: "patNote",
      title: "Comment",
      width: "250px"
    },
    {
      field: "recordStatus",
      title: "Status",
      width: "250px"
    }
  ];

  const closeMedicationPopup = () => {
    closePopup("addMedication");
    setTimeout(() => dispatch(getPatientMedications(patientId.patientId)), 2000);
  };

  const saveEditMedicationPopup = () => {
    closePopup("editMedication");
  };

  const openEditMedication = (patientMedication) => {
    setEditMedication(patientMedication);
    openPopup("editMedication");
  };

  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => openEditMedication(dataItem)
    }
  ];

  return (
    <div style={{ height: "500px" }}>
      <FormActionsBar>
        <Checkbox label={"Inactive"} />
        <Button icon="plus" themeColor="primary" onClick={() => openPopup("addMedication")}>
          {" "}
          Medications{" "}
        </Button>
      </FormActionsBar>
      <DynamicGrid
        rows={patientMedication}
        columns={columns}
        key={patientMedication.length}
        actions={actions}
        pageSize={5}
        pageable={true}
      ></DynamicGrid>
      <div
        className="k-form-buttons"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "10px"
        }}
      >
        <Button themeColor="primary">Save</Button>
        {popups.addMedication && (
          <MasterMedication
            show={popups.addMedication}
            patientId={patientId}
            onClose={closeMedicationPopup}
          />
        )}
        {popups.editMedication && (
          <ManagePatientMedication
            show={popups.editMedication}
            patientId={patientId}
            onClose={() => closePopup("editMedication")}
            initialData={editMedication}
            onSubmit={saveEditMedicationPopup}
          />
        )}
      </div>
    </div>
  );
};

export default Medications;
