import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchProcedureCodeList = createAsyncThunk(
  "patient/fetchProcedureCodeList",
  async () => {
    try {
      const response = await httpClient.get(`/ProcedureCodeMaster/getAll`); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching procedure code list");
    }
  }
);

export const fetchProcedureCodeBySearch = createAsyncThunk(
  "patient/fetchProcedureCodeBySearch",
  async (searchRequest) => {
    try {
      const response = await httpClient.post(`/ProcedureCodeMaster/searchProcedure`, searchRequest); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching procedure code list");
    }
  }
);
