import React, { useState } from "react";
import "@progress/kendo-theme-default/dist/all.css";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { RadioButton } from "@progress/kendo-react-inputs";
import InputField from "src/components/atoms/InputField";
import Button from "src/components/atoms/Button";
import Label from "src/components/atoms/Label";
import NavbarTemplate from "src/components/templates/NavbarTemplate";
import "./AdminInvoicingFees.css";

const AdminInvoicingFees = () => {
  const [applyDate, setApplyDate] = useState(new Date());
  const [feeType, setFeeType] = useState("invoicing");
  const [interestRate, setInterestRate] = useState(3);
  const [minimumFee, setMinimumFee] = useState(1.0);
  const [minimumBalance, setMinimumBalance] = useState(1.0);
  const [balancePeriod, setBalancePeriod] = useState("90+");

  const handleApplyFees = () => {
    alert("Fees Applied Successfully!");
  };

  const handleReverseFees = () => {
    alert("Fees Reversed Successfully!");
  };

  return (
    <NavbarTemplate>
      <div className="admin-invoicing-fees">
        <h3>Invoicing fees</h3>
        <div className="label-plus-date-invoice">
          <Label style={{ width: "100px" }}>Apply On:</Label>
          <DatePicker
            value={applyDate}
            onChange={(e) => setApplyDate(e.value)}
            style={{ marginLeft: "10px", width: "200px" }}
          />
        </div>

        <div className="fee-type-section">
          <div className="radio-input-group">
            <RadioButton
              name="feeType"
              value="invoicing"
              checked={feeType === "invoicing"}
              onChange={() => setFeeType("invoicing")}
              label="Invoicing Fee"
            />
            <InputField
              value={minimumFee}
              onChange={(e) => setMinimumFee(e.target.value)}
              style={{ width: "100px" }}
              type="number"
              disabled={feeType !== "invoicing"}
            />
          </div>

          <div className="radio-input-group">
            <RadioButton
              name="feeType"
              value="interest"
              checked={feeType === "interest"}
              onChange={() => setFeeType("interest")}
              label="Interest"
            />
            <div className="interest-fields">
              <InputField
                value={interestRate}
                onChange={(e) => setInterestRate(e.target.value)}
                style={{ width: "100px", marginRight: "10px" }}
                type="number"
                disabled={feeType !== "interest"}
              />
              <div className="label-plus-date-invoice">
                <Label style={{ width: "150px" }}>Minimum Fee:</Label>
                <InputField
                  value={minimumFee}
                  onChange={(e) => setMinimumFee(e.target.value)}
                  style={{ width: "100px" }}
                  type="number"
                  disabled={feeType !== "interest"}
                />
              </div>
              <div className="label-plus-date-invoice">
                <Label style={{ width: "150px" }}>Minimum Balance:</Label>
                <InputField
                  value={minimumBalance}
                  onChange={(e) => setMinimumBalance(e.target.value)}
                  style={{ width: "100px" }}
                  type="number"
                  disabled={feeType !== "interest"}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <Label>Balance Period:</Label>
          <div className="balance-period-section">
            <section>
              <RadioButton
                name="balancePeriod"
                value="30+"
                checked={balancePeriod === "30+"}
                onChange={() => setBalancePeriod("30+")}
              />
              <Label>30+ days</Label>
            </section>
            <section>
              <RadioButton
                name="balancePeriod"
                value="60+"
                checked={balancePeriod === "60+"}
                onChange={() => setBalancePeriod("60+")}
              />
              <Label>60+ days</Label>
            </section>
            <section>
              <RadioButton
                name="balancePeriod"
                value="90+"
                checked={balancePeriod === "90+"}
                onChange={() => setBalancePeriod("90+")}
              />
              <Label>90+ days</Label>
            </section>
          </div>
        </div>

        <div className="invoice-fees-button-section">
          <Button onClick={handleApplyFees} style={{ backgroundColor: "#e53935", color: "white" }}>
            Apply Fees
          </Button>
          <Button
            onClick={handleReverseFees}
            style={{ backgroundColor: "#1e88e5", color: "white" }}
          >
            Reverse Fees
          </Button>
        </div>
      </div>
    </NavbarTemplate>
  );
};

export default AdminInvoicingFees;
