import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Button from "src/components/atoms/Button";
import { addClinic, editClinic, fetchStates, fetchZipcode } from "src/services/admin/saclient";

const OfficeAddEditPopup = ({ title, actionType, show, onClose, data, client }) => {
  const [stateOptions, setStateOptions] = useState([]);
  const [zipOptions, setZipOptions] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectedZip, setSelectedZip] = useState("");

  useEffect(() => {
    const getStates = async () => {
      try {
        const statesData = await fetchStates();
        const options =
          statesData?.map((state) => ({
            text: state?.stateName || "",
            value: state?.stateID || "",
            dropdowntype: "state"
          })) || [];
        setStateOptions(options);

        if (data.stateId) {
          setSelectedStateId(data.stateId);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
        setStateOptions([]);
      }
    };

    getStates();
  }, [data.stateId]);

  useEffect(() => {
    if (selectedStateId) {
      const getCities = async () => {
        try {
          const Data = await fetchZipcode(selectedStateId);
          const options =
            Data?.map((citydata) => ({
              text: citydata?.zipCodeDigits || "",
              value: citydata?.zipCodeId || "",
              city: citydata?.city || "",
              dropdowntype: "zip"
            })) || [];
          setZipOptions(options);
          if (data.zipCodeId) {
            const selectedZipData = options.find((city) => city.value === data.zipId);
            setSelectedZip(selectedZipData || "");
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
          setZipOptions([]);
        }
      };

      getCities();
    }
  }, [selectedStateId, data.zipId]);

  const formFields = [
    { field: "description", label: "Office Name" },
    { field: "abbr", label: "Abbreviation" },
    { field: "phone", label: "Office Phone" },
    { field: "emailAddress", label: "Office Email" },
    { field: "address", label: "Address" },
    {
      field: "reviewtype",
      label: "Review Type",
      type: "select",
      options: [
        { text: "None", value: "none" },
        { text: "Social Media Review", value: "review" }
      ]
    },
    {
      field: "timeZone",
      label: "Timezone",
      type: "select",
      options: [
        { text: "None", value: "none" },
        { text: "US/central", value: "central" }
      ]
    },

    {
      field: "state",
      label: "State",
      type: "select",
      options: stateOptions || []
    },
    {
      field: "zipId",
      label: "Zip",
      type: "select",
      options: zipOptions || []
    }
  ];

  const handleSubmit = async (formData) => {
    formData.city = selectedZip?.city || formData.city || "";

    switch (actionType) {
      case "addoffice": {
        const officeformData = {
          ...formData,
          clientId: client.id
        };
        await addClinic(officeformData);
        break;
      }
      case "editoffice":
        await editClinic(formData);
        break;
      default:
        console.warn("Unknown action type:", actionType);
    }
    onClose();
  };

  if (!show) return null;

  const CustomInput = ({ label, type, onChange, value, options, ...props }) => {
    const containerStyle = {
      display: "grid",
      gridTemplateColumns: "150px 1fr",
      gap: "10px",
      alignItems: "center",
      marginBottom: "15px"
    };

    const labelStyle = {
      textAlign: "right",
      paddingRight: "10px",
      color: "white"
    };

    const handleInputChange = (e) => {
      if (type === "select") {
        const selectedItem = e.target.value;
        const otherdropdown = e.target.value.text;
        console.log(otherdropdown);
        console.log(e);

        if (typeof selectedItem === "object" && selectedItem !== null) {
          if (selectedItem.dropdowntype === "state") {
            onChange({ value: selectedItem.value });
            setSelectedStateId(selectedItem.value);
            setSelectedZip("");
          }
          if (selectedItem.dropdowntype === "zip") {
            onChange({ value: selectedItem.value });
            setSelectedZip(selectedItem);
          }
          if (otherdropdown === "Social Media Review" || otherdropdown === "US/central") {
            onChange({ value: e.target.value.value });
          }
        }
      } else {
        onChange({ value: e.target.value });
      }
    };

    return (
      <div style={containerStyle}>
        <label style={labelStyle}>{label}:</label>
        {type === "select" ? (
          <ComboBox
            data={options}
            textField="text"
            dataItemKey="value"
            value={options.find((opt) => opt.value === value)}
            onChange={handleInputChange}
            style={{ width: "100%" }}
            clearButton={false}
            placeholder={`Select ${label}`}
            filterable={true}
          />
        ) : type === "file" ? (
          <Input type="file" {...props} onChange={handleInputChange} />
        ) : (
          <Input {...props} value={value || ""} onChange={handleInputChange} />
        )}
      </div>
    );
  };

  return (
    <Dialog title={title} onClose={onClose} width={800}>
      <Form
        // initialValues={data}
        initialValues={{
          ...data,
          state: data.stateId || "",
          zipId: data.zipId || "",
          city: selectedZip?.city || data.city || ""
        }}
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <form onSubmit={formRenderProps.onSubmit}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                padding: "20px"
              }}
            >
              <div>
                {formFields.slice(0, Math.ceil(formFields.length / 2)).map((field) => (
                  <Field
                    key={field.field}
                    name={field.field}
                    component={CustomInput}
                    label={field.label}
                    type={field.type}
                    options={field.options}
                    value={formRenderProps.valueGetter(field.field)}
                  />
                ))}
              </div>

              <div>
                {formFields.slice(Math.ceil(formFields.length / 2)).map((field) => (
                  <Field
                    key={field.field}
                    name={field.field}
                    component={CustomInput}
                    label={field.label}
                    type={field.type}
                    options={field.options}
                    value={formRenderProps.valueGetter(field.field)}
                  />
                ))}
                <Field
                  name="city"
                  component={({ value, onChange, ...props }) => (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "150px 1fr",
                        gap: "10px",
                        alignItems: "center",
                        marginBottom: "15px"
                      }}
                    >
                      <label style={{ textAlign: "right", paddingRight: "10px", color: "white" }}>
                        City:
                      </label>
                      <Input
                        {...props}
                        value={selectedZip?.city || value || ""}
                        onChange={onChange}
                      />
                    </div>
                  )}
                  value={selectedZip?.city}
                />
              </div>
            </div>
            <div style={{ borderTop: "1px solid #ddd", margin: "10px 0" }}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
                padding: "20px"
              }}
            >
              <Button type="submit" themeColor="primary" disabled={!formRenderProps.modified}>
                Submit
              </Button>
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

export default OfficeAddEditPopup;
