import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearNotification } from "src/store/slices/appSlice";

export const useAutoDismiss = (notification, timeout = 4000) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        dispatch(clearNotification());
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [notification, dispatch, timeout]);
};
