import { createSlice } from "@reduxjs/toolkit";
import { fetchClinics, fetchDoctors } from "../actions/clinicAction";
import Swal from "sweetalert2";

const clinics = createSlice({
  name: "clinics",
  initialState: {
    clinics: [],
    doctors: [],
    loading: false,
    error: null,
    selectedClinic: null,
    selectedDoctor: null
  },

  reducers: {
    setClinic: (state, action) => {
      state.selectedClinic = action.payload;
    },

    setDoctor: (state, action) => {
      state.doctors = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchClinics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClinics.fulfilled, (state, action) => {
        state.loading = false;
        state.clinics = action.payload;
      })
      .addCase(fetchClinics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setClinic, setDoctor } = clinics.actions;
export const selectClinic = (state) => state.clinics.data;
export const selectDoctor = (state) => state.clinics.doctors;
export const selectClinics = (state) => state.clinics;
export default clinics.reducer;
