import { createAsyncThunk } from "@reduxjs/toolkit";
import { InsuranceCompanyService, PlanService } from "src/services/admin/insurance";

export const fetchInsuranceCompanies = createAsyncThunk(
  "admin/insurance/fetchInsuranceCompanies",
  async (isActive, { rejectWithValue }) => {
    try {
      return await InsuranceCompanyService.getInsuranceCompanies(isActive);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteInsuranceComapny = createAsyncThunk(
  "admin/insurance/deleteInsuranceComapny",
  async (id, { rejectWithValue }) => {
    try {
      return await InsuranceCompanyService.deleteInsuranceCompany(id);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchPlans = createAsyncThunk(
  "admin/insurance/fetchPlans",
  async (isActive, { rejectWithValue }) => {
    try {
      return await PlanService.getPlans(isActive);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addInsuranceCompany = createAsyncThunk(
  "admin/insurance/addInsuranceCompany",
  async (formData, { rejectWithValue }) => {
    try {
      const data = {
        insuranceCompanyName: formData.insuranceCompanyName,
        address: formData.address,
        address2: formData.address2,
        city: formData.city,
        stateId: formData.stateId,
        zip: formData.zip,
        phone: formData.phone,
        isActive: formData.isActive
      };
      return await InsuranceCompanyService.addInsuranceCompany(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateInsuranceCompany = createAsyncThunk(
  "admin/insurance/updateInsuranceCompany",
  async (formData, { rejectWithValue }) => {
    try {
      const data = {
        id: formData.id,
        insuranceCompanyName: formData.insuranceCompanyName,
        address: formData.address,
        address2: formData.address2,
        city: formData.city,
        stateId: formData.stateId,
        zip: formData.zip,
        phone: formData.phone,
        isActive: formData.isActive
      };
      return await InsuranceCompanyService.updateInsuranceCompany(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
