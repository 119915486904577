import React, { useRef, useEffect } from "react";
import CommonPopup from "../../../molecules/CommonPopup"; // Import the reusable CommonPopup component
import DynamicForm from "../../../organisms/DynamicForm"; // Import the dynamic form component
import PatientVisitsGrid from "./PatientVisitsGrid"; // Import the custom patient visit grid component
import { useSelector, useDispatch } from "react-redux";
import { selectProfileDetails } from "src/store/slices/patientSlice";
import { fetchFamilyVisitDetails } from "src/store/actions/visitAction";
import { selectFamilyVisitDetails } from "src/store/slices/visitSlice";
const PatientVisitPopup = ({ show, onClose }) => {
  const profile = useSelector((state) => selectProfileDetails(state));
  const gridRef = useRef();
  const familyVisitDetails = useSelector((state) => selectFamilyVisitDetails(state));
  const dispatch = useDispatch();
  // Handle patient change (e.g., through dropdown selection)

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchFamilyVisitDetails(profile.patientId));
  }, [dispatch, profile]);

  const handlePrint = () => {
    gridRef.current.printGrid();
  };
  // Define the dynamic form structure for the selected patient
  const formStructure = {
    title: "Patient Visit",
    tabs: [
      {
        name: "Patient Info",
        header: {
          right: [
            {
              type: "button",
              text: "Family Recare",
              onClick: () => alert("Family Recare clicked"),
              icon: "add"
            },
            {
              type: "button",
              text: "Patient Recare",
              onClick: () => alert("Patient Recare clicked"),
              icon: "add"
            },
            {
              type: "button",
              text: "Print",
              onClick: () => handlePrint(),
              icon: "print"
            }
          ]
        },
        sections: [
          {
            customSection: (
              <PatientVisitsGrid
                gridRef={gridRef}
                data={familyVisitDetails} // Pass visit data to the grid
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title={"Patient Visit"}>
      {/* Dynamic Form for Patient Information */}
      <DynamicForm tabs={formStructure.tabs} handleFormSubmit={() => alert("Form Submitted")} />
    </CommonPopup>
  );
};

export default PatientVisitPopup;
