import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  notification: null
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      // Only update if the state is different
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    showNotification: (state, action) => {
      state.notification = {
        message: action.payload.message,
        type: action.payload.type || "info", // 'success' | 'error' | 'info' | 'warning'
        duration: action.payload.duration || 3000
      };
    },
    clearNotification: (state) => {
      state.notification = null;
    }
  }
});

export const { setLoading, showNotification, clearNotification } = appSlice.actions;

export default appSlice.reducer;

// Helper functions for common notification types
export const showError = (message) => showNotification({ message, type: "error" });
export const showSuccess = (message) => showNotification({ message, type: "success" });
export const showInfo = (message) => showNotification({ message, type: "info" });
export const showWarning = (message) => showNotification({ message, type: "warning" });
