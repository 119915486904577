import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Button from "src/components/atoms/Button";
import { useDispatch } from "react-redux";
import {
  addInsuranceCompany,
  updateInsuranceCompany
} from "src/store/actions/admin/insuranceActions";
import { addEmployer, updateEmployer } from "src/store/actions/admin/employerActions";

const AdminAddEditPopup = ({
  title,
  actionType,
  show,
  onClose,
  data,
  formFields,
  NRowinColumn,
  width
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (formData) => {
    switch (actionType) {
      case "addcompany":
        dispatch(addInsuranceCompany(formData));
        break;
      case "editcompany":
        dispatch(updateInsuranceCompany(formData));
        break;
      case "addemployer":
        dispatch(addEmployer(formData));
        break;
      case "editemployer":
        console.log("editemployer", formData);
        dispatch(updateEmployer(formData));
        break;
      default:
        console.warn("Unknown action type:", actionType);
    }
    onClose();
  };

  if (!show) return null;

  const CustomInput = ({ label, type, onChange, value, options, ...props }) => {
    const containerStyle = {
      display: "grid",
      gridTemplateColumns: " 130px 1fr",
      gap: "10px",
      alignItems: "center",
      marginBottom: "15px"
    };

    const labelStyle = {
      textAlign: "right",
      paddingRight: "10px",
      color: "white"
    };

    const handleInputChange = (e) => {
      if (type === "select") {
        onChange({ value: e.target.value.value });
      } else {
        onChange({ value: e.target.value });
      }
    };

    return (
      <div style={containerStyle}>
        <label style={labelStyle}>{label}:</label>
        {type === "select" ? (
          <DropDownList
            data={options}
            textField="text"
            dataItemKey="value"
            value={options.find((opt) => opt.value === value)}
            onChange={handleInputChange}
            style={{ width: "100%" }}
          />
        ) : (
          <Input {...props} value={value || ""} onChange={handleInputChange} />
        )}
      </div>
    );
  };

  return (
    <Dialog title={title} onClose={onClose} width={width || 800}>
      <Form
        initialValues={data}
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <form onSubmit={formRenderProps.onSubmit}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                padding: "20px"
              }}
            >
              <div>
                {formFields.slice(0, NRowinColumn || 6).map((field) => (
                  <Field
                    key={field.field}
                    name={field.field}
                    component={CustomInput}
                    label={field.label}
                    type={field.type}
                    options={field.options}
                    value={formRenderProps.valueGetter(field.field)}
                  />
                ))}
              </div>

              <div>
                {formFields.slice(NRowinColumn || 6).map((field) => (
                  <Field
                    key={field.field}
                    name={field.field}
                    component={CustomInput}
                    label={field.label}
                    type={field.type}
                    options={field.options}
                    value={formRenderProps.valueGetter(field.field)}
                  />
                ))}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
                padding: "20px"
              }}
            >
              <Button type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" themeColor="primary">
                Update
              </Button>
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

export default AdminAddEditPopup;
