import React, { useState, useEffect } from "react";
import DynamicGrid from "../../../organisms/DynamicGrid";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import TreatmentPopup from "./Treatment";
import { deletePatientProcedure } from "src/store/actions/patientProcedureAction";
import { useDispatch } from "react-redux";

const PatientVisitTreatmentGrid = ({ gridRef, data = [], onDelete }) => {
  const { popups, openPopup, closePopup } = usePopupVisibility();
  const [selectedTreatmentId, setSelectedTreatmentId] = useState(null);
  const dispatch = useDispatch();
  const [deleteProcedure, setDeleteProcedure] = useState(null);
  // Prepare rows by transforming the input data structure
  const rows = data?.map((treatment, index) => ({
    id: treatment.id, // Generate unique ID for each row
    treatment: treatment.treatment || "No Description", // Map treatment name
    code: treatment.code || "-", // Map code
    tooth: treatment.tooth || "-", // Map tooth (null replaced with "-")
    area: treatment.area || "-", // Map area (null replaced with "-")
    fee: treatment.fee || "-" // Map fee
  }));

  useEffect(() => {
    if (deleteProcedure) {
      dispatch(deletePatientProcedure(deleteProcedure))
        .unwrap() // Unwrap the promise to handle success/failure
        .then(() => {
          // Success: Fetch updated visit details
          onDelete(true);
        });
    }
  }, [dispatch, deleteProcedure]);
  // Dynamic columns configuration for the parent grid
  const parentColumns = [
    { field: "treatment", title: "Treatement", width: "200px" },
    { field: "code", title: "Code", width: "50px", sortable: true, filterable: true },
    { field: "tooth", title: "Tooth", width: "100px", sortable: true, filterable: true },
    { field: "area", title: "Area", width: "100px", sortable: true, filterable: false },
    { field: "fee", title: "Fee", width: "150px", sortable: true, filterable: true }
  ];

  // Dynamic rows data including visit data for each patient

  // Custom action buttons for the action column
  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => {
        setSelectedTreatmentId(dataItem.id);
        openPopup("editTreatment");
      }
    },
    {
      title: "Delete",
      icon: "delete",
      onClick: (dataItem) => {
        setDeleteProcedure(dataItem.id);
      }
    }
  ];

  // Use DynamicGrid as both parent and detail grid
  return data ? (
    <>
      <DynamicGrid
        ref={gridRef}
        columns={parentColumns}
        rows={rows}
        actions={actions}
        allowExpand={true} // Enable row expand/collapse
        allowSort={true} // Enable sorting
        pageable={true}
        pageSize={10}
      />
      {popups.editTreatment && (
        <TreatmentPopup
          show={popups.editTreatment}
          onClose={() => closePopup("editTreatment")}
          treatmentId={selectedTreatmentId}
        />
      )}
    </>
  ) : (
    ""
  );
};

export default PatientVisitTreatmentGrid;
