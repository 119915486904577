import React, { useState } from "react";
import DynamicGrid from "../../../organisms/DynamicGrid";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import TreatmentPopup from "./Treatment";

const AdjustmentsGrid = ({ gridRef, data = [] }) => {
  // Prepare rows by transforming the input data structure
  const rows = data?.map((treatment, index) => ({
    id: index + 1, // Generate unique ID for each row
    treatment: treatment.treatment || "No Description", // Map treatment name
    code: treatment.code || "-", // Map code
    tooth: treatment.tooth || "-", // Map tooth (null replaced with "-")
    area: treatment.area || "-", // Map area (null replaced with "-")
    fee: treatment.fee || "-" // Map fee
  }));

  // Dynamic columns configuration for the parent grid
  const parentColumns = [
    { field: "date", title: "Date", width: "70px" },
    {
      field: "amount",
      title: "Amount",
      width: "120px",
      sortable: true,
      filterable: true
    },
    {
      field: "adjustment",
      title: "Adjustment",
      width: "100px",
      sortable: true,
      filterable: true
    },
    { field: "comment", title: "Comments", width: "200px", sortable: true, filterable: false }
  ];

  // Custom action buttons for the action column
  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => {
        setSelectedTreatmentId(dataItem.id);
        openPopup("editTreatment");
      }
    },
    {
      title: "Delete",
      icon: "delete",
      onClick: (dataItem) => alert(`Delete `)
    }
  ];

  // Custom action buttons for the action column

  // Use DynamicGrid as both parent and detail grid
  return data ? (
    <>
      <DynamicGrid
        ref={gridRef}
        columns={parentColumns}
        actions={actions}
        rows={rows}
        allowExpand={true} // Enable row expand/collapse
        allowSort={true} // Enable sorting
      />
    </>
  ) : (
    ""
  );
};

export default AdjustmentsGrid;
