import React, { useState, useCallback, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import IconButton from "../../atoms/IconButton";
import { Icon } from "@progress/kendo-react-common";
import SearchComponent from "./SearchComponent";
import ClientAddEditpopup from "./ClientAddEditPopup";
import OfficeAddEditPopup from "./OfficeAddEditPopup";
import {
  fetchOfficeSearch,
  fetchSAClients,
  fetchStates,
  searchSAClient
} from "src/services/admin/saclient";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showClientAddPopup, setShowClientAddPopup] = useState(false);
  const [showClientEditPopup, setShowClientEditPopup] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showOfficeAddPopup, setShowOfficeAddPopup] = useState(false);
  const [showOfficeEditPopup, setShowOfficeEditPopup] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [stateData, setStateData] = useState([]);
  const [activestatus, setActivestatus] = useState(true);

  const setClientsData = (clients) => {
    setTotalSize(clients.total);
    const clientsData = clients.results.map((client) => {
      const stateName =
        stateData.find((option) => option.stateId === client.stateId)?.statename || "";
      return {
        ...client,
        stateName,
        expanded: false
      };
    });
    setClients(clientsData);
  };

  // fetch clients data
  const fetchSAClientsData = useCallback(async () => {
    if (!stateData.length) return;

    try {
      setLoading(true);
      const clients = await fetchSAClients(activestatus, page.skip, page.take);
      if (clients?.results) {
        setClientsData(clients);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [page, stateData, activestatus]);

  useEffect(() => {
    const getStates = async () => {
      try {
        const response = await fetchStates();
        const options =
          response?.map((state) => ({
            statename: state?.stateName || "",
            stateId: state?.stateID || ""
          })) || [];
        setStateData(options);
      } catch (error) {
        console.error("Error fetching states:", error);
        setStateData([]);
      }
    };

    getStates();
  }, []);

  useEffect(() => {
    fetchSAClientsData();
  }, [fetchSAClientsData]);

  const clientColumns = [
    { field: "id", title: "Client ID" },
    { field: "clientName", title: "Client" },
    { field: "owner", title: "Owner" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "stateName", title: "State" },
    { field: "contactPhone", title: "Phone" },
    { field: "contactEmail", title: "Email" },
    { field: "users", title: "Users" }
  ];

  const officeColumns = [
    { field: "id", title: "Office ID" },
    { field: "description", title: "Office" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "stateName", title: "State" },
    { field: "phone", title: "Phone" },
    { field: "emailAddress", title: "Email" },
    { field: "patients", title: "Patients" },
    { field: "users", title: "Users" }
  ];

  const clientSearchColumns = [
    { field: "clientid", title: "Client ID" },
    { field: "client", title: "Client" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "zip", title: "Zip" },
    { field: "email", title: "Email" }
  ];

  const officeSearchColumns = [
    { field: "officeid", title: "Office ID" },
    { field: "office", title: "Office" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "zip", title: "Zip" },
    { field: "email", title: "Email" }
  ];

  const [clientSearchFields, setClientSearchFields] = useState(
    clientColumns.reduce((acc, column) => {
      acc[column.field] = "";
      return acc;
    }, {})
  );

  const [officeSearchFields, setOfficeSearchFields] = useState(
    officeColumns.reduce((acc, column) => {
      acc[column.field] = "";
      return acc;
    }, {})
  );

  const handleInputChange = (setFields) => (field, value) => {
    setFields((prev) => ({ ...prev, [field]: value }));
  };

  const handleClientSearch = async (searchformData) => {
    if (Object.entries(searchformData).length === 0) {
      fetchSAClientsData();
    }

    const stateid = stateData.find(
      (state) => state.statename.toLowerCase() === searchformData?.state?.toLowerCase()
    )?.stateId;
    const clientsearch = {
      ...searchformData,
      state: stateid
    };

    try {
      const data = await searchSAClient(clientsearch, page.skip, page.take, activestatus);

      const clientsData = data.results.map((client) => ({
        ...client,
        expanded: false
      }));
      setTotalSize(data?.total);
      setClients(clientsData);
    } catch (error) {
      console.error("Error searching clients:", error);
    }
  };

  const handleOfficeSearch = async (searchformData) => {
    const stateid = stateData.find(
      (state) => state.statename.toLowerCase() === searchformData?.state?.toLowerCase()
    )?.stateId;

    const officesearch = {
      ...searchformData,
      state: stateid
    };

    const data = await fetchOfficeSearch(officesearch);

    setClientsData(data);
  };

  const onExpandChange = useCallback(
    (event) => {
      const updatedData = clients.map((item) =>
        item.id === event.dataItem.id ? { ...item, expanded: !item.expanded } : item
      );
      setClients(updatedData);
    },
    [clients]
  );

  const actions = [
    {
      icon: "edit", // Edit icon
      title: "Edit",
      onClick: (dataItem) => {
        setShowClientEditPopup(true);
        setSelectedClient(dataItem);
      }
    },
    {
      icon: "add", // Add icon
      title: "Add",
      onClick: (dataItem) => {
        setSelectedClient(dataItem);
        setShowOfficeAddPopup(true);
      }
    }
  ];

  const DetailComponent = (props) => {
    const clientClinics =
      props.dataItem.clinics?.map((clinic) => {
        const stateName =
          stateData.find((option) => option.stateId === clinic.stateId)?.statename || "";
        return {
          ...clinic,
          stateName // Add state name
        };
      }) || [];

    const officeActions = [
      {
        icon: "edit",
        title: "Edit Office",
        onClick: (dataItem) => {
          setShowOfficeEditPopup(true);
          setSelectedOffice(dataItem);
        }
      }
    ];

    const renderOfficeActions = (props) => {
      const dataItem = props.dataItem;
      return (
        <td>
          {officeActions.map((action, index) => (
            <IconButton
              key={index}
              icon={action.icon}
              title={action.title}
              onClick={() => action.onClick(dataItem)}
            />
          ))}
        </td>
      );
    };

    return (
      <Grid data={clientClinics} style={{ overflowY: "auto" }}>
        {officeColumns.map((column, idx) => (
          <Column key={idx} {...column} />
        ))}
        <Column title="Actions" width="150px" cell={renderOfficeActions} />
      </Grid>
    );
  };

  const renderActions = (props) => {
    const dataItem = props.dataItem;
    return (
      <td>
        {actions &&
          actions.map((action, index) => (
            <IconButton
              key={index}
              icon={action.icon}
              title={action.title}
              onClick={() => action.onClick(dataItem)}
            />
          ))}
      </td>
    );
  };

  const ExpandCollapseCell = (props) => {
    const { dataItem } = props;
    const isExpanded = dataItem.expanded;

    return (
      <td style={{ cursor: "pointer", textAlign: "center" }} onClick={() => onExpandChange(props)}>
        <Icon name={isExpanded ? "arrow-s" : "arrow-e"} />
      </td>
    );
  };

  const handleaddClient = () => {
    setShowClientAddPopup(true);
  };

  const onStatusChange = (status) => {
    const isActive = status === "Active";
    setPage({ skip: 0, take: 10 });
    setActivestatus(isActive);
    // fetchInsuranceCompanies(isActive)
  };

  return (
    <DatatableLayout
      title="Clients"
      statusOptions={["Active", "Inactive"]}
      status={activestatus === true ? "Active" : "Inactive"}
      onStatusChange={onStatusChange}
      onAdd={handleaddClient}
      addBtnLabel="New Client"
      onExtraAction={() => console.log("Export data")}
    >
      {showClientAddPopup && (
        <ClientAddEditpopup
          title="New Client"
          actionType="addclient"
          show={showClientAddPopup}
          onClose={() => {
            setShowClientAddPopup(false);
            fetchSAClientsData();
          }}
          data={{}}
        />
      )}
      {showClientEditPopup && (
        <ClientAddEditpopup
          title="Edit Client"
          actionType="editclient"
          show={showClientEditPopup}
          onClose={() => {
            setShowClientEditPopup(false);
            setSelectedClient(null);
            fetchSAClientsData();
          }}
          data={selectedClient}
        />
      )}
      {showOfficeAddPopup && (
        <OfficeAddEditPopup
          title="Add Office"
          actionType="addoffice"
          show={showOfficeAddPopup}
          onClose={() => {
            setShowOfficeAddPopup(false);
            setSelectedClient(null);
            fetchSAClientsData();
          }}
          data={{}}
          client={selectedClient}
        />
      )}
      {showOfficeEditPopup && (
        <OfficeAddEditPopup
          title="Edit Office"
          actionType="editoffice"
          show={showOfficeEditPopup}
          onClose={() => {
            setShowOfficeEditPopup(false);
            setSelectedOffice(null);
            fetchSAClientsData();
          }}
          data={selectedOffice}
        />
      )}
      <div style={{ borderTop: "1px solid #ddd", margin: "10px 0" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          marginBottom: "10px"
        }}
      >
        <SearchComponent
          title="Client Search"
          columns={clientSearchColumns}
          searchFields={clientSearchFields}
          onInputChange={handleInputChange(setClientSearchFields)}
          onSearch={handleClientSearch}
        />
        <SearchComponent
          title="Office Search"
          columns={officeSearchColumns}
          searchFields={officeSearchFields}
          onInputChange={handleInputChange(setOfficeSearchFields)}
          onSearch={handleOfficeSearch}
        />
      </div>
      <div style={{ maxHeight: "350px", overflowY: "auto" }}>
        <Grid
          data={clients}
          expandField="expanded"
          detail={DetailComponent}
          style={{ minWidth: "1000px" }}
          pageable={true}
          total={totalSize}
          onPageChange={(event) => {
            setPage({ skip: event.page.skip, take: event.page.take });
          }}
          skip={page.skip}
          take={page.take}
        >
          <Column width="50px" cell={ExpandCollapseCell} />
          {clientColumns.map((column, idx) => (
            <Column key={idx} {...column} />
          ))}
          {actions.length > 0 && <Column title="Actions" width="150px" cell={renderActions} />}
        </Grid>
      </div>
    </DatatableLayout>
  );
};

export default Clients;
