import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDiseaseMaster, getPatientDiseases } from "../actions/diseaseAction";

const diseaseSlice = createSlice({
  name: "diseases",
  initialState: {
    masterdiseases: [],
    patientDiseases: [],
    loading: {
      masterdiseases: false,
      patientDiseases: false
    },
    error: {
      masterdiseases: [],
      patientDiseases: []
    }
  },

  reducers: {
    setDiseaseMaster(state, action) {
      state.data = action.payload;
    },
    setPatientDiseases(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchDiseaseMaster.pending, (state) => {
        state.loading.masterdiseases = true;
        state.error.masterdiseases = [];
      })
      .addCase(fetchDiseaseMaster.fulfilled, (state, action) => {
        state.loading.masterdiseases = false;
        state.masterdiseases = action.payload;
      })
      .addCase(fetchDiseaseMaster.rejected, (state, action) => {
        state.loading.masterdiseases = false;
        state.error.masterdiseases = action.payload;
      })
      .addCase(getPatientDiseases.pending, (state) => {
        state.loading.patientDiseases = true;
        state.error.patientDiseases = [];
      })
      .addCase(getPatientDiseases.fulfilled, (state, action) => {
        state.loading.patientDiseases = false;
        state.patientDiseases = action.payload;
      })
      .addCase(getPatientDiseases.rejected, (state, action) => {
        state.loading.patientDiseases = false;
        state.error.patientDiseases = action.payload;
      });
  }
});

export const selectDiseaseMaster = (state) => state.diseases.masterdiseases;
export const selectDiseaseMasterLoading = (state) => state.diseases.loading;
export const selectDiseaseMasterError = (state) => state.diseases.error;
export const selectPatientDisease = (state) => state.diseases.patientDiseases;
export const selectPatientDiseaseLoading = (state) => state.diseases.loading;
export const selectPatientDiseaseError = (state) => state.diseases.error;
export const { setDiseaseMaster, setPatientDiseases } = diseaseSlice.actions;
export default diseaseSlice.reducer;
