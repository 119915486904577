import { icon } from "@progress/kendo-react-common";
import React, { useState, useEffect } from "react";
import CommonPopup from "src/components/molecules/CommonPopup"; // Import the reusable CommonPopup component
import DynamicForm from "src/components/organisms/DynamicForm"; // Import the DynamicForm component

const ChairDialog = ({ show, onClose, onSubmit, mode = "add", initialData = {} }) => {
  // Define state for the form data
  const [formData, setFormData] = useState({
    chair: "",
    abbreviation: "",
    status: "Active",
    clinician: "None",
    hygienist: "None",
    hygieneChair: false
  });

  // Update form data when initialData changes (e.g., when editing an item)
  useEffect(() => {
    if (mode === "edit" && initialData) {
      setFormData({
        chair: initialData.opName || "",
        abbreviation: initialData.abbrev || "",
        status: initialData.isActive ? "Active" : "Inactive",
        clinician: initialData.clinician || "None",
        hygienist: initialData.hygienist || "None",
        hygieneChair: initialData.isHygiene || false
      });
    }
  }, [initialData, mode]);

  // Define the dynamic form structure
  const formStructure = {
    tabs: [
      {
        name: "Chair Details",
        sections: [
          {
            fields: [
              {
                name: "chair",
                type: "input",
                label: "Chair",
                placeholder: "Enter Chair Name",
                required: true
              },
              {
                name: "abbreviation",
                type: "input",
                label: "Abbreviation",
                placeholder: "Enter Abbreviation"
              },
              {
                name: "status",
                type: "select",
                label: "Status",
                options: [
                  { label: "Active", value: "Active" },
                  { label: "Inactive", value: "Inactive" }
                ],
                required: true
              },
              {
                name: "clinician",
                type: "select",
                label: "Clinician",
                options: [
                  { label: "None", value: "None" },
                  { label: "Neel Kothari", value: "Neel Kothari" }, //provDentistId = 1
                  { label: "Nirav Shah", value: "Nirav Shah" } //provDentistId = 2
                ]
              },
              {
                name: "hygienist",
                type: "select",
                label: "Hygienist",
                options: [
                  { label: "None", value: "None" },
                  { label: "Jen Simpson", value: "Jen Simpson" }, //provHygienistId = 55
                  { label: "Zadee Caps", value: "Zadee Caps" } //provHygienistId = 1186
                ]
              },
              {
                name: "hygieneChair",
                type: "checkbox",
                label: "Hygiene Chair"
              }
            ]
          }
        ],
        footer: {
          right: [
            {
              type: "button",
              text: "Cancel",
              onClick: onClose
            },
            {
              type: "button",
              text: mode === "add" ? "Add" : "Update",
              onClick: () => handleFormSubmit(formData), // Directly pass formData
              icon: mode === "add" ? "plus" : ""
            }
          ]
        }
      }
    ]
  };

  // Handle form submission
  const handleFormSubmit = (submittedData) => {
    onSubmit(submittedData); // Call the onSubmit callback with form data
    onClose(); // Close the popup
  };

  return (
    <CommonPopup show={show} onClose={onClose} title={mode === "add" ? "Add Chair" : "Edit Chair"}>
      <DynamicForm
        tabs={formStructure.tabs} // Pass the form structure
        formData={formData} // Pass the form data state
        setFormData={setFormData} // Pass the setter for form data
        handleFormSubmit={handleFormSubmit} // Handle form submission
        mode={mode} // Mode of the form (add/edit)
        footer={formStructure.footer} // Footer configuration
      />
    </CommonPopup>
  );
};

export default ChairDialog;
