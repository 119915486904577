import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";

const PatientCarousel = ({ familyMembers }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // Slide one item at a time
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const CustomLeftArrow = ({ onClick }) => (
    <button onClick={onClick} className="custom-arrow custom-left-arrow">
      <div className="arrow-shape"></div>
    </button>
  );

  const CustomRightArrow = ({ onClick }) => (
    <button onClick={onClick} className="custom-arrow custom-right-arrow">
      <div className="arrow-shape"></div>
    </button>
  );

  const navigate = useNavigate();
  const handleProfileClick = (patientId) => {
    // Redirect to the patient page with the patient ID as a parameter
    navigate(`/patient/${patientId}`);
  };

  return (
    <div className="patient-carousel">
      {familyMembers && familyMembers.length > 0 && (
        <Carousel
          responsive={responsive}
          infinite={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          showDots={false}
          arrows
          autoPlay={true}
          keyBoardControl
          containerClass="carousel-container"
          itemClass="carousel-item"
        >
          {familyMembers.map((member, index) => (
            <div
              key={index}
              className="carousel-item"
              onClick={() => handleProfileClick(member.id)}
            >
              <div className="family-image-wrapper">
                <div className="family-image ">{member?.name?.[0] || ""}</div>
              </div>
              <p className="family-member-name">{member.name}</p>
              <p className="family-member-details">{member.ageGender}</p>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default PatientCarousel;
