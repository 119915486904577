import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchProfileDetails = createAsyncThunk(
  "patient/fetchProfileDetails",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(`/Patient/getprofiledetails/${patientId}`);
      if (response) response.patientId = patientId;
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching patient");
    }
  }
);

export const fetchProfileStrip = createAsyncThunk(
  "patient/fetchProfileStrip",
  async (patientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/Patient/getprofilestrip/${patientId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching patient");
    }
  }
);

export const fetchSearchPatient = createAsyncThunk(
  "patient/fetchSearchPatient",
  async (patientSearchModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(`/Patient/searchpatient`, patientSearchModel);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching patient");
    }
  }
);

export const fetchInsuranceDetails = createAsyncThunk(
  "patient/fetchInsuranceDetails",
  async (patientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/PatientInsurance/getpatientinsurancedetails/418468`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching patient");
    }
  }
);

export const savePatient = createAsyncThunk(
  "Patient/savepatient",
  async (patientModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(`/Patient/savepatient`, patientModel);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error saving patient");
    }
  }
);

export const getPatientRecall = createAsyncThunk(
  "PatientRecall/getpatientrecallbypatientid",
  async (patientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/PatientRecall/getpatientrecallbypatientid/${patientId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching patient recall");
    }
  }
);
