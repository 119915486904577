import { createSlice } from "@reduxjs/toolkit";
import { fetchInsuranceCompanies, fetchPlans } from "src/store/actions/admin/insuranceActions";

const insurance = createSlice({
  name: "insurance",
  initialState: {
    insuranceCompanies: [],
    insuranceCompanyStatusIsActive: true,
    plans: [],
    plansStatusIsActive: true,
    loading: false,
    plansLoading: false,
    error: null,
    plansError: null
  },

  reducers: {
    setInsuranceCompanyStatus: (state, action) => {
      state.insuranceCompanies = [];
      state.insuranceCompanyStatusIsActive = action.payload;
    },
    setPlansStatus: (state, action) => {
      state.plans = [];
      state.plansStatusIsActive = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchInsuranceCompanies.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(fetchInsuranceCompanies.fulfilled, (state, action) => {
      state.loading = false;
      state.insuranceCompanies = action.payload;
    });

    builder.addCase(fetchInsuranceCompanies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchPlans.pending, (state) => {
      state.plansLoading = true;
      state.plansError = null;
    });

    builder.addCase(fetchPlans.fulfilled, (state, action) => {
      state.plansLoading = false;
      state.plans = action.payload.filter((plan) => plan.isActive === state.plansStatusIsActive);
    });

    builder.addCase(fetchPlans.rejected, (state, action) => {
      state.plansLoading = false;
      state.plansError = action.payload;
    });
  }
});
export const { setInsuranceCompanyStatus, setPlansStatus } = insurance.actions;
export default insurance.reducer;
