import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEmployeeByClinicId, getEmployeeByClientId } from "../actions/staffAction";

const staffSlice = createSlice({
  name: "staff",
  initialState: {
    employeesByClinic: [],
    employeesByClient: [],
    loading: {
      employeesByClinic: false,
      employeesByClient: false
    },
    error: {
      employeesByClinic: [],
      employeesByClient: []
    }
  },

  reducers: {
    setEmployeesByClinic(state, action) {
      state.data = action.payload;
    },
    setEmployeesByClient(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeByClinicId.pending, (state) => {
        state.loading.employeesByClinic = true;
        state.error.employeesByClinic = [];
      })
      .addCase(getEmployeeByClinicId.fulfilled, (state, action) => {
        state.loading.employeesByClinic = false;
        state.employeesByClinic = action.payload;
      })
      .addCase(getEmployeeByClinicId.rejected, (state, action) => {
        state.loading.employeesByClinic = false;
        state.error.employeesByClinic = action.payload;
      })
      .addCase(getEmployeeByClientId.pending, (state) => {
        state.loading.employeesByClient = true;
        state.error.employeesByClient = [];
      })
      .addCase(getEmployeeByClientId.fulfilled, (state, action) => {
        state.loading.employeesByClient = false;
        state.employeesByClient = action.payload;
      })
      .addCase(getEmployeeByClientId.rejected, (state, action) => {
        state.loading.employeesByClient = false;
        state.error.employeesByClient = action.payload;
      });
  }
});

export const selectEmployeesClinic = (state) => state.staff.employeesByClinic;
export const selectEmployeesClinicLoading = (state) => state.staff.loading;
export const selectEmployeesClinicError = (state) => state.staff.error;
export const selectEmployeesClient = (state) => state.staff.employeesByClient;
export const selectEmployeesClientLoading = (state) => state.staff.loading;
export const selectEmployeesClientError = (state) => state.staff.error;
export const { setEmployeesByClient, setEmployeesByClinic } = staffSlice.actions;
export default staffSlice.reducer;
