import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchMedicationMaster = createAsyncThunk(
  "MedicationMaster/getMedications",
  async (patientSearchModel, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/MedicationMaster/getMedications`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching Medication master");
    }
  }
);

export const getPatientMedications = createAsyncThunk(
  "PatientMedication/getallpatientmedications",
  async (patientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/PatientMedication/getallpatientmedications/${patientId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching patient medication");
    }
  }
);

export const savePatientMedication = createAsyncThunk(
  "PatientMedication/savepatientmedication",
  async (patientMedicationModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(
        `/PatientMedication/savepatientmedication`,
        patientMedicationModel // Send the patientId in the request body
      );
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving patient medication");
    }
  }
);

export const manageMedication = createAsyncThunk(
  "MedicationMaster/editmedication",
  async (medicationModel, { rejectWithValue }) => {
    try {
      return await httpClient.put(
        `/MedicationMaster/editmedication`,
        medicationModel // Send the patientId in the request body
      );
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving medication");
    }
  }
);

export const editPatientMedication = createAsyncThunk(
  "PatientMedication/managepatientmedication",
  async (patientMedicationModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(
        `/PatientMedication/managepatientmedication`,
        patientMedicationModel // Send the patientId in the request body
      );
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving patient medication");
    }
  }
);

export const addMedication = createAsyncThunk(
  "MedicationMaster/addmedication",
  async (medicationModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(
        `/MedicationMaster/addmedication`,
        medicationModel // Send the patientId in the request body
      );
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving medication");
    }
  }
);
