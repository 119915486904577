import httpClient from "../http-client";

export const InsuranceCompanyService = {
  getInsuranceCompanies: (isActive) => httpClient("admin/insurance/companies?isActive=" + isActive),
  deleteInsuranceCompany: (id) =>
    httpClient(`admin/insurance/companies/${id}`, { method: "DELETE" }),
  addInsuranceCompany: (data) =>
    httpClient("admin/insurance/companies", { method: "POST", body: data }),
  updateInsuranceCompany: (data) =>
    httpClient(`admin/insurance/companies/${data.id}`, { method: "PUT", body: data })
};

export const PlanService = {
  getPlans: (isActive) => httpClient("admin/insurance/plans?isActive=" + isActive)
};
