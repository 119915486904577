import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonPopup from "src/components/molecules/CommonPopup";
import DynamicForm from "src/components/organisms/DynamicForm";
import { manageDisease, fetchDiseaseMaster, addDisease } from "src/store/actions/diseaseAction";
import { AddEditDisease } from "src/models/diseaseModel";

const ManageHealthIssue = ({ show, onClose, mode = "add", initialData = {}, onSubmit }) => {
  const dispatch = useDispatch();

  // Retrieve global state values for clinicId, and createdBy
  const clinicID = useSelector((state) => state.clinics.selectedClinic?.id || 0);
  // const createdBy = useSelector((state) => state.auth?.user?.email || 0);
  const createdBy = 1;

  const [formData, setFormData] = useState({ ...AddEditDisease });

  useEffect(() => {
    if (mode === "edit" && initialData) {
      // Populate formData for edit mode
      setFormData({
        ...initialData,
        isActive: true
      });
    } else {
      // Set defaults for add mode
      setFormData({
        ...AddEditDisease
      });
    }
  }, [mode, initialData, clinicID, createdBy]);

  const handleInputChange = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFormSubmit = async () => {
    try {
      if (mode === "edit") {
        await dispatch(manageDisease(formData));
      } else {
        await dispatch(addDisease(formData));
      }
      dispatch(fetchDiseaseMaster()); // Refresh grid after save
      onSubmit && onSubmit();
      onClose();
    } catch (error) {
      console.error("Error saving disease:", error);
    }
  };

  const tabs = [
    {
      name: mode === "add" ? "Add Disease" : "Edit Disease",
      sections: [
        {
          fields: [
            {
              name: "diseaseName",
              type: "input",
              label: "Disease",
              value: formData.diseaseName || ""
            },
            {
              name: "showOnForms",
              type: "checkbox",
              label: "Show On Forms",
              value: formData.showOnForms || ""
            }
          ]
        }
      ],
      footer: {
        right: [
          {
            type: "button",
            icon: "save",
            className: "save-button",
            text: "Save",
            onClick: handleFormSubmit
          }
        ]
      }
    }
  ];

  return (
    <CommonPopup show={show} onClose={onClose} title={tabs[0].name}>
      <DynamicForm
        tabs={tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData}
        handleInputChange={handleInputChange}
        mode={mode}
      />
    </CommonPopup>
  );
};

export default ManageHealthIssue;
