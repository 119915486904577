import httpClient from "./http-client";
const API_BASE_URL = "/PatientFamilyHealth"; // Base endpoint for Patient APIs

export const getFamilyHistory = async (patientId) => {

  let result = await httpClient(`${API_BASE_URL}/getallpatientfamilyhistory/${patientId}`, {
    method: "GET"
  });
  return result;
};

export const manageFamilyHistory = async (payload) => {
  return await httpClient(`${API_BASE_URL}/managepatientfamilyhistory`,  {method: "POST",
      body: payload
    })
};
