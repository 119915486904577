import React, { useEffect, useState } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import Dropdown from "src/components/atoms/Dropdown";
import ActionButton from "src/components/atoms/ActionButton";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import Button from "src/components/atoms/Button";
import ScheduleHours from "./ScheduleHours";
import ViewAddEditPopup from "./ViewAddEditPopup";
import {
  deleteAppointmentView,
  getAppointmentView,
  getAppointmentViewDetail,
  getHoursAndViews,
  updateHoursAndViews
} from "src/services/settings/schedule/hours-and-views";
import VisitPopup from "./VistSummariesPopup";
import { showNotification } from "src/store/slices/appSlice";
import { useDispatch } from "react-redux";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const beforeafteroption = [
  { text: "0 min", value: "0" },
  { text: "5 min", value: "5" },
  { text: "10 min", value: "10" },
  { text: "15 min", value: "15" },
  { text: "20 min", value: "20" }
];

const radioOptions = [
  { label: "5 min", value: "5" },
  { label: "10 min", value: "10" },
  { label: "15 min", value: "15" }
];

const ViewHourColumns = [{ field: "description", title: "View" }];

const HoursViews = () => {
  const dispatch = useDispatch();
  const [showViewAddPopup, setShowViewAddPopup] = useState(false);
  const [showViewEditPopup, setShowViewEditPopup] = useState(false);
  const [showVistSummaries, setShowVistSummaries] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [viewData, setViewData] = useState([]);
  const [selectedViewData, setSelectedViewData] = useState([]);
  const [activestatus, setActivestatus] = useState(true);
  const [radioOption, setRadioOption] = useState("");
  const [total, setTotal] = useState(10);

  const [switches, setSwitches] = useState({
    dynamicDashboard: false,
    showAllDays: false,
    isLocationAccessRestriction: false,
    isSearchPreferredPatientsOnly: false,
    officeHoursAccessOnly: false
  });

  const [beforeAfterTimes, setBeforeAfterTimes] = useState({
    beforeOpen: "0",
    afterOpen: "0"
  });

  const [hours, setHours] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = { isOpen: false, startTime: "9:00 AM", endTime: "5:00 PM" };
      return acc;
    }, {})
  );

  const borderStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    position: "relative",
    margin: "20px"
  };

  const titleStyle = {
    position: "absolute",
    top: "-12px",
    left: "20px",
    fontWeight: "bold",
    backgroundColor: "#1a262f",
    padding: "0 5px",
    color: "#ddd"
  };

  const formatTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(":");
    const hour = parseInt(hours, 10);
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minutes} ${period}`;
  };

  const transformApiDataToHours = (apiData) => {
    return {
      Monday: {
        isOpen: apiData.weekdayM,
        startTime: formatTime(apiData.startTimeM) || "9:00 AM",
        endTime: formatTime(apiData.endTimeM) || "5:00 PM"
      },
      Tuesday: {
        isOpen: apiData.weekdayT,
        startTime: formatTime(apiData.startTimeT) || "9:00 AM",
        endTime: formatTime(apiData.endTimeT) || "5:00 PM"
      },
      Wednesday: {
        isOpen: apiData.weekdayW,
        startTime: formatTime(apiData.startTimeW) || "9:00 AM",
        endTime: formatTime(apiData.endTimeW) || "5:00 PM"
      },
      Thursday: {
        isOpen: apiData.weekdayTh,
        startTime: formatTime(apiData.startTimeTh) || "9:00 AM",
        endTime: formatTime(apiData.endTimeTh) || "5:00 PM"
      },
      Friday: {
        isOpen: apiData.weekdayF,
        startTime: formatTime(apiData.startTimeF) || "9:00 AM",
        endTime: formatTime(apiData.endTimeF) || "5:00 PM"
      },
      Saturday: {
        isOpen: apiData.weekdayS,
        startTime: formatTime(apiData.startTimeS) || "9:00 AM",
        endTime: formatTime(apiData.endTimeS) || "5:00 PM"
      },
      Sunday: {
        isOpen: apiData.weekdaySu,
        startTime: formatTime(apiData.startTimeSu) || "9:00 AM",
        endTime: formatTime(apiData.endTimeSu) || "5:00 PM"
      }
    };
  };

  const fetchHoursAndViews = () => {
    getHoursAndViews().then((response) => {
      if (response) {
        const transformedHours = transformApiDataToHours(response);
        setHours(transformedHours);

        setBeforeAfterTimes({
          beforeOpen: `${response.beforeOpen}`,
          afterOpen: `${response.afterClose}`
        });
        setSwitches({
          dynamicDashboard: response.dynamicDashboard,
          showAllDays: response.showAllDays,
          isLocationAccessRestriction: response.isLocationAccessRestriction,
          isSearchPreferredPatientsOnly: response.isSearchPreferredPatientsOnly,
          officeHoursAccessOnly: response.officeHoursAccessOnly
        });

        setRadioOption(`${response.visitInterval}`);

        setResponseData(response);
      }

      console.log("Transformed Hours and Views Data:", response);
    });
  };

  useEffect(() => {
    fetchHoursAndViews();
  }, []);

  const fetchAppointmentView = (activestatus) => {
    getAppointmentView(activestatus).then((response) => {
      if (response) {
        console.log("Appointment View Data:", response);
        setViewData(response);
        setTotal(response.length);
      }
    });
  };

  useEffect(() => {
    fetchAppointmentView(activestatus);
  }, [activestatus]);

  const formatTimeTo24Hour = (timeStr) => {
    const [time, period] = timeStr.split(" ");
    let [hours, minutes, seconds = "00"] = time.split(":").map(Number);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }
    return (
      `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:` +
      `${seconds.toString().padStart(2, "0")}`
    );
  };

  const transformData = (responseData, hoursData, beforeAfterTimes, switches, radioOption) => {
    return {
      ...responseData,
      weekdayM: hoursData.Monday.isOpen,
      startTimeM: formatTimeTo24Hour(hoursData.Monday.startTime),
      endTimeM: formatTimeTo24Hour(hoursData.Monday.endTime),
      weekdayT: hoursData.Tuesday.isOpen,
      startTimeT: formatTimeTo24Hour(hoursData.Tuesday.startTime),
      endTimeT: formatTimeTo24Hour(hoursData.Tuesday.endTime),
      weekdayW: hoursData.Wednesday.isOpen,
      startTimeW: formatTimeTo24Hour(hoursData.Wednesday.startTime),
      endTimeW: formatTimeTo24Hour(hoursData.Wednesday.endTime),
      weekdayTh: hoursData.Thursday.isOpen,
      startTimeTh: formatTimeTo24Hour(hoursData.Thursday.startTime),
      endTimeTh: formatTimeTo24Hour(hoursData.Thursday.endTime),
      weekdayF: hoursData.Friday.isOpen,
      startTimeF: formatTimeTo24Hour(hoursData.Friday.startTime),
      endTimeF: formatTimeTo24Hour(hoursData.Friday.endTime),
      weekdayS: hoursData.Saturday.isOpen,
      startTimeS: formatTimeTo24Hour(hoursData.Saturday.startTime),
      endTimeS: formatTimeTo24Hour(hoursData.Saturday.endTime),
      weekdaySu: hoursData.Sunday.isOpen,
      startTimeSu: formatTimeTo24Hour(hoursData.Sunday.startTime),
      endTimeSu: formatTimeTo24Hour(hoursData.Sunday.endTime),
      beforeOpen: beforeAfterTimes.beforeOpen,
      afterClose: beforeAfterTimes.afterOpen,
      dynamicDashboard: switches.dynamicDashboard,
      showAllDays: switches.showAllDays,
      isLocationAccessRestriction: switches.isLocationAccessRestriction,
      isSearchPreferredPatientsOnly: switches.isSearchPreferredPatientsOnly,
      officeHoursAccessOnly: switches.officeHoursAccessOnly,
      visitInterval: radioOption
    };
  };

  const handleToggle = (day) => {
    setHours((prev) => ({
      ...prev,
      [day]: { ...prev[day], isOpen: !prev[day].isOpen }
    }));
  };

  const handleTimeChange = (day, field, value) => {
    setHours((prev) => ({
      ...prev,
      [day]: { ...prev[day], [field]: value.value }
    }));
  };

  const handleRadioChange = (event) => {
    setRadioOption(event.target.value);
  };

  const handleSwitchChange = (field) => {
    setSwitches((prev) => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleBeforeAfterChange = (field, value) => {
    setBeforeAfterTimes((prev) => ({
      ...prev,
      [field]: value.value
    }));
  };

  const onStatusChange = (status) => {
    const isActive = status === "Active";
    setActivestatus(isActive);
    console.log(isActive);
  };

  const handlevisitsummaries = () => {
    setShowVistSummaries(true);
  };

  const handleSubmit = () => {
    const formData = transformData(responseData, hours, beforeAfterTimes, switches, radioOption);
    updateHoursAndViews(formData)
      .then(() => {
        fetchHoursAndViews();
        dispatch(
          showNotification({ message: "Hours and Views Updated Successfully", type: "success" })
        );
      })
      .catch((error) => {
        dispatch(showNotification({ message: "An error occurred", type: "error" }));
        console.error("Error updating hours and views:", error);
      });
  };

  return (
    <DatatableLayout title="Hours and Views">
      <ScheduleHours
        hours={hours}
        handleToggle={handleToggle}
        handleTimeChange={handleTimeChange}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          marginBottom: "10px"
        }}
      >
        <div style={{ flex: "1" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginBottom: "10px"
            }}
          >
            <div style={{ flex: "1" }}>
              <div style={borderStyle}>
                <div style={titleStyle}>Visit Intervals</div>
                {radioOptions.map((option) => (
                  <label key={option.value} style={{ marginRight: "12px" }}>
                    <input
                      type="radio"
                      name="interval"
                      style={{ accentColor: "#ff6358" }}
                      value={option.value}
                      checked={radioOption === option.value}
                      onChange={handleRadioChange}
                    />
                    {option.label}
                  </label>
                ))}
              </div>

              <div style={borderStyle}>
                <div style={titleStyle}>Schedule Dashboard</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "10px" }}>Show/Hide Dynamically:</label>
                  <Switch
                    checked={switches.dynamicDashboard}
                    onChange={() => handleSwitchChange("dynamicDashboard")}
                  />
                </div>
              </div>
              <div style={borderStyle}>
                <div style={titleStyle}>Team Coverage</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "10px" }}>Show All Days:</label>
                  <Switch
                    checked={switches.showAllDays}
                    onChange={() => handleSwitchChange("showAllDays")}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "20px",
                margin: "20px",
                position: "relative",
                flex: 1
              }}
            >
              <div style={titleStyle}>Office Hours Access Only</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ marginRight: "10px" }}>Enable Restriction:</label>
                <Switch
                  checked={switches.isLocationAccessRestriction}
                  onChange={() => handleSwitchChange("isLocationAccessRestriction")}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px"
                }}
              >
                <label style={{ marginRight: "10px" }}>Befor Open:</label>
                <ComboBox
                  data={beforeafteroption}
                  textField="text"
                  dataItemKey="value"
                  value={beforeafteroption.find((opt) => opt.value === beforeAfterTimes.beforeOpen)}
                  onChange={(e) => handleBeforeAfterChange("beforeOpen", e.target.value)}
                  style={{ width: "60%" }}
                  clearButton={false}
                  placeholder="Select Before Open Time"
                  filterable={true}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ marginRight: "15px" }}>After Open:</label>
                <ComboBox
                  data={beforeafteroption}
                  textField="text"
                  dataItemKey="value"
                  value={beforeafteroption.find((opt) => opt.value === beforeAfterTimes.afterOpen)}
                  onChange={(e) => handleBeforeAfterChange("afterOpen", e.target.value)}
                  style={{ width: "60%" }}
                  clearButton={false}
                  placeholder="Select After Open Time"
                  filterable={true}
                />
              </div>
              <p>
                <h4>Note :</h4>
                If restriction is on, users will only access ThriveCloud during Office Hours. You
                may add an access buffer above. To bypass this restriction for specific users, go to
                Security.
              </p>
            </div>
          </div>
        </div>
        <div style={{ flex: "1" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              marginBottom: "10px"
            }}
          >
            <div></div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px"
              }}
            >
              <Dropdown
                options={["Active", "Inactive"]}
                status={activestatus === true ? "Active" : "Inactive"}
                value={activestatus === true ? "Active" : "Inactive"}
                onChange={onStatusChange}
              />
              <ActionButton
                icon="plus"
                className="add-button"
                onClick={() => {
                  setShowViewAddPopup(true);
                }}
              >
                View
              </ActionButton>
            </div>
          </div>
          <div style={{ maxHeight: "350px", overflowY: "auto" }}>
            <DynamicGrid
              columns={ViewHourColumns}
              rows={viewData}
              allowSort={false}
              allowFilter={false}
              pageable={true}
              total={total}
              actions={[
                {
                  icon: "edit",
                  title: "Edit",
                  onClick: async (dataItem) => {
                    setShowViewEditPopup(false);

                    try {
                      const response = await getAppointmentViewDetail(dataItem.appointmentViewID);
                      setSelectedViewData(response);
                      setShowViewEditPopup(true);
                    } catch (error) {
                      console.error("Error fetching appointment view detail:", error);
                    }
                  }
                },
                {
                  icon: "delete",
                  title: "Delete",
                  onClick: (dataItem) => {
                    const userResponse = confirm(
                      "Are you sure you want to delete this appointment view?"
                    );
                    if (userResponse) {
                      deleteAppointmentView(dataItem.appointmentViewID)
                        .then(() => {
                          fetchAppointmentView(activestatus);
                        })
                        .catch((error) => {
                          alert("Error deleting appointment view: " + error.message);
                        });
                    }
                  }
                }
              ]}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              margin: "20px"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Search Preferred Patients Only:</label>
              <Switch
                checked={switches.isSearchPreferredPatientsOnly}
                onChange={() => handleSwitchChange("isSearchPreferredPatientsOnly")}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Complete Treatment with Visits:</label>
              <Switch
                checked={switches.officeHoursAccessOnly}
                onChange={() => handleSwitchChange("officeHoursAccessOnly")}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ borderTop: "1px solid #ddd", margin: "20px 0" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "8px",
          padding: "20px",
          marginTop: "10px"
        }}
      >
        <Button onClick={handlevisitsummaries} themeColor="primary">
          Visit Summaries
        </Button>
        <Button onClick={handleSubmit} type="submit" themeColor="primary">
          Save
        </Button>
      </div>
      {showViewAddPopup && (
        <ViewAddEditPopup
          title="Add Schedule View"
          show={showViewAddPopup}
          actionType="addview"
          onClose={() => {
            setShowViewAddPopup(false);
          }}
          activestatus={activestatus}
          fetchAppointmentView={fetchAppointmentView}
        />
      )}
      {showViewEditPopup && (
        <ViewAddEditPopup
          title="Edit Schedule View"
          show={showViewEditPopup}
          actionType="editview"
          data={selectedViewData}
          onClose={() => {
            setShowViewEditPopup(false);
          }}
          activestatus={activestatus}
          fetchAppointmentView={fetchAppointmentView}
        />
      )}
      {showVistSummaries && (
        <VisitPopup
          title="Visit Summaries"
          show={showVistSummaries}
          onClose={() => {
            setShowVistSummaries(false);
          }}
        />
      )}
    </DatatableLayout>
  );
};

export default HoursViews;
