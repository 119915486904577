import React, { useRef, useEffect } from "react";
import CommonPopup from "../../../molecules/CommonPopup"; // Import the reusable CommonPopup component
import DynamicForm from "../../../organisms/DynamicForm"; // Import the dynamic form component
import PatientVisitsGrid from "./PatientVisitsGrid"; // Import the custom patient visit grid component
import { useSelector, useDispatch } from "react-redux";
import { selectProfileDetails } from "src/store/slices/patientSlice";
import { fetchFamilyVisitDetails } from "src/store/actions/visitAction";
import { selectFamilyVisitDetails } from "src/store/slices/visitSlice";
const AddFeeSchedulePopup = ({ show, onClose }) => {
  const profile = useSelector((state) => selectProfileDetails(state));
  const gridRef = useRef();
  const familyVisitDetails = useSelector((state) => selectFamilyVisitDetails(state));
  const dispatch = useDispatch();
  // Handle patient change (e.g., through dropdown selection)

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchFamilyVisitDetails(profile.patientId));
  }, [dispatch, profile]);

  const handlePrint = () => {
    gridRef.current.printGrid();
  };
  const handleFormSubmit = async () => {};
  // Define the dynamic form structure for the selected patient
  const formStructure = {
    title: "Add Fee Schedule",

    tabs: [
      {
        name: "Add Fee Schedule",
        sections: [
          {
            sectionName: "New Treatment",
            fields: [
              { label: "Name", name: "name", type: "input" },
              { label: "Type", name: "type", type: "select" },
              { label: "Status", name: "status", type: "select" },
              { label: "Show in Offices", name: "showInOffices", type: "select" }
            ]
          }
        ],
        footer: {
          right: [
            {
              type: "button",
              icon: "save",
              className: "save-button",
              text: "Save",
              onClick: handleFormSubmit
            }
          ]
        }
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title={"Patient Visit"}>
      {/* Dynamic Form for Patient Information */}
      <DynamicForm formData={{}} tabs={formStructure.tabs} handleFormSubmit={handleFormSubmit} />
    </CommonPopup>
  );
};

export default AddFeeSchedulePopup;
