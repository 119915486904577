import React, { useEffect, useState } from "react";
import CommonPopup from "src/components/molecules/CommonPopup";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import Button from "src/components/atoms/Button";
import {
  AddAppointmentView,
  getAllOpratory,
  updateAppointmentView
} from "src/services/settings/schedule/hours-and-views";
import { showNotification } from "src/store/slices/appSlice";
import { useDispatch } from "react-redux";

const ViewAddEditPopup = ({
  title,
  show,
  actionType,
  data,
  onClose,
  activestatus,
  fetchAppointmentView
}) => {
  const initialFormState = {
    showFullNote: false,
    showAllTx: false,
    showScheduledProduction: false,
    showTreatment: false,
    showHealthAlert: false,
    showVisitNote: false,
    showInsuranceCompany: false,
    showMobileNumber: false,
    description: "",
    operatoryDetails: [],
    inactive: false,
    showInitialsOnly: false
  };

  const dispatch = useDispatch();
  const [formState, setFormState] = useState(() => {
    const filteredOperatoryDetails =
      data?.operatoryDetails?.filter((item) => item.operatoryID !== null && item.abbrev !== null) ||
      [];

    return actionType === "editview" && data
      ? { ...data, operatoryDetails: filteredOperatoryDetails, inactive: !data?.isActive }
      : initialFormState;
  });

  const [operatoryList, setOperatoryList] = useState([]);

  useEffect(() => {
    getAllOpratory().then((response) => {
      if (response) {
        const allOperatories = response.map((item) => ({
          operatoryID: item.id,
          abbrev: item.abbrev
        }));

        setOperatoryList(allOperatories);
      }
    });
  }, [data]);

  const handleCheckboxChange = (field) => (event) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: event.target.checked
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleMultiSelectChange = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      operatoryDetails: event.target.value
    }));
  };

  const handleSwitchChange = (field) => (event) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: event.value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (actionType === "addview") {
      try {
        AddAppointmentView(formState).then(() => {
          dispatch(
            showNotification({ message: "Schedule view added successfully", type: "success" })
          );
          fetchAppointmentView(activestatus);
        });
      } catch (error) {
        dispatch(showNotification({ message: "An error occurred", type: "error" }));
      }
    }
    if (actionType === "editview") {
      try {
        updateAppointmentView(formState).then(() => {
          dispatch(
            showNotification({ message: "Schedule view updated successfully", type: "success" })
          );

          fetchAppointmentView(activestatus);
        });
      } catch (error) {
        dispatch(showNotification({ message: "An error occurred", type: "error" }));
      }
    }

    onClose();
  };

  const titleStyle = {
    position: "absolute",
    top: "-12px",
    left: "20px",
    fontWeight: "bold",
    backgroundColor: "#273c45",
    padding: "0 5px",
    color: "#ddd"
  };

  const formFields = [
    { label: "Full Note", field: "showFullNote" },
    { label: "All Tx", field: "showAllTx" },
    { label: "Scheduled Production", field: "showScheduledProduction" },
    { label: "Treatment", field: "showTreatment" },
    { label: "Health Alert", field: "showHealthAlert" },
    { label: "Visit Note", field: "showVisitNote" },
    { label: "Insurance/Membership", field: "showInsuranceCompany" },
    { label: "Mobile Number", field: "showMobileNumber" }
  ];

  return (
    <CommonPopup show={show} onClose={onClose} title={title} dialogProps={{ width: "1000px" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ marginLeft: "300px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", margin: "20px" }}>
            <label style={{ minWidth: "50px", color: "white" }}>View:</label>
            <Input name="description" value={formState.description} onChange={handleInputChange} />
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "10px", margin: "20px" }}>
            <label style={{ color: "white", marginBottom: "10px" }}>Chairs:</label>
            <MultiSelect
              data={operatoryList}
              value={formState.operatoryDetails || []}
              onChange={handleMultiSelectChange}
              placeholder="All Operatories..."
              textField="abbrev"
              dataItemKey="operatoryID"
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", width: "500px" }}>
            <div style={{ margin: "20px", accentColor: "#ff6358" }}>
              <label style={{ color: "white", marginRight: "10px" }}>Inactive:</label>
              <input
                type="checkbox"
                checked={formState.inactive}
                onChange={handleCheckboxChange("inactive")}
              />
            </div>
            <div style={{ margin: "20px", accentColor: "#ff6358" }}>
              <label style={{ color: "white", marginRight: "10px" }}>
                Only Show Patient Initials:
              </label>
              <input
                type="checkbox"
                checked={formState.showInitialsOnly}
                onChange={handleCheckboxChange("showInitialsOnly")}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1.5fr",
            gap: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "20px",
            position: "relative",
            margin: "20px"
          }}
        >
          <label style={titleStyle}> Show on Visit Block</label>
          <div>
            {formFields.slice(0, 4).map(({ label, field }) => (
              <div style={{ margin: "20px", display: "flex", justifyContent: "end" }} key={field}>
                <label style={{ color: "white", marginRight: "20px" }}>{label}:</label>
                <Switch checked={formState[field]} onChange={handleSwitchChange(field)} />
              </div>
            ))}
          </div>
          <div>
            {formFields.slice(4).map(({ label, field }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: "100px",
                  marginTop: "20px"
                }}
                key={field}
              >
                <label
                  style={{
                    color: "white",
                    marginRight: "20px"
                  }}
                >
                  {label}:
                </label>
                <Switch checked={formState[field]} onChange={handleSwitchChange(field)} />
              </div>
            ))}
          </div>
        </div>

        <div style={{ textAlign: "right", margin: "20px" }}>
          <Button type="submit" themeColor="primary">
            Submit
          </Button>
        </div>
      </form>
    </CommonPopup>
  );
};

export default ViewAddEditPopup;
