import { createSlice } from "@reduxjs/toolkit";
import { fetchAppointmentTypeList } from "../actions/appointmentTypeAction";

const appointmentTypeSlice = createSlice({
  name: "appointmentType",
  initialState: {
    appointmentTypeList: null,
    loading: {
      appointmentTypeList: false
    },
    error: {
      appointmentTypeList: null
    }
  },

  reducers: {
    setAppointmentTypeList(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAppointmentTypeList.pending, (state) => {
        state.loading.appointmentTypeList = true;
        state.error.appointmentTypeList = null;
      })
      .addCase(fetchAppointmentTypeList.fulfilled, (state, action) => {
        state.loading.appointmentTypeList = false;
        state.appointmentTypeList = action.payload;
      })
      .addCase(fetchAppointmentTypeList.rejected, (state, action) => {
        state.loading.appointmentTypeList = false;
        state.error.appointmentTypeList = action.payload;
      });
  }
});

export const selectAppointmentTypeList = (state) => state.appointmentType.appointmentTypeList;
export const selectAppointmentTypeListLoading = (state) => state.appointmentType.loading;
export const selectAppointmentTypeListError = (state) => state.appointmentType.error;
export const { setAppointmentTypeList } = appointmentTypeSlice.actions;
export default appointmentTypeSlice.reducer;
