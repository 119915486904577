import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../../services/clinicService";
import httpClient from "src/services/http-client";

export const fetchClinics = createAsyncThunk(
  "clinics/fetchClinics",
  async (clientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/Clinic/getclincbyclient/${clientId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching clinic");
    }
  }
);

export const fetchDoctors = createAsyncThunk(
  "clinics/fetchDoctors",
  async (_, { getState, rejectWithValue }) => {
    const { accessToken } = getState().auth;
    console.log("getstate", getState());
    try {
      // const resp = await instance.acquireTokenSilent({
      //         ...loginRequest,
      //         account: accounts[0], // Account object from MSAL
      //       });
      //     console.log("att",resp)
      const response = await apiService.getDoctors(accessToken);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
