import React, { useEffect, useState } from "react";
import Button from "src/components/atoms/Button";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import NavbarTemplate from "src/components/templates/NavbarTemplate";
import { UserLoginStatusService } from "src/services/AdminService";

const AdminUserLoginStatus = () => {
  const [userStatus, setUserStatus] = useState([]);

  useEffect(() => {
    const data = UserLoginStatusService.getUserLoginStatus();
    setUserStatus(data);
  }, []);

  const columns = [
    { field: "user", title: "User" },
    { field: "loggedin", title: "Logged In" },
    { field: "logInTime", title: "Log In Time" },
    { field: "logOutTime", title: "Log Out Time" },
    { field: "timePunch", title: "Time Punch" },
    { field: "ipAdderss", title: "IP Address" }
  ];

  return (
    <NavbarTemplate>
      <h3>User Login Status</h3>
      <DynamicGrid columns={columns} rows={userStatus} allowSort={false} allowFilter={false} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ color: "red" }}>
          Note: If a user closes their browser without logging out, they will still show as logged
          in here.
        </p>
        <a
          href="https://whatismyipaddress.com/ip-lookup"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "deepskyblue",
            cursor: "pointer",
            textDecoration: "none",
            marginTop: "15px"
          }}
        >
          Lookup IP Address Location
        </a>
      </div>
    </NavbarTemplate>
  );
};

export default AdminUserLoginStatus;
