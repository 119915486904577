import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { useSelector, useDispatch } from "react-redux";
import { fetchDiseaseMaster, savePatientDisease } from "src/store/actions/diseaseAction";
import { selectDiseaseMaster } from "src/store/slices/diseaseSlice";
import { PatientDisease } from "src/models/diseaseModel";
import CommonPopup from "../../../molecules/CommonPopup";
import Button from "src/components/atoms/Button";

const MasterDisease = ({ gridRef, patientId, show, onClose }) => {
  const { clinicId } = useParams();
  const dispatch = useDispatch();
  const masterDisease = useSelector((state) => selectDiseaseMaster(state));
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    dispatch(fetchDiseaseMaster());
  }, [dispatch, fetchDiseaseMaster]);

  const columns = [
    { field: "diseaseName", title: "Disease", width: "250px" },
    { field: "showOnForms", title: "Show On Forms", width: "250px" }
  ];

  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => alert(`Editing ${dataItem.name}`)
    },
    {
      title: "Delete",
      icon: "delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.name}`)
    }
  ];

  const handleSelectedRowsChange = (selectedRowIds) => {
    setSelectedRows(selectedRowIds);
  };

  const saveDisease = () => {
    console.log("selec", selectedRows);
    const newPatientDisease = {
      ...PatientDisease,
      patientId: patientId.patientId,
      masterIds: selectedRows,
      clientId: 4,
      createdBy: 1
    };
    dispatch(savePatientDisease(newPatientDisease));
    onClose();
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Diseases">
      <DynamicGrid
        ref={gridRef}
        columns={columns}
        rows={masterDisease}
        actions={actions}
        allowCheckable={true}
        dataItemKey={"diseaseId"}
        onSelectedRowsChange={handleSelectedRowsChange}
        pageSize={10}
        pageable={true}
      />
      <Button themeColor="primary" onClick={saveDisease}>
        Save
      </Button>
    </CommonPopup>
  );
};

export default MasterDisease;
