import React from "react";
import { useSelector } from "react-redux";
import "./AppLoading.css";

const AppLoading = () => {
  const isLoading = useSelector((state) => state.app.isLoading);

  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="loading-spinner">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default AppLoading;
