import React, { useRef, useEffect, useState } from "react";
import DynamicForm from "src/components/organisms/DynamicForm";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import CommonPopup from "src/components/molecules/CommonPopup";
import { useGridPrint, useGridExportToExcel } from "src/hooks/useGridActions";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import NewTreatmentPopup from "./NewTreatmentPopup";
import FeeSchedulePopup from "./FeeSchedulePopup";
import { selectFeeSchedule } from "src/store/slices/feeScheduleSlice";
import { fetchFeeScheduleByClient } from "src/store/actions/feeScheduleAction";
import { useSelector, useDispatch } from "react-redux";
import { selectProcedureCodeBySearch } from "src/store/slices/procedureCodeSlice";
import { fetchProcedureCodeBySearch } from "src/store/actions/procedureCodeAction";
import { fetchCodeDefinitionForProcedure } from "src/store/actions/codeDefinitionAction";
import { selectCodeDefinitionForProcedure } from "src/store/slices/codeDefinitionSlice";

const TreatmentAndFeesPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});
  const gridRef = useRef(null);
  const { popups, openPopup, closePopup } = usePopupVisibility();
  const feeSchedule = useSelector((state) => selectFeeSchedule(state));
  const procedureCodeBySearch = useSelector((state) => selectProcedureCodeBySearch(state));
  const codeDefinitionForProcedure = useSelector((state) =>
    selectCodeDefinitionForProcedure(state)
  );
  const dispatch = useDispatch();

  const handlePrint = useGridPrint(gridRef);
  const handleExportToExcel = useGridExportToExcel(gridRef);
  const [feeScheduleValue, setfeeScheduleValue] = useState(null);

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchFeeScheduleByClient(1));
    dispatch(fetchProcedureCodeBySearch({ feeScheduleId: 1 }));
    dispatch(fetchCodeDefinitionForProcedure());
  }, [dispatch]);
  const feeScheduleOptions = feeSchedule?.map((fee) => ({
    label: `${fee.description}`.trim(), // Full name
    value: fee.id // Provider ID
  }));

  const codeDefinitionForProcedureOptions = codeDefinitionForProcedure?.map((codeDef) => ({
    label: `${codeDef.itemName}`.trim(),
    value: codeDef.id
  }));

  const handleInputChange = (value, name) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name == "officeFees") {
      const procedureSearchRequest = {
        procedureCode: formData?.adaCode,
        procedureDescription: formData?.treatment,
        groups: formData?.groups,
        abbreviatedDescription: formData?.officeCode,
        feeScheduleId: value
      };

      dispatch(fetchProcedureCodeBySearch(procedureSearchRequest));
    }
  };

  const columns = [
    { field: "groupId", title: "Group", width: "150px" },
    { field: "procedureCode", title: "ADA Code", width: "150px" },
    { field: "procedureDescription", title: "Treatment", width: "300px" },
    { field: "abbreviatedDescription", title: "Office Code", width: "150px" },
    { field: "fee", title: "Fee Schedule", width: "150px" }
  ];

  const formStructure = {
    title: "Treament and Fees",
    tabs: [
      {
        name: "Treament and Fees",
        sections: [
          {
            sectionName: "Search Criteria",
            fields: [
              {
                label: "ADA Code",
                name: "adaCode",
                type: "input"
              },
              {
                label: "Treatment",
                name: "treatment",
                type: "input"
              },
              {
                label: "Office Code",
                name: "officeCode",
                type: "input"
              },
              {
                label: "Groups",
                name: "groups",
                type: "multiselect",
                options: codeDefinitionForProcedureOptions
              },
              {
                name: "search",
                type: "searchbutton",
                icon: "search",
                onClick: (e) => {
                  const procedureSearchRequest = {
                    procedureCode: formData?.adaCode,
                    procedureDescription: formData?.treatment,
                    groups: formData?.groups,
                    abbreviatedDescription: formData?.officeCode,
                    feeScheduleId: 1
                  };

                  dispatch(fetchProcedureCodeBySearch(procedureSearchRequest));
                }
              }
            ]
          },
          {
            sectionHeader: {
              left: [
                {
                  type: "button",
                  text: "New Treatment",
                  onClick: (dataItem) => {
                    openPopup("newTreament");
                  }
                },
                {
                  type: "button",
                  text: "Fee Schedules",
                  onClick: (dataItem) => {
                    openPopup("feeSchedule");
                  }
                },
                {
                  type: "button",
                  text: "Mass Edit Fees",
                  onClick: () => console.log("Mass Text clicked")
                }
              ],
              right: [
                {
                  type: "button",
                  text: "Export",
                  icon: "file-excel",
                  onClick: handleExportToExcel
                },
                {
                  type: "button",
                  text: "Save Fees",
                  onClick: () => console.log("Mass Text clicked")
                },
                {
                  label: "Office Fees",
                  type: "select",
                  text: "Print",
                  name: "officeFees",
                  options: feeScheduleOptions
                }
              ]
            },
            customSection: (
              <DynamicGrid
                ref={gridRef}
                columns={columns}
                rows={procedureCodeBySearch.map((procedure) => {
                  return {
                    ...procedure, // Spread the original object
                    groupId:
                      codeDefinitionForProcedureOptions?.find((x) => x.value == procedure?.groupId)
                        ?.label || procedure?.groupId
                  };
                })}
                allowExpand={true}
                pageable={true}
                pageSize={10}
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <>
      <CommonPopup show={show} onClose={onClose} title="Treatment and Fees">
        <DynamicForm
          tabs={formStructure.tabs}
          handleFormSubmit={(data) => setFormData(data)}
          formData={formData}
          handleInputChange={handleInputChange}
        />
      </CommonPopup>
      {popups.newTreament && (
        <NewTreatmentPopup show={popups.newTreament} onClose={() => closePopup("newTreament")} />
      )}
      {popups.feeSchedule && (
        <FeeSchedulePopup show={popups.feeSchedule} onClose={() => closePopup("feeSchedule")} />
      )}
    </>
  );
};

export default TreatmentAndFeesPopup;
