import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllergyMaster, savePatientAllergy } from "src/store/actions/allergyAction";
import { selectAllergyMaster } from "src/store/slices/allergySlice";
import { PatientAllergy } from "src/models/allergyModel";
import CommonPopup from "../../../molecules/CommonPopup";
import Button from "src/components/atoms/Button";

const MasterAllergy = ({ gridRef, patientId, show, onClose }) => {
  const { clinicId } = useParams();
  const dispatch = useDispatch();
  const masterAllergy = useSelector((state) => selectAllergyMaster(state));
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    dispatch(fetchAllergyMaster());
  }, [dispatch, fetchAllergyMaster]);

  const columns = [
    { field: "allergyName", title: "Allergy", width: "250px" },
    { field: "showOnForms", title: "Show On Forms", width: "250px" }
  ];

  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => alert(`Editing ${dataItem.name}`)
    },
    {
      title: "Delete",
      icon: "delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.name}`)
    }
  ];

  const handleSelectedRowsChange = (selectedRowIds) => {
    setSelectedRows(selectedRowIds);
  };

  const saveAllergy = () => {
    console.log("selec", selectedRows);
    const newPatientAllergy = {
      ...PatientAllergy,
      patientId: patientId.patientId,
      masterIds: selectedRows,
      clientId: 4,
      createdBy: 1
    };
    dispatch(savePatientAllergy(newPatientAllergy));
    onClose();
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Allergies">
      <DynamicGrid
        ref={gridRef}
        columns={columns}
        rows={masterAllergy}
        actions={actions}
        allowCheckable={true}
        dataItemKey={"allergyID"}
        onSelectedRowsChange={handleSelectedRowsChange}
        pageSize={10}
        pageable={true}
      />
      <Button themeColor="primary" onClick={saveAllergy}>
        Save
      </Button>
    </CommonPopup>
  );
};

export default MasterAllergy;
