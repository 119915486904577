import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchDiseaseMaster = createAsyncThunk(
  "DiseaseMaster/getDiseases",
  async (patientSearchModel, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/DiseaseMaster/getDiseases`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching Disease master");
    }
  }
);

export const getPatientDiseases = createAsyncThunk(
  "PatientDisease/getallpatientDiseases",
  async (patientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/PatientDisease/getallpatientDisease/${patientId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching patient Disease");
    }
  }
);

export const savePatientDisease = createAsyncThunk(
  "PatientDisease/savepatientdisease",
  async (patientDiseaseModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(
        `/PatientDisease/savepatientdisease`,
        patientDiseaseModel // Send the patientId in the request body
      );
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving patient disease");
    }
  }
);

export const manageDisease = createAsyncThunk(
  "DiseaseMaster/editdisease",
  async (diseaseModel, { rejectWithValue }) => {
    try {
      return await httpClient.put(
        `/DiseaseMaster/editdisease`,
        diseaseModel // Send the patientId in the request body
      );
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving disease");
    }
  }
);

export const editPatientDisease = createAsyncThunk(
  "PatientDisease/managepatientdisease",
  async (patientDiseaseModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(
        `/PatientDisease/managepatientdisease`,
        patientDiseaseModel // Send the patientId in the request body
      );
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving patient disease");
    }
  }
);

export const addDisease = createAsyncThunk(
  "DiseaseMaster/adddisease",
  async (diseaseModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(
        `/DiseaseMaster/adddisease`,
        diseaseModel // Send the patientId in the request body
      );
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving disease");
    }
  }
);
