import React, { useEffect, useState } from "react";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { BulkInsuranceBillingService } from "src/services/AdminService";

const AdminBulkInsuranceBilling = () => {
  const [insuranceBilling, setInsuranceBilling] = useState([]);
  const [clinician, setClinician] = useState("All");

  useEffect(() => {
    const data = BulkInsuranceBillingService.getbulkInsuranceBilling();
    setInsuranceBilling(data);
  }, []);

  const columns = [
    { field: "date", title: "Date" },
    { field: "patient", title: "Patient" },
    { field: "type", title: "Type" },
    { field: "phase", title: "Phase" },
    { field: "insurance", title: "Insurance" },
    { field: "issues", title: "Issues to Correct" }
  ];

  const actions = [
    {
      icon: "eye", // Edit Icon
      title: "View",
      onClick: (dataItem) => {
        console.log(dataItem);
      }
    },
    {
      icon: "user", // Edit Icon
      title: "Profile",
      onClick: (dataItem) => {
        console.log(dataItem);
      }
    },
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => {
        console.log(dataItem);
      }
    }
  ];
  return (
    <>
      <DatatableLayout
        title="Bulk Insurance Billing"
        statusOptions={["All", "Albert Hoffman", "Amber Smith"]}
        status={clinician}
        onStatusChange={setClinician}
        onAdd={() => console.log("Add new Bills")}
        addBtnLabel="Excel"
        saveButton="Save"
        extraBtnLabel={[
          "Quality Check",
          "Unpaid Claims",
          "Unbilled Insurance",
          "DXC Portal",
          "Print Claims"
        ]}
      >
        <DynamicGrid
          columns={columns}
          rows={insuranceBilling}
          allowSort={false}
          allowFilter={false}
          actions={actions}
        />
      </DatatableLayout>
    </>
  );
};

export default AdminBulkInsuranceBilling;
