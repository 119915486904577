import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
// import "./CommonPopup.css"; // Common styles for the dialog

const CommonDialog = ({ show, onClose, title, children, className, dialogProps = {} }) => {
  return (
    <>
      {show && (
        <Dialog
          onClose={onClose}
          className={className || "dialog-container"}
          title={title}
          {...dialogProps}
        >
          {/* Dialog Body */}
          <div className="dialog-body">{children}</div>
        </Dialog>
      )}
    </>
  );
};

export default CommonDialog;
