import React, { useState } from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import "@progress/kendo-theme-default/dist/all.css"; // Kendo UI styles

/**
 * Generic SearchBox component with Autocomplete functionality.
 * @param {Object} props - The props object.
 * @param {Array} props.data - The array of data that will be displayed in the autocomplete dropdown.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {Function} props.onSelect - Callback function to handle when a suggestion is selected.
 * @param {string} props.label - The label text for the input field (optional).
 * @returns {JSX.Element} The rendered SearchBox component.
 */
const SearchBox = ({ data, placeholder, onSelect, label, value }) => {
  // const [value, setValue] = useState("");
  const handleChange = (e) => {
    console.log("heree in select", e);

    // setValue(e.value); // Update value when input changes
    if (onSelect) {
      onSelect(e.value); // Call the onSelect prop if provided
    }
  };

  const handleSelect = (e) => {
    setValue(e.value);
    if (onSelect) {
      onSelect(e.value); // Call the onSelect prop if provided
    }
  };

  return (
    <div className="search-box">
      {label && <label htmlFor="search-box">{label}</label>}
      <AutoComplete
        id="search-box"
        data={data} // Data source for autocomplete
        value={value} // Bind value to the state
        onChange={handleChange} // Update state when value changes
        placeholder={placeholder} // Placeholder text
        suggest={true} // Enable suggestions as the user types
      />
    </div>
  );
};

export default SearchBox;
