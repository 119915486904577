import React, { useState, useEffect } from "react";
import "../../../organisms/DynamicForm.css"; // Custom styles for the popup
import CommonPopup from "../../../molecules/CommonPopup";
import DynamicForm from "../../../organisms/DynamicForm";
import { Button } from "@progress/kendo-react-buttons";
import { savePatient } from "src/store/actions/patientAction";
import { useDispatch } from "react-redux";
import { ProfileDetail } from "src/models/patientModel";
import { fetchStates, fetchZipcode } from "src/services/admin/saclient";

const AddEditPatient = ({ show, onClose, mode = "add", initialData = {} }) => {
  const [formData, setFormData] = useState({ ...initialData });
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [zipOptions, setZipOptions] = useState([]);
  const [selectedZip, setSelectedZip] = useState("");

  const handleFormSubmit = (data) => {
    console.log(`${mode === "add" ? "Adding" : "Updating"} patient profile with data:`, formData);
    dispatch(savePatient(formData));
    onClose(1);
  };

  useEffect(() => {
    if (mode === "edit" && initialData) {
      // Populate formData for edit mode
      setFormData({
        ...initialData
      });
    }
    if (mode === "add" && firstLoad) {
      // Set defaults for add mode
      setFormData({
        ...ProfileDetail,
        birthdate: new Date(ProfileDetail.birthdate)
      });
      setFirstLoad(false);
    }
  }, [mode, initialData]);

  useEffect(() => {
    const getStates = async () => {
      try {
        const statesData = await fetchStates();
        const options =
          statesData?.map((state) => ({
            label: state?.stateName || "",
            value: state?.stateID.toString() || "",
            dropdowntype: "state"
          })) || [];
        setStateOptions(options);

        if (initialData?.state) {
          setSelectedStateId(initialData?.state);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
        setStateOptions([]);
      }
    };

    getStates();
  }, [initialData?.state]);

  useEffect(() => {
    if (selectedStateId) {
      const getCities = async () => {
        try {
          const Data = await fetchZipcode(selectedStateId);
          const options =
            Data?.map((citydata) => ({
              label: citydata?.zipCodeDigits || "",
              value: citydata?.zipCodeId.toString() || "",
              city: citydata?.city || "",
              dropdowntype: "zip"
            })) || [];
          setZipOptions(options);
          if (initialData?.zip) {
            const selectedZipData = options.find((city) => city.value === initialData?.zip);
            setSelectedZip(selectedZipData || "");
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
          setZipOptions([]);
        }
      };

      getCities();
    }
  }, [selectedStateId, initialData?.zip]);

  const formStructure = {
    title: "Profile",
    tabs: [
      {
        name: "Demographics",
        sections: [
          {
            sectionName: "Demographics",
            fields: [
              {
                label: "Preferred Office",
                name: "preferredOffice",
                type: "select",
                options: [{ label: "Smiles Clinic", value: "Smiles Clinic" }],
                required: true
              },
              {
                label: "Title",
                name: "title",
                type: "select",
                options: [
                  { label: "Mr.", value: "Mr." },
                  { label: "Ms.", value: "Ms." }
                ]
              },
              { label: "First Name", name: "firstName", type: "input", required: true },
              { label: "Middle Name", name: "middleName", type: "input" },
              { label: "Last Name", name: "lastName", type: "input", required: true },
              { label: "Nickname", name: "nickname", type: "input" },
              {
                label: "Type",
                name: "type",
                type: "select",
                options: [{ label: "Regular", value: "Regular" }]
              },
              {
                label: "Gender",
                name: "gender",
                type: "select",
                options: [
                  { label: "Male", value: 0 },
                  { label: "Female", value: 1 }
                ]
              },
              {
                label: "Life Status",
                name: "lifeStatus",
                type: "select",
                options: [
                  { label: "Alive", value: "Alive" },
                  { label: "Deceased", value: "Deceased" }
                ]
              },
              { label: "Birthdate", name: "birthdate", type: "date", required: true },
              { label: "SSN", name: "ssn", type: "password" }
            ]
          },
          {
            sectionName: "Family",
            fields: [
              { label: "Guarantor", name: "guarantor", type: "input" },
              {
                type: "button",
                text: "Change Family",
                name: "changeFamily",
                onClick: () => console.log("Change Family clicked")
              },
              { label: "Self", name: "self", type: "checkbox" }
            ]
          }
        ]
      },
      {
        name: "Address",
        sections: [
          {
            sectionName: "Address",
            fields: [
              { label: "Family", name: "familyAddress", type: "checkbox" },
              { label: "Address", name: "address", type: "input", placeholder: "Enter Location" },
              { label: "City", name: "city", type: "input" },
              {
                label: "State",
                name: "state",
                type: "select",
                options: stateOptions || []
              },
              { label: "Zip", name: "zip", type: "select", options: zipOptions || [] }
            ]
          }
        ]
      },
      {
        name: "Contact Details",
        sections: [
          {
            sectionName: "Phone and Email",
            fields: [
              { label: "Family", name: "familyContact", type: "checkbox" },
              { label: "Mobile Phone", name: "mobilePhone", type: "input" },
              {
                label: "Send Texts",
                name: "sendTexts",
                type: "radio",
                options: [
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                  { label: "Ask", value: "ask" }
                ]
              },
              { label: "Home Phone", name: "homePhone", type: "input" },
              { label: "Work Phone", name: "workPhone", type: "input" },
              {
                label: "Send Reviews",
                name: "sendReviews",
                type: "radio",
                options: [
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" }
                ]
              },
              { label: "E-mail", name: "email", type: "input" },
              { label: "Add to Portal", name: "addToPortal", type: "checkbox" }
            ]
          },
          {
            sectionName: "Contact Preferences",
            fields: [
              { label: "Family", name: "familyPreferences", type: "checkbox" },
              {
                label: "Reach By",
                name: "reachBy",
                type: "select",
                options: [{ label: "Select", value: "" }]
              },
              {
                label: "Language",
                name: "language",
                type: "select",
                options: [{ label: "Select", value: "" }]
              },
              { label: "Contact Notes", name: "contactNotes", type: "textarea" }
            ]
          },
          {
            sectionName: "Emergency Contact",
            fields: [
              { label: "Name & Relation", name: "emergencyContactName", type: "input" },
              { label: "Phone", name: "emergencyContactPhone", type: "input" }
            ]
          }
        ]
      },
      {
        name: "Account and Treatment",
        sections: [
          {
            sectionName: "Contact Preferences",
            fields: [
              { label: "Family", name: "familyAccountPreferences", type: "checkbox" },
              {
                label: "Dentist",
                name: "dentist",
                type: "select",
                options: [{ label: "None", value: "none" }]
              },
              {
                label: "Hygienist",
                name: "hygienist",
                type: "select",
                options: [{ label: "None", value: "none" }]
              },
              {
                label: "Fees (Optional)",
                name: "fees",
                type: "select",
                options: [{ label: "None", value: "none" }]
              },
              { label: "Source", name: "source", type: "input" }
            ]
          }
        ]
      }
    ]
  };

  const handleInputChange = (value, name) => {
    if (name === "state") {
      setSelectedStateId(value);
    }
    if (name === "zip") {
      setFormData((prev) => ({
        ...prev,
        city: zipOptions.find((x) => x.value == value)?.city
      }));
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Patient Profile">
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={handleFormSubmit}
        initialData={initialData}
        formData={formData}
        mode={mode}
        handleInputChange={handleInputChange}
      />
    </CommonPopup>
  );
};

export default AddEditPatient;
