export const PatientMedication = {
  masterIds: [],
  patientId: 0,
  clientId: 0,
  createdBy: 0
};

export const AddEditMedication = {
  id: 0,
  createdBy: 1,
  updatedBy: 0,
  createdAt: null,
  updatedAt: null,
  isActive: true,
  medId: 0,
  medName: "",
  note: ""
};

export const EditPatientMedication = {
  requestType: 1, // 1 for Add, 2 for Edit
  patientMedication: {
    id: 0,
    createdBy: 1,
    updatedBy: 0,
    createdAt: "",
    updatedAt: "",
    isActive: true,
    medicationId: 0,
    clientId: 4,
    patientId: 0,
    medicationMasterId: 0,
    medicationName: "",
    patNote: "",
    dateStart: "",
    dateStop: "",
    providerId: 0,
    medDescript: "",
    rxCui: 0,
    newCropGuid: "",
    isCpoe: true,
    recordStatus: true
  }
};
