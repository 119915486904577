import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonPopup from "src/components/molecules/CommonPopup";
import DynamicForm from "src/components/organisms/DynamicForm";
import { editPatientAllergy, getPatientAllergies } from "src/store/actions/allergyAction";
import { EditPatientAllergy } from "src/models/allergyModel";
import usePatient from "src/hooks/use-patient";

const ManagePatientAllergy = ({ show, onClose, mode = "edit", initialData = {}, onSubmit }) => {
  const dispatch = useDispatch();

  // Retrieve global state values for patientId, clinicId, and createdBy
  const patientID = usePatient()?.patientId;
  const clinicID = useSelector((state) => state.clinics.selectedClinic?.id || 0);
  // const createdBy = useSelector((state) => state.auth?.user?.email || 0);
  const createdBy = 1;

  const [formData, setFormData] = useState({ ...EditPatientAllergy });

  useEffect(() => {
    setFormData({
      requestType: 2,
      patientAllergy: {
        ...initialData,
        patientID,
        clinicID,
        createdBy
      }
    });
  }, [mode, initialData, patientID, clinicID, createdBy]);

  const handleInputChange = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      patientAllergy: {
        ...prev.patientAllergy,
        [name]: value
      }
    }));
  };

  const handleFormSubmit = async () => {
    try {
      await dispatch(editPatientAllergy(formData));
      dispatch(getPatientAllergies(patientID)); // Refresh grid after save
      onSubmit && onSubmit();
      onClose();
    } catch (error) {
      console.error("Error saving allergy:", error);
    }
  };

  const tabs = [
    {
      name: "Edit Allergy",
      sections: [
        {
          fields: [
            {
              name: "allergyName",
              type: "input",
              label: "Allergy",
              value: formData.patientAllergy.allergyName || ""
            },
            {
              name: "allergyReaction",
              type: "input",
              label: "Reaction",
              value: formData.patientAllergy.allergyReaction || ""
            },
            {
              name: "allergySeverity",
              type: "input",
              label: "Severity",
              value: formData.patientAllergy.allergySeverity || ""
            },
            {
              name: "dateAdverseReaction",
              type: "date",
              label: "Date",
              value: formData.patientAllergy.dateAdverseReaction || new Date(),
              required: true
            }
          ]
        }
      ],
      footer: {
        right: [
          {
            type: "button",
            icon: "save",
            className: "save-button",
            text: "Save",
            onClick: handleFormSubmit
          }
        ]
      }
    }
  ];

  return (
    <CommonPopup show={show} onClose={onClose} title={tabs[0].name}>
      <DynamicForm
        tabs={tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData.patientAllergy}
        handleInputChange={handleInputChange}
        mode={mode}
      />
    </CommonPopup>
  );
};

export default ManagePatientAllergy;
