import React from "react";
import "./Profile.css";
import { getGenderLabel } from "src/utils/genderUtils";
import { getContactMethodLabel } from "src/utils/contactUtils";

const Profile = ({ patient }) => {
  console.log("Patient data: ", patient);

  return (
    <div className="profile-container">
      <div className="profile-left">
        {/* Profile Icon Section */}
        <div className="profile-icon">
          <div className="profile-img">{patient?.firstName?.[0] || ""}</div>
        </div>

        {/* Profile Info Section */}
        <div className="profile-info">
          <h2>{`${patient?.firstName} ${patient?.lastName} (${patient?.mrn})`}</h2>
          <p>{`${patient?.birthdate} (${getGenderLabel(patient?.gender)})`}</p>
          <p>Recare Visit: {patient?.recareDue || "Not Available"}</p>
        </div>

        {/* Family Members Section */}
        <div className="family-members">
          {/* This is a placeholder; update with family member data if available */}
          <img
            src="https://via.placeholder.com/50"
            alt="Family Member"
            className="family-member-img"
          />
        </div>
      </div>

      <div className="profile-right">
        {/* Details Section */}
        <div className="details">
          <div className="detail-item">
            <h4>Address</h4>
            <p>{`${patient?.address || ""}, ${patient?.city || ""}, ${patient?.state || ""} ${patient?.zip || ""}`}</p>
          </div>
          <div className="detail-item">
            <h4>Mobile</h4>
            <p>{patient?.mobilePhone || "Not Available"}</p>
          </div>
          <div className="detail-item">
            <h4>Home</h4>
            <p>{patient?.homePhone || "Not Available"}</p>
          </div>
          <div className="detail-item">
            <h4>Work</h4>
            <p>{patient?.workPhone || "Not Available"}</p>
          </div>
          <div className="detail-item">
            <h4>Email</h4>
            <p>{patient?.email || "Not Available"}</p>
          </div>
          <div className="detail-item">
            <h4>Reach By</h4>
            <p>{getContactMethodLabel(patient?.reachBy)}</p>
          </div>
          <div className="detail-item">
            <h4>Dentist</h4>
            <p>{patient?.dentist || "Not Available"}</p>
          </div>
          <div className="detail-item">
            <h4>Hygienist</h4>
            <p>{patient?.hygienist || "Not Available"}</p>
          </div>
          <div className="detail-item">
            <h4>Language</h4>
            <p>{patient?.language || "Not Specified"}</p>
          </div>
          <div className="detail-item">
            <h4>Status</h4>
            <p>{patient?.isActive ? "Active" : "Inactive"}</p>
          </div>
          <div className="detail-item">
            <h4>Patient ID</h4>
            <p>{patient?.mrn}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
