import React from "react";
import Button from "src/components/atoms/Button";

const SearchComponent = ({ title, columns, searchFields, onInputChange, onSearch }) => {
  const handleSearch = () => {
    const searchformData = {};

    Object.entries(searchFields).forEach(([field, value]) => {
      if (value && value.trim()) {
        searchformData[field] = value.trim();
      }
    });

    onSearch(searchformData);
  };

  return (
    <div style={{ marginBottom: "20px", width: "100%" }}>
      <h4 style={{ marginBottom: "10px" }}>{title}</h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "20px",
          marginBottom: "20px",
          position: "relative"
        }}
      >
        {/* First Column */}
        <div style={{ flex: 1 }}>
          {columns.slice(0, Math.ceil(columns.length / 2)).map((column) => (
            <div
              key={column.field}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px"
              }}
            >
              <label style={{ minWidth: "80px", textAlign: "right" }}>{column.title}:</label>
              <input
                type="text"
                value={searchFields[column.field]}
                onChange={(e) => onInputChange(column.field, e.target.value)}
                placeholder={`Search by ${column.title}`}
                style={{
                  flex: "1",
                  padding: "8px",
                  boxSizing: "border-box"
                }}
              />
            </div>
          ))}
        </div>

        {/* Second Column */}
        <div style={{ flex: 1 }}>
          {columns.slice(Math.ceil(columns.length / 2)).map((column) => (
            <div
              key={column.field}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px"
              }}
            >
              <label style={{ minWidth: "80px", textAlign: "right" }}>{column.title}:</label>
              <input
                type="text"
                value={searchFields[column.field]}
                onChange={(e) => onInputChange(column.field, e.target.value)}
                placeholder={`Search by ${column.title}`}
                style={{
                  flex: "1",
                  padding: "8px",
                  boxSizing: "border-box"
                }}
              />
            </div>
          ))}
          {/* Search Button */}
          <Button
            themeColor="primary"
            onClick={handleSearch}
            style={{
              width: "250px",
              marginLeft: "90px",
              marginTop: "10px",
              padding: "8px 16px",

              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer"
            }}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
