import React, { useState } from "react";
import DynamicGrid from "../../../organisms/DynamicGrid";
import { Button } from "@progress/kendo-react-buttons";
import { getGenderLabel } from "src/utils/genderUtils";
import PatientVisitEditPopup from "./PatientVisitEdit";
import usePopupVisibility from "src/hooks/usePopupVisibility";

const FeeScheduleGrid = ({ gridRef, data = [] }) => {
  const { popups, openPopup, closePopup } = usePopupVisibility();
  const [selectedVisitId, setSelectedVisitId] = useState(null);
  // Prepare rows by transforming the input data structure
  const rows = data?.map((patient, index) => ({
    id: index + 1,
    name: `${patient.firstName} ${patient.middleName || ""} ${patient.lastName}`.trim(),
    age: `${new Date().getFullYear() - new Date(patient?.birthdate).getFullYear()}`,
    gender: `${getGenderLabel(patient?.gender)}`,
    recareDue: patient.recareDue || "-",
    schedule: patient.schedule || "-",
    visits: patient.visitViewModels?.map((visit) => ({
      date: visit.date || "-",
      time: visit.time || "-",
      chair: visit.chair || "-",
      duration: visit.duration ? `${visit.duration} min` : "-",
      status: visit.status || "-",
      clinician: visit.clinician || "-",
      treatment: visit.treatment || "No Description",
      note: visit.note || "No Notes",
      id: visit.id
    }))
  }));
  // Dynamic columns configuration for the parent grid
  const parentColumns = [
    { field: "name", title: "Name", width: "200px" },
    {
      field: "showInOffices",
      title: "Show In Offices",
      width: "50px",
      sortable: true,
      filterable: true
    },
    { field: "type", title: "Type", width: "100px", sortable: true, filterable: true }
  ];

  // Dynamic rows data including visit data for each patient

  // Custom action buttons for the action column
  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => alert(`Scheduling for ${dataItem.name}`)
    }
  ];

  // Use DynamicGrid as both parent and detail grid
  return data ? (
    <>
      <DynamicGrid
        ref={gridRef}
        columns={parentColumns}
        rows={rows}
        actions={actions}
        allowExpand={true} // Enable row expand/collapse
        allowSort={true} // Enable sorting
      />

      {popups.editPatientVisit && (
        <PatientVisitEditPopup
          show={popups.editPatientVisit}
          onClose={() => closePopup("editPatientVisit")}
          visitId={selectedVisitId}
        />
      )}
    </>
  ) : (
    ""
  );
};

export default FeeScheduleGrid;
