import React, { useEffect, useState } from "react";
import DatatableLayout from "../../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../../organisms/DynamicGrid";
//import { ChairsService } from "../../../../services/SettingsService";
import ChairDialog from "./ChairDialog";
import axios from "axios";

const SettingsChairs = () => {
  const [chairs, setChairs] = useState([]);
  const [status, setStatus] = useState("Active");
  const [showDialog, setShowDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [initialData, setInitialData] = useState({});

  const fetchChairs = async () => {
    try {
      const response = await axios.get(
        "https://dev-api-practiceonlcoud.azurewebsites.net/api/Operatory/getallchairsbyclinicid/1"
      );
      setChairs(response.data);
      console.log("DATA: ", response.data);
    } catch (error) {
      console.error("Error fetching chairs:", error);
    }
  };

  useEffect(() => {
    fetchChairs();
  }, []);

  const columns = [
    { field: "opName", title: "Chair" },
    { field: "abbrev", title: "Abbreviation" },
    { field: "clinician", title: "Clinician" },
    { field: "hygienist", title: "Hygienist" },
    {
      field: "isHygiene",
      title: "Hygiene",
      cell: (props) => <td>{props.dataItem.isHygiene ? "Yes" : "No"}</td>
    }
  ];

  const actions = [
  {
    icon: "edit", // Edit Icon
    title: "Edit",
    onClick: (dataItem) => handleOpenDialog(true, dataItem)
  },
  {
    icon: "delete", // Delete Icon
    title: "Delete",
    onClick: async (dataItem) => {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete the chair: ${dataItem.opName}?`
      );
      if (confirmDelete) {
        try {
          await axios.delete(
            `https://dev-api-practiceonlcoud.azurewebsites.net/api/Operatory/deleteoperatorybyid/${dataItem.id}`
          );
          alert(`Chair ${dataItem.opName} deleted successfully!`);
          fetchChairs(); // Refresh the chairs list
        } catch (error) {
          console.error("Error deleting chair:", error);
          alert("Failed to delete the chair. Please try again.");
        }
      }
    }
  }
  ];

  // Function to open the dialog
  const handleOpenDialog = (isEdit = false, data = {}) => {
    setEditMode(isEdit);
    setInitialData(data);
    setShowDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setShowDialog(false);
    setInitialData({});
  };

  // Handle form submission
  const handleSubmit = async (formData) => {
    if (editMode) {
      const dataToUpdate = {
        updatedAt: new Date().toISOString(),
        clinicId: 1, // Hardcoded as of now
        isActive: formData.status === "Active", // Convert to boolean
        opName: formData.chair,
        abbrev: formData.abbreviation || "",
        clinician: formData.clinician || "",
        hygienist: formData.hygienist || "",
        provDentistId: formData.clinician === "Neel Kothari" ? 1 : 2,
        provHygienistId: formData.hygienist === "Jen Simpson" ? 55 : 1186,
        isHygiene: formData.hygieneChair ? 1 : 0,
        createdBy: 1, // Hardcoded as of now
        updatedBy: 1, //Hardcoded as of now
        operatoryId: initialData.id,
        id: initialData.id,
      };

      try {
        const response = await axios.put(
          `https://dev-api-practiceonlcoud.azurewebsites.net/api/Operatory/${initialData.id}`,
          dataToUpdate
        );
        alert("Chair updated successfully!");
        fetchChairs(); // Refresh the chairs list
      } catch (error) {
        console.error("Error updating chair:", error);
        alert("Failed to update chair. Please try again.");
      }
    } else {
      const dataToSend = {
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        clinicId: 1, // Hardcoded as of now
        isActive: formData.status === "Active", // Convert to boolean
        opName: formData.chair,
        abbrev: formData.abbreviation || "",
        createdBy: 1, // Hardcoded as of now
        clinician: formData.clinician || "",
        hygienist: formData.hygienist || "",
        provDentistId: formData.clinician === "Neel Kothari" ? 1 : 2,
        provHygienistId: formData.hygienist === "Jen Simpson" ? 55 : 1186,
        isHygiene: formData.hygieneChair ? 1 : 0
      };
      console.log("DATA SENT: ", dataToSend);

      try {
        const response = await axios.post(
          "https://dev-api-practiceonlcoud.azurewebsites.net/api/Operatory",
          dataToSend
        );
        console.log("Chair successfully created");
        fetchChairs();
        alert("Chair added successfully!");
      } catch (error) {
        console.error("Error adding chair:", error);
        alert("Failed to add chair. Please try again.");
      }
    }
    handleCloseDialog(); // Close the dialog
  };

  return (
    <>
      <DatatableLayout
        title="Chairs"
        statusOptions={["Active", "Inactive"]}
        status={status}
        onStatusChange={setStatus}
        onAdd={() => handleOpenDialog(false)} // Open dialog for adding a new chair
        addBtnLabel="Chairs"
      >
        <DynamicGrid
          columns={columns}
          rows={chairs}
          allowSort={false}
          allowFilter={false}
          actions={actions}
        />
      </DatatableLayout>

      {/* Chair Dialog */}
      <ChairDialog
        show={showDialog}
        onClose={handleCloseDialog}
        onSubmit={handleSubmit}
        mode={editMode ? "edit" : "add"}
        initialData={initialData}
      />
    </>
  );
};

export default SettingsChairs;
