import { createSlice } from "@reduxjs/toolkit";
import { fetchCodeDefinitionForProcedure } from "../actions/codeDefinitionAction";

const codeDefinitionSlice = createSlice({
  name: "codeDefinition",
  initialState: {
    codeDefinitionForProcedure: [],
    loading: {
      codeDefinitionForProcedure: false
    },
    error: {
      codeDefinitionForProcedure: null
    }
  },

  reducers: {
    setCodeDefinitionForProcedure(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCodeDefinitionForProcedure.pending, (state) => {
        state.loading.codeDefinitionForProcedure = true;
        state.error.codeDefinitionForProcedure = null;
      })
      .addCase(fetchCodeDefinitionForProcedure.fulfilled, (state, action) => {
        state.loading.codeDefinitionForProcedure = false;
        state.codeDefinitionForProcedure = action.payload;
      })
      .addCase(fetchCodeDefinitionForProcedure.rejected, (state, action) => {
        state.loading.codeDefinitionForProcedure = false;
        state.error.codeDefinitionForProcedure = action.payload;
      });
  }
});

export const selectCodeDefinitionForProcedure = (state) =>
  state.codeDefinition.codeDefinitionForProcedure;
export const selectCodeDefinitionForProcedureLoading = (state) => state.codeDefinition.loading;
export const selectCodeDefinitionForProcedureError = (state) => state.codeDefinition.error;
export const { setCodeDefinitionForProcedure } = codeDefinitionSlice.actions;
export default codeDefinitionSlice.reducer;
