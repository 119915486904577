import React, { useRef, useEffect } from "react";
import CommonPopup from "../../../molecules/CommonPopup"; // Import the reusable CommonPopup component
import DynamicForm from "../../../organisms/DynamicForm"; // Import the dynamic form component
import PatientVisitsGrid from "./PatientVisitsGrid"; // Import the custom patient visit grid component
import { useSelector, useDispatch } from "react-redux";
import { selectProfileDetails } from "src/store/slices/patientSlice";
import { fetchFamilyVisitDetails } from "src/store/actions/visitAction";
import { selectFamilyVisitDetails } from "src/store/slices/visitSlice";
import FeeScheduleGrid from "./FeeScheduleGrid";
import AddFeeSchedulePopup from "./AddFeeSchedulePopup";
import usePopupVisibility from "src/hooks/usePopupVisibility";

const FeeSchedulePopup = ({ show, onClose }) => {
  const profile = useSelector((state) => selectProfileDetails(state));
  const gridRef = useRef();
  const familyVisitDetails = useSelector((state) => selectFamilyVisitDetails(state));
  const dispatch = useDispatch();
  const { popups, openPopup, closePopup } = usePopupVisibility();
  // Handle patient change (e.g., through dropdown selection)

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(fetchFamilyVisitDetails(profile.patientId));
  }, [dispatch, profile]);

  const handlePrint = () => {
    gridRef.current.printGrid();
  };
  // Define the dynamic form structure for the selected patient
  const formStructure = {
    title: "Patient Visit",
    tabs: [
      {
        name: "Patient Info",
        header: {
          right: [
            {
              type: "select",
              text: "Active"
            },
            {
              type: "button",
              text: "Fee Schedule",
              onClick: (dataItem) => {
                openPopup("addFeeSchedule");
              },
              icon: "add"
            }
          ]
        },
        sections: [
          {
            customSection: (
              <FeeScheduleGrid
                gridRef={gridRef}
                data={familyVisitDetails} // Pass visit data to the grid
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <>
      <CommonPopup show={show} onClose={onClose} title={"Fee Schedule"}>
        {/* Dynamic Form for Patient Information */}
        <DynamicForm tabs={formStructure.tabs} handleFormSubmit={() => alert("Form Submitted")} />
      </CommonPopup>
      {popups.addFeeSchedule && (
        <AddFeeSchedulePopup
          show={popups.addFeeSchedule}
          onClose={() => closePopup("addFeeSchedule")}
        />
      )}
    </>
  );
};

export default FeeSchedulePopup;
