import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const getEmployeeByClientId = createAsyncThunk(
  "Employee/getemployeebyclientid",
  async (clientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/Employee/getemployeebyclientid/${clientId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching employee");
    }
  }
);

export const getEmployeeByClinicId = createAsyncThunk(
  "Employee/getemployeebyclinicid",
  async (clinicId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/Employee/getemployeebyclinicid/${clinicId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching employee");
    }
  }
);

export const getEmployeeByEmployeeId = createAsyncThunk(
  "Employee/getemployeebyid",
  async (employeeId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/Employee/getemployeebyid/${employeeId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching employee");
    }
  }
);

export const manageEmployee = createAsyncThunk(
  "/Employee/manageemployee",
  async (employeeModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(`/Employee/manageemployee`, employeeModel);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving employee");
    }
  }
);
