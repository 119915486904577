import { createSlice } from "@reduxjs/toolkit";

import { fetchFeeScheduleByClient } from "../actions/feeScheduleAction";

const feeScheduleSlice = createSlice({
  name: "feeSchedule",
  initialState: {
    feeSchedule: null,
    loading: {
      feeSchedule: false
    },
    error: {
      feeSchedule: null
    }
  },

  reducers: {
    setFeeSchedule(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchFeeScheduleByClient.pending, (state) => {
        state.loading.feeSchedule = true;
        state.error.feeSchedule = null;
      })
      .addCase(fetchFeeScheduleByClient.fulfilled, (state, action) => {
        state.loading.feeSchedule = false;
        state.feeSchedule = action.payload;
      })
      .addCase(fetchFeeScheduleByClient.rejected, (state, action) => {
        state.loading.feeSchedule = false;
        state.error.feeSchedule = action.payload;
      });
  }
});

export const selectFeeSchedule = (state) => state.feeSchedule.feeSchedule;
export const selectFeeScheduleLoading = (state) => state.feeSchedule.loading;
export const selectFeeScheduleListError = (state) => state.feeSchedule.error;
export const { setFeeSchedule } = feeScheduleSlice.actions;
export default feeScheduleSlice.reducer;
