import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "src/store/slices/appSlice";
import { useAutoDismiss } from "src/hooks/useAutoDismiss";

const position = {
  bottomRight: { bottom: 0, right: 0, alignItems: "flex-end" }
};

const NotificationWrapper = () => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.app);
  useAutoDismiss(notification);

  return (
    <NotificationGroup style={position.bottomRight}>
      <Slide direction={notification ? "up" : "down"}>
        {notification && (
          <Notification
            style={{
              height: "60px",
              padding: "20px",
              fontSize: "16px"
            }}
            type={{
              style: notification.type,
              icon: true
            }}
            closable={true}
            onClose={() => dispatch(clearNotification())}
          >
            <span>{notification.message}</span>
          </Notification>
        )}
      </Slide>
    </NotificationGroup>
  );
};

export default NotificationWrapper;
