import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAllergyMaster, getPatientAllergies } from "../actions/allergyAction";

const allergySlice = createSlice({
  name: "allergies",
  initialState: {
    masterAllergies: [],
    patientAllergies: [],
    loading: {
      masterAllergies: false,
      patientAllergies: false
    },
    error: {
      masterAllergies: [],
      patientAllergies: []
    }
  },

  reducers: {
    setAllergyMaster(state, action) {
      state.data = action.payload;
    },
    setPatientAllergies(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllergyMaster.pending, (state) => {
        state.loading.masterAllergies = true;
        state.error.masterAllergies = [];
      })
      .addCase(fetchAllergyMaster.fulfilled, (state, action) => {
        state.loading.masterAllergies = false;
        state.masterAllergies = action.payload;
      })
      .addCase(fetchAllergyMaster.rejected, (state, action) => {
        state.loading.masterAllergies = false;
        state.error.masterAllergies = action.payload;
      })
      .addCase(getPatientAllergies.pending, (state) => {
        state.loading.patientAllergies = true;
        state.error.patientAllergies = [];
      })
      .addCase(getPatientAllergies.fulfilled, (state, action) => {
        state.loading.patientAllergies = false;
        state.patientAllergies = action.payload;
      })
      .addCase(getPatientAllergies.rejected, (state, action) => {
        state.loading.patientAllergies = false;
        state.error.patientAllergies = action.payload;
      });
  }
});

export const selectAllergyMaster = (state) => state.allergies.masterAllergies;
export const selectAllergyMasterLoading = (state) => state.allergies.loading;
export const selectAllergyMasterError = (state) => state.allergies.error;
export const selectPatientAllergy = (state) => state.allergies.patientAllergies;
export const selectPatientAllergyLoading = (state) => state.allergies.loading;
export const selectPatientAllergyError = (state) => state.allergies.error;
export const { setAllergyMaster, setPatientAllergies } = allergySlice.actions;
export default allergySlice.reducer;
