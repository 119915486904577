import httpClient from "../http-client";

const fetchSAClients = (activestatus = true, skip = 0, take = 10) =>
  // in api is is hard coded isActive=true
  httpClient.get(`/Client/paginated?skip=${skip}&take=${take}&isActive=${activestatus}`);

const addSAClient = async (formData) => {
  // without stateselection and zipselection in ui Clinent is not created and editable it show 404

  let payload = {
    client: {
      isActive: true,
      clientName: formData.clientName,
      displayClientName: formData.clientName,
      address: formData.address,
      adminId: 0,
      adminType: null,
      owner: formData.owner,
      contactPhone: formData.contactPhone,
      stateId: formData.state,
      countryId: formData.countryId,
      preferredLanguageId: 0,
      isCorporation: true,
      contactEmail: formData.contactEmail,
      city: formData.city,
      zipCodeId: formData.zipCodeId,
      ePrescribing: formData.ePrescribing
    },
    user: {
      username: formData.username,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      role: "ACCOUNT_ADMINISTRATOR",
      isClinician: formData.isClinician
    }
  };

  // first create client without adminId then create admin
  const client = await httpClient.post("/Client/createclient", payload);
  return { client };
};

const editSAClient = (formData) => {
  const admindata = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    username: formData.username,
    email: formData.email,
    role: formData.role
  };

  const data = {
    id: formData.id,
    adminId: formData.adminId,
    isActive: formData?.inactive === undefined || !formData?.inactive,
    clientName: formData.clientName,
    owner: formData.owner,
    contactPhone: formData.contactPhone,
    contactemail: formData.contactEmail,
    address: formData.address,
    city: formData.city,
    stateId: formData.state,
    zipCodeId: formData.zipCodeId,
    ePrescribing: formData.ePrescribing,
    createdBy: 1
  };

  return httpClient.put(`/Client/${formData.id}`, data);
};

const addClinic = (officeformData) => {
  const data = {
    clientId: officeformData.clientId,
    description: officeformData.description,
    phone: officeformData.phone,
    emailAddress: officeformData.emailAddress,
    address: officeformData.address,
    city: officeformData.city,
    stateId: officeformData.state,
    zipId: officeformData.zipId,
    timeZone: officeformData.timeZone,
    abbr: officeformData.abbr,
    createdBy: 1,
    updatedBy: 1,
    smsContractDate: "2025-01-07T07:06:13.679Z"
  };

  return httpClient.post("/Clinic", data);
};

const editClinic = (formData) => {
  const data = {
    id: formData.id,
    clientId: formData.clientId,
    description: formData.description,
    phone: formData.phone,
    emailAddress: formData.emailAddress,
    address: formData.address,
    stateId: formData.state,
    zipId: formData.zipId,
    timeZone: formData.timeZone,
    abbr: formData.abbr,
    city: formData.city,
    createdBy: 1,
    updatedBy: 1
  };

  return httpClient.put(`/Clinic/${formData.id}`, data);
};

const searchSAClient = async (searchformData, skip = 0, take = 10, activestatus) => {
  const params = {
    ...searchformData,
    skip: skip,
    take: take,
    isActive: activestatus
  };

  const queryString = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null && value !== "")
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return await httpClient.get(`/Client/search?${queryString}`);
};

const fetchStates = async () => {
  try {
    return await httpClient.get(`/StateMaster/getallstatemaster`);
  } catch (error) {
    console.error("Error fetching states:", error);
    return [];
  }
};

const fetchZipcode = async (stateId) => {
  try {
    return await httpClient.get(`/ZipCodeMaster/getallzipcodebystateid/${stateId}`);
  } catch (error) {
    console.error("Error fetching states:", error);
    return [];
  }
};

const fetchOfficeSearch = (searchformData, skip = 0, take = 10) => {
  const params = {
    ...searchformData,
    skip: skip,
    take: take
  };

  const queryString = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null && value !== "")
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return httpClient.get(`/Client/searchoffices?${queryString}`);
};

const getUserData = (adminId) => {
  return httpClient.get(`/User/${adminId}`);
};

export {
  fetchSAClients,
  addSAClient,
  editSAClient,
  addClinic,
  editClinic,
  searchSAClient,
  fetchStates,
  fetchZipcode,
  fetchOfficeSearch,
  getUserData
};
