import httpClient from "./http-client";

const DocumentsService = {
  getDirectories: async () => {
    return await httpClient("/PatientDocuments/1/docs/categories");
  },

  getDirectoryFiles: async (directoryId) => {
    return await httpClient(`/PatientDocuments/1/docs/categories/${directoryId}/docs`);
  },

  uploadFile: async (directoryId, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("DocCategory", directoryId);
    return await httpClient(`/PatientDocuments/1/docs`, {
      method: "POST",
      body: formData
    });
  },

  deleteFile: async (fileId) => {
    return await httpClient(`/PatientDocuments/1/docs/${fileId}`, { method: "DELETE" });
  },

  downloadFile: async (fileId) => {
    return await httpClient(`/PatientDocuments/1/docs/${fileId}/download`, {
      responseType: "blob"
    });
  }
};

export default DocumentsService;
