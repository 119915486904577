import { combineReducers } from "@reduxjs/toolkit";
import insuraceReducer from "./insuranceSlice";
import employerReducer from "./employerSlice";
import depositslipReducer from "./depositslipSlice";

const adminReducer = combineReducers({
  insurance: insuraceReducer,
  employer: employerReducer,
  depositslip: depositslipReducer
});

export default adminReducer;
