import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonPopup from "src/components/molecules/CommonPopup";
import DynamicForm from "src/components/organisms/DynamicForm";
import { editPatientDisease, getPatientDiseases } from "src/store/actions/diseaseAction";
import { EditPatientDisease } from "src/models/diseaseModel";
import usePatient from "src/hooks/use-patient";

const ManagePatientDisease = ({ show, onClose, mode = "edit", initialData = {}, onSubmit }) => {
  const dispatch = useDispatch();

  // Retrieve global state values for patientId, clinicId, and createdBy
  const patientID = usePatient()?.patientId;
  const clinicID = useSelector((state) => state.clinics.selectedClinic?.id || 0);
  // const createdBy = useSelector((state) => state.auth?.user?.email || 0);
  const createdBy = 1;

  const [formData, setFormData] = useState({ ...EditPatientDisease });

  useEffect(() => {
    setFormData({
      requestType: 2,
      patientDisease: {
        ...initialData,
        patientID,
        clinicID,
        createdBy
      }
    });
  }, [mode, initialData, patientID, clinicID, createdBy]);

  const handleInputChange = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      patientDisease: {
        ...prev.patientDisease,
        [name]: value
      }
    }));
  };

  const handleFormSubmit = async () => {
    try {
      await dispatch(editPatientDisease(formData));
      dispatch(getPatientDiseases(patientID)); // Refresh grid after save
      onSubmit && onSubmit();
      onClose();
    } catch (error) {
      console.error("Error saving disease:", error);
    }
  };

  const tabs = [
    {
      name: "Edit Disease",
      sections: [
        {
          fields: [
            {
              name: "diseaseName",
              type: "input",
              label: "Health Issue",
              value: formData.patientDisease.diseaseName || ""
            },
            {
              name: "patNote",
              type: "input",
              label: "Comment",
              value: formData.patientDisease.patNote || ""
            },
            {
              name: "recordStatus",
              type: "checkbox",
              label: "Status",
              value: formData.patientDisease.recordStatus || ""
            },
            {
              name: "dateStart",
              type: "date",
              label: "Date",
              value: formData.patientDisease.dateStart || new Date()
            },
            {
              name: "dateStop",
              type: "date",
              label: "Date",
              value: formData.patientDisease.dateStop || new Date()
            }
          ]
        }
      ],
      footer: {
        right: [
          {
            type: "button",
            icon: "save",
            className: "save-button",
            text: "Save",
            onClick: handleFormSubmit
          }
        ]
      }
    }
  ];

  return (
    <CommonPopup show={show} onClose={onClose} title={tabs[0].name}>
      <DynamicForm
        tabs={tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData.patientDisease}
        handleInputChange={handleInputChange}
        mode={mode}
      />
    </CommonPopup>
  );
};

export default ManagePatientDisease;
