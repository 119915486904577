import React, { useState, useEffect } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import ActionButton from "../atoms/ActionButton";
import RenderField from "../molecules/DynamicFormFields";
import "./DynamicForm.css";

// Render custom section for headers, footers, and section footers
export const renderCustomSection = (
  section,
  handleInputChange,
  formData,
  uniquePrefix,
  onSubmit
) => {
  return (
    <div className="custom-section">
      <div className="custom-section-left">
        {section.left &&
          section.left.map((field, idx) => (
            <div key={idx} className="header-footer-group">
              {RenderField(field, handleInputChange, formData, uniquePrefix, onSubmit)}
            </div>
          ))}
      </div>
      <div className="custom-section-right">
        {section.right &&
          section.right.map((field, idx) => (
            <div key={idx} className="header-footer-group button-group">
              {RenderField(field, handleInputChange, formData, uniquePrefix, onSubmit)}
            </div>
          ))}
      </div>
    </div>
  );
};

// Render the content of a section, including duplicated instances
const renderSectionContent = (section, handleInputChange, formData, uniquePrefix, onSubmit) => {
  // Determine the layout class based on the section's or field's layout type
  const sectionLayoutClass = section.layout
    ? section.layout + " custom-section-layout"
    : "form-grid";
  const isNonGrid = section.fields?.some((field) => field.type === "searchbutton");

  return (
    <form style={{ padding: "20px" }}>
      <div className={isNonGrid ? "non-grid-layout" : sectionLayoutClass}>
        {section.fields &&
          section.fields.map((field, fieldIdx) => {
            const fieldLayoutClass = field.layout || "form-group";
            return field.type === "textarea" ? (
              <div key={fieldIdx} className="grid-field-wrapper">
                {RenderField(field, handleInputChange, formData, uniquePrefix)}
              </div>
            ) : (
              <div key={fieldIdx} className={fieldLayoutClass}>
                {field.type !== "searchbutton" &&
                  field.type !== "textarea" &&
                  field.labelPosition !== "internal" &&
                  field.label !== undefined && (
                    <label>
                      {field.label}
                      {field.label !== "" ? ":" : ""}
                      {field.required && <span style={{ color: "red" }}>*</span>}
                    </label>
                  )}
                {RenderField(field, handleInputChange, formData, uniquePrefix, onSubmit)}
              </div>
            );
          })}
      </div>
    </form>
  );
};

// Main DynamicForm component
const DynamicForm = ({
  tabs,
  handleFormSubmit,
  formData: externalFormData,
  setFormData: setFormData,
  handleInputChange: externalHandleInputChange,
  mode = "add",
  initialData = {}
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [internalFormData, setInternalFormData] = useState(initialData);
  const [duplicatedSections, setDuplicatedSections] = useState({});
  const [uniqueIdCounter, setUniqueIdCounter] = useState(0);

  const formData = externalFormData || internalFormData;
  const handleInputChange =
    externalHandleInputChange ||
    ((value, fieldName) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: value
      }));

      setInternalFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: value
      }));
    });

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const handleNext = () => {
    if (selectedTab < tabs.length - 1) {
      setSelectedTab(selectedTab + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    }
  };

  const onSubmit = () => {
    handleFormSubmit(internalFormData);
  };

  const duplicateSection = (tabIndex, sectionIndex) => {
    setUniqueIdCounter((prevId) => prevId + 1);
    setDuplicatedSections((prevDuplicatedSections) => {
      const tabDuplicates = prevDuplicatedSections[tabIndex] || {};
      const sectionDuplicates = tabDuplicates[sectionIndex] || [];
      return {
        ...prevDuplicatedSections,
        [tabIndex]: {
          ...tabDuplicates,
          [sectionIndex]: [...sectionDuplicates, uniqueIdCounter]
        }
      };
    });
  };

  const renderSections = (
    tabIndex,
    sections,
    handleInputChange,
    formData,
    sectionLayout,
    onSubmit
  ) => {
    return sections.map((section, sectionIndex) => (
      <>
        {" "}
        <div
          key={sectionIndex}
          className={
            section.sectionName
              ? `${section.boxLayout ? section.boxLayout + " " : ""}section-box flex-basis`
              : ` ${section.boxLayout ? section.boxLayout + " " : ""}box flex-basis`
          }
          style={{
            flex: `1 1 ${section.minWidth}`, // Allows flexibility with a minimum width
            maxWidth: section.maxWidth === "1fr" ? "none" : section.maxWidth, // Apply maxWidth if provided
            minWidth: section.minWidth
          }}
        >
          {section.sectionHeader &&
            renderCustomSection(
              section.sectionHeader,
              handleInputChange,
              formData,
              undefined,
              onSubmit
            )}
          {section.customSection && (
            <div className="custom-section-component" style={{ padding: "20px" }}>
              {section.customSection}
            </div>
          )}
          {section.sectionName && <div className="section-title">{section.sectionName}</div>}
          {renderSectionContent(section, handleInputChange, formData, undefined, onSubmit)}
          {section.sectionFooter &&
            renderCustomSection(
              section.sectionFooter,
              handleInputChange,
              formData,
              undefined,
              onSubmit
            )}

          {/* Conditional rendering of Duplicate Button */}
          {section.allowDuplicate && (
            <ActionButton
              icon="add"
              className="add-button"
              style={{ margin: "10px" }}
              onClick={() => duplicateSection(tabIndex, sectionIndex)}
            >
              Duplicate {section.sectionName}
            </ActionButton>
          )}
        </div>
        {/* Render duplicated sections */}
        {duplicatedSections[tabIndex]?.[sectionIndex]?.map((uniqueId) => (
          <div
            key={`duplicate-${sectionIndex}-${uniqueId}`}
            className={
              section.sectionName
                ? `${section.boxLayout ? section.boxLayout + " " : ""}section-box`
                : ` ${section.boxLayout ? section.boxLayout + " " : ""}box`
            }
          >
            {section.sectionName && (
              <div className="section-title">
                {section.sectionName} (Copy {uniqueId + 1})
              </div>
            )}
            {renderSectionContent(
              section,
              handleInputChange,
              formData,
              `${section.sectionName}${uniqueId + 1}`,
              onSubmit
            )}
          </div>
        ))}
      </>
    ));
  };

  return (
    <div className="custom-form">
      {tabs.length > 1 ? (
        <>
          <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            {tabs.map((tab, tabIndex) => {
              const columnWidthStyles = tab.columnWidths
                ?.map(({ min, max }) => `minmax(${min}, ${max})`)
                .join(" ");
              return (
                <TabStripTab key={tabIndex} title={tab.name}>
                  <div
                    className={` ${tab.layout ? "flex-display" + " " : ""}tab-content default-tab-layout`}
                  >
                    {tab.header &&
                      renderCustomSection(
                        tab.header,
                        handleInputChange,
                        formData,
                        undefined,
                        onSubmit
                      )}
                    {renderSections(
                      tabIndex,
                      tab.sections,
                      handleInputChange,
                      formData,
                      tab.layout
                    )}
                    {tab.footer &&
                      renderCustomSection(tab.footer, handleInputChange, formDataundefined, "")}
                  </div>
                </TabStripTab>
              );
            })}
          </TabStrip>
          <div className="footer-buttons">
            <ActionButton
              className="save-button"
              onClick={handlePrevious}
              disabled={selectedTab === 0}
            >
              Prev
            </ActionButton>
            <ActionButton
              className="save-button"
              onClick={selectedTab === tabs.length - 1 ? onSubmit : handleNext}
            >
              {selectedTab === tabs.length - 1 ? (mode === "edit" ? "Update" : "Submit") : "Next"}
            </ActionButton>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="content">
            {tabs[0].header &&
              renderCustomSection(tabs[0].header, handleInputChange, formData, undefined, onSubmit)}
            {renderSections(
              0,
              tabs[0].sections,
              handleInputChange,
              formData,
              tabs[0].layout,
              onSubmit
            )}
          </div>
          {tabs[0].footer &&
            renderCustomSection(tabs[0].footer, handleInputChange, formData, undefined, onSubmit)}
        </>
      )}
    </div>
  );
};

export default DynamicForm;
