import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { useSelector, useDispatch } from "react-redux";
import { fetchMedicationMaster, savePatientMedication } from "src/store/actions/medicationAction";
import { selectMedicationMaster } from "src/store/slices/medicationSlice";
import { PatientMedication } from "src/models/medicationModel";
import CommonPopup from "../../../molecules/CommonPopup";
import Button from "src/components/atoms/Button";

const MasterMedication = ({ gridRef, patientId, show, onClose }) => {
  const { clinicId } = useParams();
  const dispatch = useDispatch();
  const masterMedication = useSelector((state) => selectMedicationMaster(state));
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    dispatch(fetchMedicationMaster());
  }, [dispatch, fetchMedicationMaster]);

  const columns = [
    { field: "medName", title: "Generic Drug", width: "250px" },
    { field: "note", title: "Generic Note", width: "250px" }
  ];

  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => alert(`Editing ${dataItem.name}`)
    },
    {
      title: "Delete",
      icon: "delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.name}`)
    }
  ];

  const handleSelectedRowsChange = (selectedRowIds) => {
    setSelectedRows(selectedRowIds);
  };

  const saveMedication = () => {
    console.log("selec", selectedRows);
    const newPatientMedication = {
      ...PatientMedication,
      patientId: patientId.patientId,
      masterIds: selectedRows,
      clientId: 4,
      createdBy: 1
    };
    dispatch(savePatientMedication(newPatientMedication));
    onClose();
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Medications">
      <DynamicGrid
        ref={gridRef}
        columns={columns}
        rows={masterMedication}
        actions={actions}
        allowCheckable={true}
        dataItemKey={"medId"}
        onSelectedRowsChange={handleSelectedRowsChange}
        pageSize={10}
        pageable={true}
      />
      <Button themeColor="primary" onClick={saveMedication}>
        Save
      </Button>
    </CommonPopup>
  );
};

export default MasterMedication;
