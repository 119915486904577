import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepositslips } from "src/store/actions/admin/depositslipActions";

const AdminDepositSlips = () => {
  const dispatch = useDispatch();
  const { depositslips, loading } = useSelector((state) => state.admin.depositslip);

  const [localDepositslip, setLocalDepositslip] = useState([]);
  const [totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    if (!depositslips?.results?.length) {
      dispatch(fetchDepositslips());
    }
  }, [dispatch, depositslips]);

  useEffect(() => {
    if (depositslips?.results) {
      setLocalDepositslip(depositslips.results);
      setTotalSize(depositslips.total);
    }
  }, [depositslips]);
  const onPageChange = (event) => {
    const targetEvent = event.targetEvent;
    console.log(targetEvent.value);
    let val = parseInt(event.nativeEvent.target.textContent, 0);
    console.log(val);
  };

  const columns = [
    { field: "date", title: "Date" },
    { field: "type", title: "Type" },
    { field: "payer", title: "Payer" },
    { field: "method", title: "Method" },
    { field: "payment", title: "Payment" }
  ];

  return (
    <DatatableLayout
      title="Deposit Slip"
      onAdd={() => console.log("Add new Deposit Slip")}
      addBtnLabel="Deposit Slip"
    >
      <DynamicGrid
        columns={columns}
        rows={localDepositslip}
        loading={loading}
        pageSize={100}
        pageable={true}
        total={totalSize}
        allowSort={false}
        allowFilter={false}
        OnpageChange={onPageChange}
      />
    </DatatableLayout>
  );
};

export default AdminDepositSlips;
