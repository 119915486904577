import httpClient from "./http-client";

export const OfficeFormService = {
  getOfficeForms: () => [
    { form: "Form", title: "A Test of Dr. Ross Patient Registration (Default) 1" },
    { form: "Form", title: "A Test of Dr. Ross Patient Registration (Default) 2" },
    { form: "Form", title: "Consent - Teeth Whitening" },
    { form: "Form", title: "Consent - Teeth Whitening 1" }
    // more rows...
  ]
};

export const FormPacketService = {
  getFormPackets: () => [
    { packet: "Consent Custom Q&A", forms: "A Test of Dr. Ross Patient Registration (Default) 1" },
    { packet: "New Package", forms: "A Test of Dr. Ross Patient Registration (Default) 2" },
    { packet: "New Patient", forms: "Consent - Teeth Whitening" }
    // more rows...
  ]
};

export const PrescriptionService = {
  getPrescriptions: () => [
    {
      medication: "-",
      controlled: "-",
      instructions: "-",
      quantity: "-",
      refills: "-",
      notes: "-"
    },
    {
      medication: "-",
      controlled: "-",
      instructions: "-",
      quantity: "-",
      refills: "-",
      notes: "-"
    },
    {
      medication: "-",
      controlled: "-",
      instructions: "-",
      quantity: "-",
      refills: "-",
      notes: "-"
    },
    { medication: "-", controlled: "-", instructions: "-", quantity: "-", refills: "-", notes: "-" }
    // more rows...
  ]
};

export const EmployeeService = {
  getEmployees: () => [
    { employer: "Amazon" },
    { employer: "Amazon" },
    { employer: "Amazon" }
    // more rows...
  ]
};

export const PaidClaimWoEOBService = {
  getPaidClaimsWoEOB: () => [
    {
      patient: "Test Ortho",
      insurance: "ADVENTIST HEALTH",
      servicedate: "11/04/2024",
      receiveddate: "11/04/2024",
      amount: "$10"
    },
    {
      patient: "Test Ortho",
      insurance: "ADVENTIST HEALTH",
      servicedate: "11/04/2024",
      receiveddate: "11/04/2024",
      amount: "$10"
    },
    {
      patient: "Test Ortho",
      insurance: "ADVENTIST HEALTH",
      servicedate: "11/04/2024",
      receiveddate: "11/04/2024",
      amount: "$10"
    },
    {
      patient: "Test Ortho",
      insurance: "ADVENTIST HEALTH",
      servicedate: "11/04/2024",
      receiveddate: "11/04/2024",
      amount: "$10"
    }
    // more rows...
  ]
};

export const BillingClinicService = {
  getBillingClinics: () => [
    {
      clinicname: "Eastern Clinic",
      abbreviation: "EC"
    },
    {
      clinicname: "Smiles Clinic",
      abbreviation: "SC"
    },
    {
      clinicname: "Eastern Clinic",
      abbreviation: "EC"
    },
    {
      clinicname: "Smiles Clinic",
      abbreviation: "SC"
    }
    // more rows...
  ]
};

export const PharmacyService = {
  getPharmacies: () => [
    {
      name: "A1",
      address: "4215 South Military Trail",
      city: "Culver City",
      state: "California",
      zip: "49090",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@mway.com",
      comment: ""
    },
    {
      name: "B2",
      address: "9652 West Market Street",
      city: "Kernersville",
      state: "Ohio",
      zip: "48917",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@mway.com",
      comment: ""
    },
    {
      name: "C3",
      address: "234 West 42nd Street",
      city: "Shadyside",
      state: "California",
      zip: "49090",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@mway.com",
      comment: ""
    },
    {
      name: "A1",
      address: "4215 South Military Trail",
      city: "Culver City",
      state: "California",
      zip: "48917",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@mway.com",
      comment: ""
    }
    // more rows...
  ]
};

export const LaboratoryService = {
  getLaboratories: () => [
    {
      laboratory: "Crowns 4 U",
      address: "4215 South Military Trail",
      city: "Culver City",
      state: "California",
      zip: "49090",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@mway.com",
      comment: ""
    },
    {
      laboratory: "Default Lab",
      address: "9652 West Market Street",
      city: "Kernersville",
      state: "Ohio",
      zip: "48917",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@mway.com",
      comment: ""
    },
    {
      laboratory: "Denures 4 Us",
      address: "234 West 42nd Street",
      city: "Shadyside",
      state: "California",
      zip: "49090",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@mway.com",
      comment: ""
    },
    {
      laboratory: "Crowns 4 U",
      address: "4215 South Military Trail",
      city: "Culver City",
      state: "California",
      zip: "48917",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@mway.com",
      comment: ""
    }
    // more rows...
  ]
};

export const OfficeNewsService = {
  getOfficeNews: () => [
    {
      startdate: "12/12/2023",
      enddate: "12/12/2023",
      title: "test",
      message: "test"
    },
    {
      startdate: "12/12/2023",
      enddate: "12/12/2023",
      title: "Testing",
      message: "test"
    },
    {
      startdate: "12/12/2023",
      enddate: "12/12/2023",
      title: "Testing",
      message: "test"
    },
    {
      startdate: "12/12/2023",
      enddate: "12/12/2023",
      title: "Testing",
      message: "test"
    }
    // more rows...
  ]
};

export const TreatmentAndFeeService = {
  getTreatmentAndFees: () => [
    {
      group: "Diagnostic",
      adacode: "09876",
      treatment: "test0123",
      officecode: "09876",
      feeschedule: "$11.00"
    },
    {
      group: "Diagnostic",
      adacode: "09876",
      treatment: "test0123",
      officecode: "09876",
      feeschedule: "$11.00"
    },
    {
      group: "Diagnostic",
      adacode: "09876",
      treatment: "test0123",
      officecode: "09876",
      feeschedule: "$11.00"
    },
    {
      group: "Diagnostic",
      adacode: "09876",
      treatment: "test0123",
      officecode: "09876",
      feeschedule: "$11.00"
    }
    // more rows...
  ]
};

export const NoteTemplateService = {
  getNoteTemplates: () => [
    {
      template: "Hello testing note",
      scheduled: "Testprompt"
    },
    {
      template: "This is test for patient fun fact",
      scheduled: "Funfact"
    }
    // more rows...
  ],

  getGroups: () => [
    {
      treatment: "Account"
    },
    {
      treatment: "Medication"
    },
    {
      treatment: "Rx"
    },
    {
      treatment: "New Adjustment"
    }
  ]
};

export const InsurancePlanService = {
  getInsurancePlans: () => [
    {
      employer: "Amazon",
      insurance: "ADVENTIST HEALTH",
      phone: "(800) 888-9999",
      address: "55 Fruit Street",
      city: "Culver City",
      state: "California",
      zip: "10004",
      groupname: "10004",
      groupid: "WOFM2584DOM"
    },
    {
      employer: "Amazon",
      insurance: "ADVENTIST HEALTH",
      phone: "(800) 888-9999",
      address: "55 Fruit Street",
      city: "Culver City",
      state: "California",
      zip: "10004",
      groupname: "10004",
      groupid: "WOFM2584DOM"
    },
    {
      employer: "Amazon",
      insurance: "ADVENTIST HEALTH",
      phone: "(800) 888-9999",
      address: "55 Fruit Street",
      city: "Culver City",
      state: "California",
      zip: "10004",
      groupname: "10004",
      groupid: "WOFM2584DOM"
    },
    {
      employer: "Amazon",
      insurance: "ADVENTIST HEALTH",
      phone: "(800) 888-9999",
      address: "55 Fruit Street",
      city: "Culver City",
      state: "California",
      zip: "10004",
      groupname: "10004",
      groupid: "WOFM2584DOM"
    }
    // more rows...
  ]
};

export const DepositSlipService = {
  getDepositSlips: () => [
    {
      date: "11/02/2024",
      type: "Patient",
      payer: "Tiger Shroff T",
      method: "Credit Card",
      payment: "$104.08"
    },
    {
      date: "11/02/2024",
      type: "Patient",
      payer: "Tiger Shroff T",
      method: "Credit Card",
      payment: "$104.08"
    },
    {
      date: "11/02/2024",
      type: "Patient",
      payer: "Tiger Shroff T",
      method: "Credit Card",
      payment: "$104.08"
    },
    {
      date: "11/02/2024",
      type: "Patient",
      payer: "Tiger Shroff T",
      method: "Check",
      payment: "$104.08"
    }
    // more rows...
  ]
};

export const TextEmailMarketingService = {
  getTextEmailMarketing: () => [
    {
      group: "ABC",
      note: "-",
      emailpatient: "2",
      mobilepatient: "65"
    },
    {
      group: "Accepting DentiCal Update TEST",
      note: "-",
      emailpatient: "149",
      mobilepatient: "65"
    },
    {
      group: "Active patients Only",
      note: "-",
      emailpatient: "24",
      mobilepatient: "7"
    },
    {
      group: "Male Patients on Aetna",
      note: "-",
      emailpatient: "24",
      mobilepatient: "7"
    }
    // more rows...
  ]
};

export const InternalPatientReviewService = {
  getInternalPatientReviews: () => [
    {
      date: "11/02/2024",
      time: "05:18 AM",
      clinic: "Smiles Clinic",
      clinician: "Johnny SmithR",
      visitdate: "11/02/2024",
      visittime: "08:00 AM",
      treatment: "A2, C3",
      patient: "Larry Dagley",
      rating: "Happy",
      comment: "Reviewed on Social Media"
    },
    {
      date: "11/02/2024",
      time: "05:18 AM",
      clinic: "Smiles Clinic",
      clinician: "Johnny SmithR",
      visitdate: "11/02/2024",
      visittime: "08:00 AM",
      treatment: "A2, C3",
      patient: "Larry Dagley",
      rating: "Happy",
      comment: "Reviewed on Social Media"
    },
    {
      date: "11/02/2024",
      time: "05:18 AM",
      clinic: "Smiles Clinic",
      clinician: "Johnny SmithR",
      visitdate: "11/02/2024",
      visittime: "08:00 AM",
      treatment: "A2, C3",
      patient: "Larry Dagley",
      rating: "Happy",
      comment: "Reviewed on Social Media"
    },
    {
      date: "11/02/2024",
      time: "05:18 AM",
      clinic: "Smiles Clinic",
      clinician: "Johnny SmithR",
      visitdate: "11/02/2024",
      visittime: "08:00 AM",
      treatment: "A2, C3",
      patient: "Larry Dagley",
      rating: "Happy",
      comment: "Reviewed on Social Media"
    }
    // more rows...
  ]
};

export const AutomatedChargeService = {
  getAutomatedCharges: () => [
    {
      name: "Portman",
      patientid: "160840",
      type: "Membership",
      clinic: "Smiles Clinic",
      financing: "$90.00",
      datecharge: "10/12/2024",
      timecharge: "06:00 AM",
      chargeamt: "$35.00",
      status: "Success",
      error: "-"
    },
    {
      name: "Neerav",
      patientid: "160840",
      type: "Membership",
      clinic: "Smiles Clinic",
      financing: "$90.00",
      datecharge: "10/12/2024",
      timecharge: "06:00 AM",
      chargeamt: "$35.00",
      status: "Success",
      error: "-"
    },
    {
      name: "Portman",
      patientid: "160840",
      type: "Membership",
      clinic: "Smiles Clinic",
      financing: "$90.00",
      datecharge: "10/12/2024",
      timecharge: "06:00 AM",
      chargeamt: "$35.00",
      status: "Success",
      error: "-"
    },
    {
      name: "Neerav",
      patientid: "160840",
      type: "Membership",
      clinic: "Smiles Clinic",
      financing: "$90.00",
      datecharge: "10/12/2024",
      timecharge: "06:00 AM",
      chargeamt: "$35.00",
      status: "Success",
      error: "-"
    }
    // more rows...
  ]
};

export const ConnectionService = {
  getConnections: () => [
    {
      name: "James Dude",
      address: "1200 Brickell Bay Drive",
      city: "Test",
      state: "Kentucky",
      zip: "49090",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@myway.com",
      comment: "",
      group: "Medical"
    },
    {
      name: "James Dude",
      address: "05240 County Road 689",
      city: "South Haven",
      state: "Michigan",
      zip: "48917",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@myway.com",
      comment: "",
      group: "Vendors"
    },
    {
      name: "James Dude",
      address: "05240 County Road 689",
      city: "Lansing",
      state: "Michigan",
      zip: "49090",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@myway.com",
      comment: "",
      group: "Medical"
    },
    {
      name: "James Dude",
      address: "05240 County Road 689",
      city: "Test",
      state: "Kentucky",
      zip: "49090",
      phone: "(151)205-1525",
      fax: "(456)-465-4646",
      email: "vendor@myway.com",
      comment: "",
      group: "Vendors"
    }
    // more rows...
  ]
};

export const ReferralSourceService = {
  getReferralSources: () => [
    {
      name: "Neerav Shaw",
      title: "NS",
      specialty: "General",
      patient: "Yes",
      address: "1 Matrix Dr1",
      city: "Fernley",
      state: "California",
      phone: "(909)987-6532"
    },
    {
      name: "Larry Dagley",
      title: "DDS",
      specialty: "General",
      patient: "Yes",
      address: "1 Matrix Dr1",
      city: "Fernley",
      state: "California",
      phone: "(909)987-6532"
    },
    {
      name: "Neerav Shaw",
      title: "NS",
      specialty: "General",
      patient: "Yes",
      address: "1 Matrix Dr1",
      city: "Fernley",
      state: "California",
      phone: "(909)987-6532"
    },
    {
      name: "Larry Dagley",
      title: "NS",
      specialty: "General",
      patient: "Yes",
      address: "1 Matrix Dr1",
      city: "Fernley",
      state: "California",
      phone: "(909)987-6532"
    }
    // more rows...
  ]
};

export const BulkInsuranceBillingService = {
  getbulkInsuranceBilling: () => [
    {
      date: 11 / 12 / 2023,
      patient: "Neo Shaw",
      type: "Primary",
      phase: "Ins 1 pending",
      insurance: "Gaurdian Insurance",
      issue: "Yes"
    },
    {
      date: 11 / 12 / 2023,
      patient: "Neo Shaw",
      type: "Primary",
      phase: "Ins 1 pending",
      insurance: "Gaurdian Insurance",
      issue: "Yes"
    },
    {
      date: 11 / 12 / 2023,
      patient: "Neo Shaw",
      type: "Primary",
      phase: "Ins 1 pending",
      insurance: "Gaurdian Insurance",
      issue: "Yes"
    }
  ]
};

export const BulkInsurancePaymentsService = {
  getbulkInsurancePayments: () => [
    {
      date: 11 / 12 / 2023,
      method: "Neo Shaw",
      amount: "Primary",
      partial: "Ins 1 pending",
      insurance: "Gaurdian Insurance",
      comment: "Yes",
      eob: ""
    }
  ]
};

export const UserLoginStatusService = {
  getUserLoginStatus: () => [
    {
      user: "User1",
      loggedin: true,
      logInTime: "08:00 AM",
      logOutTime: "04:00 PM",
      timePunch: "8 hrs",
      ipAdderss: "192.168.1.1"
    },
    {
      user: "User2",
      loggedin: true,
      logInTime: "09:00 AM",
      logOutTime: "05:00 PM",
      timePunch: "8 hrs",
      ipAdderss: "192.168.1.2"
    },
    {
      user: "User3",
      loggedin: false,
      logInTime: "-",
      logOutTime: "-",
      timePunch: "0 hrs",
      ipAdderss: "-"
    },
    {
      user: "User4",
      loggedin: true,
      logInTime: "10:00 AM",
      logOutTime: "06:00 PM",
      timePunch: "8 hrs",
      ipAdderss: "192.168.1.4"
    },
    {
      user: "User5",
      loggedin: false,
      logInTime: "-",
      logOutTime: "-",
      timePunch: "0 hrs",
      ipAdderss: "-"
    },
    {
      user: "User6",
      loggedin: true,
      logInTime: "07:00 AM",
      logOutTime: "03:00 PM",
      timePunch: "8 hrs",
      ipAdderss: "192.168.1.6"
    },
    {
      user: "User7",
      loggedin: true,
      logInTime: "11:00 AM",
      logOutTime: "07:00 PM",
      timePunch: "8 hrs",
      ipAdderss: "192.168.1.7"
    },
    {
      user: "User8",
      loggedin: true,
      logInTime: "12:00 PM",
      logOutTime: "08:00 PM",
      timePunch: "8 hrs",
      ipAdderss: "192.168.1.8"
    },
    {
      user: "User9",
      loggedin: false,
      logInTime: "-",
      logOutTime: "-",
      timePunch: "0 hrs",
      ipAdderss: "-"
    },
    {
      user: "User10",
      loggedin: true,
      logInTime: "06:00 AM",
      logOutTime: "02:00 PM",
      timePunch: "8 hrs",
      ipAdderss: "192.168.1.10"
    }
  ]
};
