import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchAllergyMaster = createAsyncThunk(
  "AllergyMaster/getAllergies",
  async (patientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/AllergyMaster/getAllergies`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching allergy master");
    }
  }
);

export const savePatientAllergy = createAsyncThunk(
  "PatientAllergy/savepatientallergy",
  async (patientAllergyModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(`/PatientAllergy/savepatientallergy`, patientAllergyModel);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving patient allergy");
    }
  }
);

export const editPatientAllergy = createAsyncThunk(
  "PatientAllergy/manageallergy",
  async (patientAllergyModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(`/PatientAllergy/manageallergy`, patientAllergyModel);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving patient allergy");
    }
  }
);

export const getPatientAllergies = createAsyncThunk(
  "PatientAllergy/getallpatientallergy",
  async (patientId, { rejectWithValue }) => {
    try {
      return await httpClient.get(`/PatientAllergy/getallpatientallergy/${patientId}`);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching patient allergy");
    }
  }
);

export const manageAllergy = createAsyncThunk(
  "AllergyMaster/editAllergy",
  async (allergyModel, { rejectWithValue }) => {
    try {
      return await httpClient.put(`/AllergyMaster/editAllergy`, allergyModel);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving allergy");
    }
  }
);

export const addAllergy = createAsyncThunk(
  "AllergyMaster/addAllergy",
  async (allergyModel, { rejectWithValue }) => {
    try {
      return await httpClient.post(`/AllergyMaster/addAllergy`, allergyModel);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error Saving allergy");
    }
  }
);
