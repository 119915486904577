import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/atoms/Button";
import Label from "src/components/atoms/Label";
import "./BookMyVisit.css";
import InputField from "src/components/atoms/InputField";
import Dropdown from "src/components/atoms/Dropdown";

const BookMyVisit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const bookingDetails = location.state;
  const [language, setLanguage] = useState("English");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [insurance, setInsurance] = useState("No");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [subscriberName, setSubscriberName] = useState("");
  const [subscriberID, setSubscriberID] = useState("");
  const [groupNumber, setGroupNumber] = useState("");
  const [notes, setNotes] = useState("");
  const [hearAboutUs, setHearAboutUs] = useState("Others");
  const [reasonForVisit, setReasonForVisit] = useState("Regular Check Up");
  const languageOptions = ["English", "Spanish"];
  const hearAboutUsOptions = ["Others", "OS test", "OS test 2"];
  const reasonForVisitOptions = [
    "Regular Check Up",
    "Improve My Smile",
    "Pain While Chewing",
    "Redo Other Dentist's Treatment",
    "Braces",
    "Invisalign"
  ];
  const formattedDate = new Date(bookingDetails.date).toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  });

  const validateForm = () => {
    if (
      !firstName ||
      !lastName ||
      !birthdate ||
      !email ||
      !mobile ||
      !hearAboutUs ||
      !reasonForVisit ||
      (insurance === "Yes" &&
        (!insuranceCompany || !subscriberName || !subscriberID || !groupNumber))
    ) {
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleFormSubmit = () => {
    if (!validateForm()) {
      alert("Please fill all the required fields.");
    } else {
      navigate("/bookingconfirmation", {
        state: {
          firstName,
          lastName,
          birthdate,
          email,
          mobile,
          language,
          hearAboutUs,
          reasonForVisit,
          notes,
          insurance:
            insurance === "Yes"
              ? { insuranceCompany, subscriberName, subscriberID, groupNumber }
              : null,
          bookingDetails
        }
      });
    }
  };

  return (
    <div className="book-my-visit-container">
      <div className="book-my-visit-top-ribbon">
        <h3>{`${bookingDetails.selectedService} - ${formattedDate} ${bookingDetails.timeSlot}`}</h3>
        <hr />
        <section className="doctor-name-plus-button">
          <p>with {bookingDetails.doctorName}</p>
          <Button>Edit Search</Button>
        </section>
      </div>

      <div className="book-my-visit-form-container">
        <div className="book-my-visit-label-plus-comp">
          <Label>First Name: *</Label>
          <InputField value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
        </div>
        <div className="book-my-visit-label-plus-comp">
          <Label>Last Name: *</Label>
          <InputField value={lastName} onChange={(e) => setLastName(e.target.value)} required />
        </div>
      </div>

      <div className="book-my-visit-form-container">
        <div className="book-my-visit-label-plus-comp">
          <Label>Birthdate: *</Label>
          <InputField
            type="date"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
            required
          />
        </div>
        <div className="book-my-visit-label-plus-comp">
          <Label>Email: *</Label>
          <InputField value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
      </div>

      <div className="book-my-visit-form-container">
        <div className="book-my-visit-label-plus-comp">
          <Label>Mobile: *</Label>
          <InputField value={mobile} onChange={(e) => setMobile(e.target.value)} required />
        </div>
        <div className="book-my-visit-label-plus-comp">
          <Label>Do You Have Insurance?</Label>
          <div style={{ display: "flex", gap: "20px" }}>
            <Button
              onClick={() => setInsurance("Yes")}
              style={insurance === "Yes" ? { backgroundColor: "#007bff", color: "#fff" } : {}}
            >
              Yes
            </Button>
            <Button
              onClick={() => setInsurance("No")}
              style={insurance === "No" ? { backgroundColor: "#007bff", color: "#fff" } : {}}
            >
              No
            </Button>
          </div>
        </div>
      </div>

      {insurance === "Yes" && (
        <>
          <div className="book-my-visit-form-container">
            <div className="book-my-visit-label-plus-comp">
              <Label>Insurance Company:</Label>
              <InputField
                value={insuranceCompany}
                onChange={(e) => setInsuranceCompany(e.target.value)}
                required
              />
            </div>
            <div className="book-my-visit-label-plus-comp">
              <Label>Subscriber Name:</Label>
              <InputField
                value={subscriberName}
                onChange={(e) => setSubscriberName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="book-my-visit-form-container">
            <div className="book-my-visit-label-plus-comp">
              <Label>Subscriber ID:</Label>
              <InputField
                value={subscriberID}
                onChange={(e) => setSubscriberID(e.target.value)}
                required
              />
            </div>
            <div className="book-my-visit-label-plus-comp">
              <Label>Group Number:</Label>
              <InputField
                value={groupNumber}
                onChange={(e) => setGroupNumber(e.target.value)}
                required
              />
            </div>
          </div>
        </>
      )}

      <div className="book-my-visit-form-container">
        <div className="book-my-visit-label-plus-comp">
          <Label>Language: *</Label>
          <Dropdown
            style={{ width: "300px" }}
            options={languageOptions}
            value={language}
            onChange={setLanguage}
          />
        </div>
        <div className="book-my-visit-label-plus-comp">
          <Label>How did you hear about us? *</Label>
          <Dropdown
            style={{ width: "300px" }}
            options={hearAboutUsOptions}
            value={hearAboutUs}
            onChange={setHearAboutUs}
          />
        </div>
      </div>

      <div className="book-my-visit-form">
        <Label>Reason(s) for Your Visit: *</Label>
        <Dropdown
          style={{ width: "300px" }}
          options={reasonForVisitOptions}
          value={reasonForVisit}
          onChange={setReasonForVisit}
        />
      </div>

      <div className="book-my-visit-form">
        <Label>Notes to Dental Office:</Label>
        <InputField value={notes} onChange={(e) => setNotes(e.target.value)} />
      </div>

      <div className="book-my-visit-buttons">
        <Button type="button" onClick={() => navigate("/onlinescheduling")}>
          Back
        </Button>
        <Button type="submit" onClick={handleFormSubmit}>
          Book My Visit
        </Button>
      </div>
    </div>
  );
};

export default BookMyVisit;
