export const clinicianModel = {
  id: 0,
  providerID: 0,
  clientID: 1,
  clinicID: 1,
  abbr: "", // Default to empty string
  itemOrder: null, // Default to null
  lName: "", // Last name default to empty string
  fName: null, // First name default to null
  mi: null, // Middle initial default to null
  suffix: null, // Suffix default to null
  feeSched: 1, // Default to 1
  specialty: 222, // Default specialty ID
  ssn: "", // Default SSN as empty string
  stateLicense: "", // Default state license as empty string
  deaNum: null, // DEA number default to null
  isSecondary: null, // Secondary flag default to null
  provColor: "#000000", // Default color code
  isHidden: false, // Default to false
  usingTIN: null, // TIN usage default to null
  blueCrossID: null, // Blue Cross ID default to null
  sigOnFile: null, // Signature on file default to null
  medicaidID: null, // Medicaid ID default to null
  outlineColor: null, // Outline color default to null
  schoolClassNum: null, // School class number default to null
  nationalProvID: "", // Default National Provider ID as empty string
  canadianOfficeNum: "", // Default Canadian office number as empty string
  dateTStamp: "", // Default timestamp as empty string
  anesthProvType: null, // Anesthesia provider type default to null
  taxonomyCodeOverride: null, // Taxonomy code override default to null
  isCDAnet: null, // CDA Net flag default to null
  ecwID: null, // ECW ID default to null
  stateRxID: null, // State Rx ID default to null
  isNotPerson: false, // Default to true
  stateWhereLicensed: 2, // Default state where licensed ID
  emailAddressNum: null, // Email address number default to null
  isInstructor: null, // Instructor flag default to null
  ehrMuStage: null, // EHR MU stage default to null
  provNumBillingOverride: null, // Billing override number default to null
  customID: null, // Custom ID default to null
  provStatus: null, // Provider status default to null
  isHiddenReport: null, // Hidden report flag default to null
  isErxEnabled: null, // ERx enabled flag default to null
  birthdate: "0001-01-01", // Default birthdate as placeholder
  schedNote: null, // Scheduling note default to null
  webSchedDescript: null, // Web schedule description default to null
  webSchedImageLocation: null, // Web schedule image location default to null
  hourlyProdGoalAmt: null, // Hourly production goal amount default to null
  dateTerm: "0001-01-01", // Default termination date
  preferredName: null, // Preferred name default to null
  id: 0, // Default ID set to 0
  createdBy: 1, // Default created by ID
  updatedBy: 1, // Default updated by ID
  createdAt: "", // Default created timestamp
  updatedAt: "", // Default updated timestamp
  isActive: false // Default active status to true
};
