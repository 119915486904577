import AdminEmployerService from "src/services/admin/employer";
import { setEmployers, setLoading, setError } from "src/store/slices/admin/employerSlice";

export const fetchEmployers = (isActive, skip, take) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AdminEmployerService.getEmployersList(
      "/admin/employers?isActive=" + isActive + "&skip=" + skip + "&take=" + take
    );
    dispatch(setEmployers(response));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteEmployer = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await AdminEmployerService.delete(`/admin/employers/${id}`);
    // Refresh the employers list after successful deletion
    dispatch(fetchEmployers());
    return true;
  } catch (error) {
    dispatch(setError(error.message));
    return false;
  } finally {
    dispatch(setLoading(false));
  }
};

export const addEmployer = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await AdminEmployerService.post("/admin/employers", data);
    // Refresh the employers list after successful addition
    dispatch(fetchEmployers());
    return true;
  } catch (error) {
    dispatch(setError(error.message));
    return false;
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateEmployer = (formData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = {
      id: formData.id,
      empName: formData.empName,
      isActive: formData.isActive
    };
    await AdminEmployerService.put(`/admin/employers/${formData.id}`, data);
    // Refresh the employers list after successful update
    dispatch(fetchEmployers());
    return true;
  } catch (error) {
    dispatch(setError(error.message));
    return false;
  } finally {
    dispatch(setLoading(false));
  }
};
