import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const addPatientProcedure = createAsyncThunk(
  "patient/addPatientProcedure",
  async (patientProcedure) => {
    try {
      const response = await httpClient.post(
        `/PatientProcedure/addPatientProcedure`,
        patientProcedure
      ); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error add procedure");
    }
  }
);

export const deletePatientProcedure = createAsyncThunk(
  "patient/deletePatientProcedure",
  async (procedureId) => {
    try {
      const response = await httpClient.delete(`/PatientProcedure/${procedureId}`); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error deleting procedure");
    }
  }
);
