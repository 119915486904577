import React, { useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlans } from "src/store/actions/admin/insuranceActions";
import { setPlansStatus } from "src/store/slices/admin/insuranceSlice";

const AdminInsurancePlans = () => {
  const dispatch = useDispatch();
  const { plans, loading, plansStatusIsActive } = useSelector((state) => state.admin.insurance);

  useEffect(() => {
    if (!plans?.length) {
      dispatch(fetchPlans(plansStatusIsActive));
    }
  }, [dispatch, plans]);

  const columns = [
    { field: "employerName", title: "Employer" },
    { field: "insuranceCompany.insuranceCompanyName", title: "Insurance" },
    { field: "insuranceCompany.phone", title: "Phone" },
    { field: "insuranceCompany.address", title: "Address" },
    { field: "insuranceCompany.city", title: "City" },
    { field: "state", title: "State" },
    { field: "insuranceCompany.zip", title: "Zip" },
    { field: "groupname", title: "Group Name" },
    { field: "groupid", title: "Group ID" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    }
  ];
  const onStatusChange = (status) => {
    const isActive = status === "Active";
    dispatch(setPlansStatus(isActive));
    dispatch(fetchPlans(isActive));
  };

  return (
    <DatatableLayout
      title="Insurance Plans"
      statusOptions={["Active", "Inactive"]}
      status={plansStatusIsActive === true ? "Active" : "Inactive"}
      onStatusChange={onStatusChange}
      extraBtnLabel={["Merge"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={plans}
        allowSort={false}
        allowFilter={false}
        actions={actions}
        loading={loading}
        pageable={true}
        pageSize={10}
      />
    </DatatableLayout>
  );
};

export default AdminInsurancePlans;
