import React from "react";
import DynamicGrid from "../../../organisms/DynamicGrid";

const PatientVisitFollowupGrid = ({ gridRef, data = [] }) => {
  // Prepare rows by transforming the input data structure
  const rows = data?.map((item, index) => ({
    id: index + 1,
    date: item.date ? item.date.split("T")[0] : "-",
    time: item.time || "-",
    mode: item.mode || "-",
    sender: item.sender || "-",
    conversation: item.conversation || "No Conversation"
  }));
  // Dynamic columns configuration for the parent grid
  const parentColumns = [
    { field: "date", title: "Date", width: "100px" },
    { field: "time", title: "Time", width: "50px", sortable: true, filterable: true },
    { field: "mode", title: "Mode", width: "50px", sortable: true, filterable: true },
    { field: "sender", title: "Sender", width: "50px", sortable: true, filterable: false },
    {
      field: "conversation",
      title: "Conversation",
      width: "350px",
      sortable: true,
      filterable: true
    }
  ];

  // Dynamic rows data including visit data for each patient

  // Custom action buttons for the action column
  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => openPopup("editPatientVisit")
    },
    {
      title: "Delete",
      icon: "delete",
      onClick: (dataItem) => alert(`Delete `)
    }
  ];

  // Use DynamicGrid as both parent and detail grid
  return data ? (
    <div
      style={{
        width: "100%", // Full width of the container
        height: "300px", // Set a fixed height
        overflowY: "auto", // Ensure vertical scrolling
        padding: "8px" // Optional: Add padding for spacing
      }}
    >
      <DynamicGrid
        ref={gridRef}
        columns={parentColumns}
        rows={rows}
        actions={actions}
        allowExpand={true} // Enable row expand/collapse
        allowSort={true} // Enable sorting
      />
    </div>
  ) : (
    ""
  );
};

export default PatientVisitFollowupGrid;
