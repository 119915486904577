import React, { useState, useEffect } from "react";
import DatatableLayout from "../../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../../organisms/DynamicGrid";
import { useSelector, useDispatch } from "react-redux";
import { fetchMedicationMaster } from "src/store/actions/medicationAction";
import { selectMedicationMaster } from "src/store/slices/medicationSlice";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import ManageMedication from "./ManageMedication";

const AdminMedications = () => {
  const dispatch = useDispatch();
  const masterMedication = useSelector((state) => selectMedicationMaster(state));
  const { popups, openPopup, closePopup } = usePopupVisibility(); // Using the hook for managing popups
  const [editMedication, setEditMedication] = useState(null);

  useEffect(() => {
    dispatch(fetchMedicationMaster());
  }, [dispatch, fetchMedicationMaster]);

  const columns = [
    { field: "medName", title: "Generic Drug", width: "250px" },
    { field: "note", title: "Generic Note", width: "250px" }
  ];

  const saveEditMedicationPopup = () => {
    closePopup("editMedication");
  };

  const openEditMedication = (editMedication) => {
    setEditMedication(editMedication);
    openPopup("editMedication");
  };

  const handleDelete = (dataItem) => {
    console.log(dataItem);
  };

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => openEditMedication(dataItem)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => handleDelete(dataItem)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Medication"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={openEditMedication}
      addBtnLabel="Brand"
      extraBtnLabel={["Generic Drug"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={masterMedication}
        allowSort={false}
        allowFilter={false}
        actions={actions}
        pageSize={15}
        pageable={true}
      />
      {popups.editMedication && (
        <ManageMedication
          show={popups.editMedication}
          onClose={() => closePopup("editMedication")}
          mode={editMedication.medName ? "edit" : "add"}
          initialData={editMedication || {}}
          onSubmit={saveEditMedicationPopup}
        />
      )}
    </DatatableLayout>
  );
};

export default AdminMedications;
