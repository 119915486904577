import { createSlice } from "@reduxjs/toolkit";
import { fetchProvidersByClientId } from "../actions/providerAction";

const providerSlice = createSlice({
  name: "provider",
  initialState: {
    providersByClientId: null,
    loading: {
      providersByClientId: false
    },
    error: {
      providersByClientId: null
    }
  },

  reducers: {
    setProvidersByClientId(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProvidersByClientId.pending, (state) => {
        state.loading.providersByClientId = true;
        state.error.providersByClientId = null;
      })
      .addCase(fetchProvidersByClientId.fulfilled, (state, action) => {
        state.loading.providersByClientId = false;
        state.providersByClientId = action.payload;
      })
      .addCase(fetchProvidersByClientId.rejected, (state, action) => {
        state.loading.providersByClientId = false;
        state.error.providersByClientId = action.payload;
      });
  }
});

export const selectProvidersByClientId = (state) => state.provider.providersByClientId;
export const selectProvidersByClientIdLoading = (state) => state.visit.loading;
export const selectProvidersByClientIdError = (state) => state.visit.error;
export const { setProvidersByClientId } = providerSlice.actions;
export default providerSlice.reducer;
