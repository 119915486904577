import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Icon } from "@progress/kendo-react-common";
import "./SettingsAuditLog.css";
import ActionButton from "src/components/atoms/ActionButton";
import Label from "src/components/atoms/Label";
import Dropdown from "src/components/atoms/Dropdown";
import { SettingsService } from "src/services/SettingsService";

const SettingsAuditLog = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [user, setUser] = useState("All");
  const [clinic, setClinics] = useState("Smile Clinic");
  const [patient, setPatients] = useState("Patient1");
  const [auditLogs, setAuditLogs] = useState([]);

  const users = ["All", "User1", "User2", "User3"];
  const clinics = ["Smile Clinic", "Bright Clinic", "Health Clinic"];
  const patients = ["Patient1", "Patient2", "Patient3"];
  const columns = [
    { field: "Date", title: "Date" },
    { field: "Time", title: "Time" },
    { field: "patientName", title: "Patient" },
    { field: "userName", title: "User" },
    { field: "action", title: "Action" },
    { field: "logText", title: "Description" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await SettingsService.fetchAuditLogs();
        const transformedData = data.map((log) => {
          const [date, time] = log.logDateTime.split("T");
          return {
            ...log,
            Date: date,
            Time: time.split(".")[0],
            action: ""
          };
        });
        console.log(transformedData, "transformed");
        setAuditLogs(transformedData);
      } catch (error) {
        console.error("Error fetching audit logs:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <DatatableLayout
      title="Audit Logs"
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Print"
    >
      <div className="audit-log-criteria">
        <Label style={{ color: "#FFFFFF", marginRight: "8px", fontSize: "14px" }}>Dates:</Label>

        <DatePicker
          value={startDate}
          onChange={(e) => setStartDate(e.value)}
          style={{ marginRight: "8px", width: "150px !important" }}
        />
        <DatePicker
          value={endDate}
          onChange={(e) => setEndDate(e.value)}
          style={{ marginRight: "16px", width: "150px !important" }}
        />

        <div className="label-plus-dropdown-audit">
          <Label>User:</Label>
          <Dropdown options={users} onChange={setUser} value={user} />
        </div>

        <div className="label-plus-dropdown-audit">
          <Label>Clinic:</Label>
          <Dropdown options={clinics} onChange={setClinics} value={clinic} />
        </div>

        <div className="label-plus-dropdown-audit">
          <Label>Patient:</Label>
          <Dropdown options={patients} onChange={setPatients} value={patient} />
        </div>

        <Icon name="user" style={{ color: "#22A7F0", fontSize: "24px", marginRight: "8px" }} />
        <Icon name="group" style={{ color: "#22A7F0", fontSize: "24px", marginRight: "16px" }} />

        <ActionButton icon="reload" style={{ backgroundColor: "#A3C755", color: "#000000" }} />
      </div>
      <DynamicGrid columns={columns} rows={auditLogs} allowSort={false} allowFilter={false} />
    </DatatableLayout>
  );
};

export default SettingsAuditLog;
