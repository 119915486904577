import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";

export const fetchProvidersByClientId = createAsyncThunk(
  "patient/fetchProvidersByClientId",
  async (clientId, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(`/Provider/getproviderbyclientid/${clientId}`); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching Provider details");
    }
  }
);
