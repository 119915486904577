import React from "react";
import Button from "../atoms/Button";
import { Icon } from "@progress/kendo-react-common";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import "./SearchPatientGrid.css";
import { getGenderLabel } from "src/utils/genderUtils";

const SearchPatientGrid = ({
  patientData,
  handleIconClick,
  handleOnClick,
  handleOnClickFamily,
  showGrid
}) => {
  const navigate = useNavigate();
  const handleProfileClick = (patientId) => {
    // Redirect to the patient page with the patient ID as a parameter
    navigate(`/patient/${patientId}`);
    handleIconClick(`/patient/${patientId}`);
  };

  return (
    <div className="search-and-grid">
      <div className="grid-container">
        <Grid data={patientData} className="custom-grid">
          <GridColumn
            field="FullName"
            title="Patient Name"
            cell={(props) => {
              const { firstName, middleName, lastName } = props.dataItem;
              // Concatenate FirstName, MiddleName, and LastName to display the full name
              const fullName = `${firstName} ${middleName ? middleName + " " : ""}${lastName}`;
              return <td>{fullName}</td>;
            }}
          />
          <GridColumn
            field="gender"
            title="gender"
            cell={(props) => {
              // Concatenate FirstName, MiddleName, and LastName to display the full name
              const gender = getGenderLabel(props.dataItem.gender);
              return <td>{gender}</td>;
            }}
          />
          <GridColumn field="mrn" title="Patient ID" />
          <GridColumn
            field="status"
            title="Status"
            cell={(props) => {
              // Concatenate FirstName, MiddleName, and LastName to display the full name
              const gender = getGenderLabel(props.dataItem.status);
              return <td>{gender ? "Active" : "Inactive"}</td>;
            }}
          />
          <GridColumn field="birthdate" title="Birthday" />
          <GridColumn field="mobile" title="Phone" />
          <GridColumn
            title="Go To"
            cell={(props) => {
              const patientId = props.dataItem.id; // Assuming MRN is the unique ID for the patient
              return (
                <td>
                  <Icon
                    name="user"
                    className="k-icon"
                    onClick={() => handleProfileClick(patientId)} // Attach the onClick handler to the user icon
                    style={{ cursor: "pointer" }} // Optional: style the icon to indicate it is clickable
                  />
                  <Icon name="cart" className="k-icon" />
                  <Icon name="file-pdf" className="k-icon" />
                </td>
              );
            }}
          />
        </Grid>
      </div>
      <div className="actions-container">
        <Button className="add-patient-button" onClick={handleOnClick}>
          + Add Patient
        </Button>
        <Button className="add-family-button" onClick={handleOnClickFamily}>
          + Add Family
        </Button>

        <div className="checkbox-group">
          <Checkbox label="Referred Office" />
          <Checkbox label="Active Only" />
          <Checkbox label="Duplicate" />
        </div>
        <Icon name="close" className="k-icon" onClick={() => showGrid(false)} />
      </div>
    </div>
  );
};

export default SearchPatientGrid;
