export const FamilyHealthModel = {
  requestType: 1, // 1 for Add, 2 for Edit
  patientFamilyHealth: {
    // patientFamilyHealthId: null, // Unique identifier for the record
    familyMember: "", // Family member name (e.g., "Mother", "Father")
    relationShip: 0, // 0 -> Parent, 1 -> Sibling
    relationShipName: "Parent", // Display name for the relationship
    diseaseDefId: null, // ID for the health issue
    diseaseName: "", // Name of the health issue
    isActive: true, // Record status
    patientId: null, // ID of the patient
    clientId: null, // ID of the client (clinic)
    createdBy: null, // ID of the user creating the record
    updatedBy: null, // ID of the user updating the record
    createdAt: null, // Timestamp of record creation
    updatedAt: null // Timestamp of record update
  }
};
