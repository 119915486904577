export const PatientDisease = {
  masterIds: [],
  patientId: 0,
  clientId: 0,
  createdBy: 0
};

export const EditPatientDisease = {
  requestType: 1, // 1 for Add, 2 for Edit
  patientDisease: {
    id: 0,
    createdBy: 1,
    updatedBy: 0,
    createdAt: "",
    updatedAt: "",
    isActive: true,
    patientDiseaseId: 0,
    clientId: 4,
    patientId: 0,
    diseaseDefId: 0,
    diseaseName: "",
    patNote: "",
    probStatus: 0,
    dateStart: "",
    dateStop: "",
    recordStatus: true
  }
};

export const AddEditDisease = {
  id: 0,
  createdBy: 1,
  updatedBy: 0,
  createdAt: null,
  updatedAt: null,
  isActive: true,
  diseaseId: 0,
  diseaseName: "",
  showOnForms: true
};
