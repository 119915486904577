import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; // Import Redux hooks
import DynamicGrid from "src/components/organisms/DynamicGrid";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import ActionButton from "src/components/atoms/ActionButton";
import ClinicianPopup from "./SettingsCliniciansAddEdit"; // Import the ClinicianPopup component
import { fetchClinicians } from "src/store/actions/clinicianActions"; // Import fetchCliniciansApi action
import "./SettingsClinicians.css";
const clientId = 1;
const SettingsClinicians = () => {
  const dispatch = useDispatch();
  const clinicians = useSelector((state) => state.clinicians?.clinicians) || []; // Access clinicians data from Redux
  console.log("clinicians", clinicians);
  const [status, setStatus] = useState("Active");
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [mode, setMode] = useState("add"); // Mode to check if it's add or edit
  const [initialData, setInitialData] = useState({}); // Data for editing, empty for add

  // Fetch clinicians data when the component mounts
  useEffect(() => {
    console.log("here");
    dispatch(fetchClinicians(clientId)).then((x) => {
      console.log("fetchClinicians", x);
    });
  }, [dispatch]);

  // Open the "Add" clinician popup
  const handleAddClinician = () => {
    setMode("add");
    setInitialData({}); // Clear initial data for adding
    setShowPopup(true); // Show the popup
  };

  // Open the "Edit" clinician popup with data
  const handleEditClinician = (clinicianData) => {
    setMode("edit");
    setInitialData(clinicianData); // Set the data to edit
    setShowPopup(true); // Show the popup
  };

  // Handle saving clinician data
  const handleSaveClinician = (payload) => {
    console.log("Saved Clinician:", payload); // Save the clinician data here (API call, etc.)
    setInitialData({}); // Set the data to edit

    setShowPopup(false); // Close the popup after saving
  };

  const cliniciansColumns = [
    { field: "clinician", title: "Clinician" },
    { field: "initials", title: "Initials" },
    { field: "username", title: "Username" },
    { field: "dentalPatients", title: "Dental Patients" },
    { field: "hygienePatients", title: "Hygiene Patients" },
    { field: "mobilePhone", title: "Mobile" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => handleEditClinician(dataItem) // Open edit popup
    }
  ];

  return (
    <div>
      <DatatableLayout
        title="Clinicians"
        statusOptions={["Active", "Inactive"]}
        status={status}
        onStatusChange={setStatus}
        onAdd={handleAddClinician} // Open the "Add Clinician" popup
        addBtnLabel="Add Clinician"
      >
        <DynamicGrid
          columns={cliniciansColumns}
          rows={clinicians} // Use the clinicians data fetched from Redux
          actions={actions}
          allowSort={false}
          allowFilter={false}
          onRowEdit={handleEditClinician} // Open the "Edit Clinician" popup when editing
          pageSize={5}
          pageable={true}
        />

        <div className="actions">
          <label>Transfer Patients to: </label>
          <select className="transfer-select">
            <option value="none">None</option>
            {/* Add more options if needed */}
          </select>
          <ActionButton className="for-dental" primary>
            For Dental
          </ActionButton>
          <ActionButton className="for-hygiene" primary>
            For Hygiene
          </ActionButton>
        </div>
      </DatatableLayout>

      {/* Clinician Popup (for Add/Edit clinician) */}
      <ClinicianPopup
        show={showPopup}
        onClose={() => setShowPopup(false)} // Close the popup
        mode={mode} // "add" or "edit"
        initialData={initialData} // Pass initial data for editing
        onSave={handleSaveClinician} // Save handler
      />
    </div>
  );
};

export default SettingsClinicians;
