import { createSlice } from "@reduxjs/toolkit";
import { fetchProcedureCodeList, fetchProcedureCodeBySearch } from "../actions/procedureCodeAction";

const procedureCodeSlice = createSlice({
  name: "procedureCode",
  initialState: {
    procedureCodeList: null,
    procedureCodeBySearch: [],
    loading: {
      procedureCodeList: false,
      procedureCodeBySearch: false
    },
    error: {
      procedureCodeList: null,
      procedureCodeBySearch: null
    }
  },

  reducers: {
    setProcedureCodeList(state, action) {
      state.data = action.payload;
    },
    setProcedureCodeBySearch(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProcedureCodeList.pending, (state) => {
        state.loading.procedureCodeList = true;
        state.error.procedureCodeList = null;
      })
      .addCase(fetchProcedureCodeList.fulfilled, (state, action) => {
        state.loading.procedureCodeList = false;
        state.procedureCodeList = action.payload;
      })
      .addCase(fetchProcedureCodeList.rejected, (state, action) => {
        state.loading.procedureCodeList = false;
        state.error.procedureCodeList = action.payload;
      });
    builder
      .addCase(fetchProcedureCodeBySearch.pending, (state) => {
        state.loading.procedureCodeBySearch = true;
        state.error.procedureCodeBySearch = null;
      })
      .addCase(fetchProcedureCodeBySearch.fulfilled, (state, action) => {
        state.loading.procedureCodeBySearch = false;
        state.procedureCodeBySearch = action.payload;
      })
      .addCase(fetchProcedureCodeBySearch.rejected, (state, action) => {
        state.loading.procedureCodeBySearch = false;
        state.error.procedureCodeBySearch = action.payload;
      });
  }
});

export const selectProcedureCodeList = (state) => state.procedureCode.procedureCodeList;
export const selectProcedureCodeListLoading = (state) => state.procedureCode.loading;
export const selectProcedureCodeListError = (state) => state.procedureCode.error;
export const selectProcedureCodeBySearch = (state) => state.procedureCode.procedureCodeBySearch;
export const selectProcedureCodeBySearchLoading = (state) => state.procedureCode.loading;
export const selectProcedureCodeBySearchError = (state) => state.procedureCode.error;
export const { setProcedureCodeList, setProcedureCodeBySearch } = procedureCodeSlice.actions;
export default procedureCodeSlice.reducer;
