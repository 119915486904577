export const Staff = {
  clientId: 1,
  clinicId: 1,
  clockStatus: null,
  createdAt: "2025-01-02T08:21:48.207",
  createdBy: 1,
  employeeId: 3103,
  employeeNote: "Test",
  fname: "Suprakash",
  id: 3103,
  isActive: true,
  lname: "Maity",
  middleName: null,
  mobilePhone: 8195088849,
  payrollId: null,
  phoneExt: null,
  photoPath:
    "https://practicecloud.blob.core.windows.net/upload-thumb/1/1/f438ec4d-d37c-4319-b975-55655a96cddc/thumb-300x300.jpg",
  timePunch: false,
  updatedAt: "2025-01-02T08:21:50.717",
  updatedBy: null,
  userId: 0
};
