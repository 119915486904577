import React, { useState } from "react";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels
} from "@progress/kendo-react-charts";
import { data } from "./ChartSampleData";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "@progress/kendo-theme-default/dist/all.css";
import "./Insurance.css"; // Link to the CSS file
import { Icon } from "@progress/kendo-react-common";

// PlanDetails Component for reusable rendering of details
const PlanDetails = ({ details }) => (
  <div className="plan-column">
    {details.map((item, index) => (
      <div key={index} className="insurance-details-container">
        <p className="insurance-key">{item.key}:</p>
        <p className="insurance-value">{item.value || "Not Available"}</p>
      </div>
    ))}
  </div>
);

const Insurance = ({ insurance }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const labelContent = (e) => e.category;

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  // Insurance Details (data-driven approach)
  const insuranceDetails = [
    {
      key: "Subscriber",
      value: `${insurance?.subscriberFirstName || ""} ${insurance?.subscriberLastName || ""}`
    },
    { key: "Subscriber ID", value: insurance?.subscriberId },
    { key: "Employer", value: insurance?.employer },
    { key: "Group ID", value: insurance?.groupId },
    { key: "Insurance", value: insurance?.insuranceName },
    { key: "Period", value: insurance?.period },
    { key: "Group Name", value: insurance?.groupName }
  ];

  const additionalDetails = [
    { key: "Relation", value: insurance?.relation },
    { key: "Coverage", value: insurance?.coverage },
    { key: "Fees", value: insurance?.fees },
    { key: "Insurance Phone", value: insurance?.insurancePhone },
    { key: "Plan Info", value: insurance?.plan }
  ];

  return (
    <div className="insurance">
      <div className="insurance-title">
        <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
          <TabStripTab title="First" />
          <TabStripTab title="Second" />
          <TabStripTab title="Other" />
        </TabStrip>
        <div className="tab-right-content">
          <span>Eligibility Checked 05/07/2024</span>
          <span className="tab-icons">
            <Icon name="file" />
            <Icon name="check" />
            <Icon name="user" />
          </span>
        </div>
      </div>
      <div>
        {selectedTab === 0 && (
          <div className="insurance-container">
            {/* Plan Details */}
            <div className="plan-details">
              <PlanDetails details={insuranceDetails} />
              <PlanDetails details={additionalDetails} />
            </div>
            <div className="chart-container">
              <div>
                <h3>Annual Coverage</h3>
                <Chart>
                  <ChartSeries>
                    <ChartSeriesItem type="donut" data={data} categoryField="kind" field="share">
                      <ChartSeriesLabels color="#fff" background="none" content={labelContent} />
                    </ChartSeriesItem>
                  </ChartSeries>
                  <ChartLegend visible={false} />
                </Chart>
              </div>
              <div>
                <h3>Annual Deductible</h3>
                <Chart>
                  <ChartSeries>
                    <ChartSeriesItem type="donut" data={data} categoryField="kind" field="share">
                      <ChartSeriesLabels color="#fff" background="none" content={labelContent} />
                    </ChartSeriesItem>
                  </ChartSeries>
                  <ChartLegend visible={false} />
                </Chart>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Insurance;
