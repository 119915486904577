import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import { fetchEmployers, deleteEmployer } from "src/store/actions/admin/employerActions";
import { setEmployerStatus } from "src/store/slices/admin/employerSlice";
import AdminAddEditPopup from "../AdminAddEditPopup";

const AdminEmployers = () => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState({
    skip: 0,
    take: 10
  });

  const dispatch = useDispatch();
  const [localEmployers, setLocalEmployers] = useState([]);
  const { employers, loading, employerStatusIsActive } = useSelector(
    (state) => state.admin.employer
  );

  const fetchEmployersData = useCallback(() => {
    dispatch(fetchEmployers(employerStatusIsActive, page.skip, page.take));
  }, [dispatch, employerStatusIsActive, page.skip, page.take]);

  useEffect(() => {
    fetchEmployersData();
  }, [fetchEmployersData]);

  useEffect(() => {
    if (employers?.results) {
      setLocalEmployers(employers.results);
      setTotalSize(employers.total);
    }
  }, [employers]);

  const columns = [{ field: "empName", title: "Employer Name" }];
  const formFields = [
    { field: "empName", label: "Employer Name" },
    {
      field: "isActive",
      label: "Status",
      type: "select",
      options: [
        { text: "Active", value: true },
        { text: "Inactive", value: false }
      ]
    }
  ];

  const actions = [
    {
      icon: "edit",
      title: "Edit",
      onClick: (dataItem) => {
        setShowEditPopup(true);
        setSelectedEmployer(dataItem);
      }
    },
    {
      icon: "delete",
      title: "Delete",
      onClick: (dataItem) => {
        if (confirm(`Are you sure you want to delete?`)) {
          dispatch(deleteEmployer(dataItem.id)).then(() =>
            setLocalEmployers((prev) => prev.filter((employer) => employer.id !== dataItem.id))
          );
          dispatch(fetchEmployers(employerStatusIsActive));
        }
      }
    }
  ];

  const onStatusChange = (status) => {
    const isActive = status === "Active";
    dispatch(setEmployerStatus(isActive));
    dispatch(fetchEmployers(isActive));
  };

  const handleClose = () => {
    setShowEditPopup(false);
    setSelectedEmployer(null);
  };

  const handleAdd = () => {
    setShowAddPopup(true);
  };

  return (
    <DatatableLayout
      title="Employers"
      statusOptions={["Active", "Inactive"]}
      status={employerStatusIsActive === true ? "Active" : "Inactive"}
      onStatusChange={onStatusChange}
      onAdd={handleAdd}
      addBtnLabel="Employer"
      extraBtnLabel={["Export"]}
      onExtraAction={() => console.log("Export data")}
    >
      {showEditPopup && (
        <AdminAddEditPopup
          title="Edit Employer"
          actionType="editemployer"
          show={showEditPopup}
          formFields={formFields}
          onClose={handleClose}
          data={selectedEmployer}
          onUpdate={() => {
            handleClose();
            dispatch(fetchEmployers(employerStatusIsActive));
          }}
        />
      )}

      {showAddPopup && (
        <AdminAddEditPopup
          title="Add Employer"
          actionType="addemployer"
          show={showAddPopup}
          formFields={formFields}
          onClose={() => {
            setShowAddPopup(false);
            dispatch(fetchEmployers(employerStatusIsActive));
          }}
          data={{}}
        />
      )}

      <DynamicGrid
        columns={columns}
        rows={localEmployers}
        allowSort={false}
        allowFilter={false}
        actions={actions}
        loading={loading}
        pageable={true}
        total={totalSize}
        onPageChange={(event) => {
          setPage({ skip: event.page.skip, take: event.page.take });
        }}
        skip={page.skip}
        take={page.take}
      />
    </DatatableLayout>
  );
};

export default AdminEmployers;
