import httpClient from "src/services/http-client";

export const getHoursAndViews = async () => {
  return httpClient.get("/OfficeHour/getofficehoursview/1/1");
};

export const updateHoursAndViews = async (formData) => {
  const payload = {
    requestType: 2,
    officeHoursModel: formData
  };
  return httpClient.post(`/OfficeHour/manageofficehours/`, payload);
};

export const getVisitSummaries = async () => {
  return httpClient.get("/VisitSummariesSetting/getvisitsummariessettings/1");
};

export const updateVisitSummaries = async (data) => {
  return httpClient.put(`/VisitSummariesSetting/${data.id}`, data);
};

export const getAllOpratory = async () => {
  return httpClient.get("/Operatory/getallchairsbyclinicid/1");
};

export const getAppointmentView = async (activestatus) => {
  return httpClient.get(`/AppointmentView/getappointmentview/1/1/${activestatus}`);
};

export const getAppointmentViewDetail = async (appointmentid) => {
  return httpClient.get(`/AppointmentView/getappointmentviewbyId/${appointmentid}`);
};

export const AddAppointmentView = async (data) => {
  const payload = {
    requestType: 1,
    appointmentViewModel: {
      ...data,
      isActive: !data?.inactive,
      clientId: 1,
      clinicId: 1,
      createdBy: 1,
      updatedBy: 1
    }
  };
  return httpClient.post(`/AppointmentView/manageappointmentview`, payload);
};

export const updateAppointmentView = async (data) => {
  const payload = {
    requestType: 2,
    appointmentViewModel: {
      ...data,
      isActive: !data?.inactive
    }
  };
  return httpClient.post(`/AppointmentView/manageappointmentview`, payload);
};

export const deleteAppointmentView = async (id) => {
  return httpClient.delete(`/AppointmentView/deleteappointmentview/${id}`);
};
