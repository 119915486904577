import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFamilyVisitDetails,
  fetchEditVisitDetails,
  updateVisitDetails
} from "../actions/visitAction";

const visitSlice = createSlice({
  name: "visit",
  initialState: {
    familyVisitDetails: null,
    visitEditDetails: null,
    loading: {
      familyVisitDetails: false,
      visitEditDetails: false,
      updateVisitDetails: false
    },
    error: {
      familyVisitDetails: null,
      visitEditDetails: null,
      updateVisitDetails: null
    }
  },

  reducers: {
    setFamilyVisitDetails(state, action) {
      state.data = action.payload;
    },
    setEditVisitDetails(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchFamilyVisitDetails.pending, (state) => {
        state.loading.familyVisitDetails = true;
        state.error.familyVisitDetails = null;
      })
      .addCase(fetchFamilyVisitDetails.fulfilled, (state, action) => {
        state.loading.familyVisitDetails = false;
        state.familyVisitDetails = action.payload;
      })
      .addCase(fetchFamilyVisitDetails.rejected, (state, action) => {
        state.loading.familyVisitDetails = false;
        state.error.familyVisitDetails = action.payload;
      });

    builder
      .addCase(fetchEditVisitDetails.pending, (state) => {
        state.loading.visitEditDetails = true;
        state.error.visitEditDetails = null;
      })
      .addCase(fetchEditVisitDetails.fulfilled, (state, action) => {
        state.loading.visitEditDetails = false;
        state.visitEditDetails = action.payload;
      })
      .addCase(fetchEditVisitDetails.rejected, (state, action) => {
        state.loading.visitEditDetails = false;
        state.error.visitEditDetails = action.payload;
      });

    builder
      .addCase(updateVisitDetails.pending, (state) => {
        state.loading.updateVisitDetails = true;
      })
      .addCase(updateVisitDetails.fulfilled, (state, action) => {
        state.loading.updateVisitDetails = false;
      })
      .addCase(updateVisitDetails.rejected, (state, action) => {
        state.loading.updateVisitDetails = false;
      });
  }
});

export const selectFamilyVisitDetails = (state) => state.visit.familyVisitDetails;
export const selectEditVisitDetails = (state) => state.visit.visitEditDetails;
export const selectFamilyVisitLoading = (state) => state.visit.loading;
export const selectFamilyVisitError = (state) => state.visit.error;
export const { setFamilyVisitDetails, setEditVisitDetails } = visitSlice.actions;
export default visitSlice.reducer;
