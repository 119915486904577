import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import dataReducer from "./slices/dataSlice";
import clinicReducer from "./slices/clinicSlice";
import patientReducer from "./slices/patientSlice";
import alerReducer from "./slices/alertSlice.js";
import visitReducer from "./slices/visitSlice.js";
import fastfillReducer from "./slices/fastFillSlice";
import allergyReducer from "./slices/allergySlice";
import medicationReducer from "./slices/medicationSlice";
import diseaseReducer from "./slices/diseaseSlice";
import adminStore from "./slices/admin/admin-store";
import vitalSignsSlice from "./slices/vitalSignsSlice";
import familyHealthSlice from "src/store/slices/familyHealthSlice"; // Path to your slice
import staffReducer from "./slices/staffSlice";
import providerReducer from "src/store/slices/providerSlice";
import appointmentTypeReducer from "src/store/slices/appointmentTypeSlice";
import clinicianSlice from "src/store/slices/clinicianSlice"; // Path to your slice
import appReducer from "src/store/slices/appSlice";
import procedureCodeReducer from "src/store/slices/procedureCodeSlice";
import patientProcedureReducer from "src/store/slices/patientProcedureSlice";
import feeScheduleReducer from "src/store/slices/feeScheduleSlice";
import codeDefinitionReducer from "src/store/slices/codeDefinitionSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    data: dataReducer,
    clinics: clinicReducer,
    patient: patientReducer,
    visit: visitReducer,
    provider: providerReducer,
    alerts: alerReducer,
    fastFills: fastfillReducer,
    allergies: allergyReducer,
    medications: medicationReducer,
    admin: adminStore,
    VitalSigns: vitalSignsSlice,
    familyHealth: familyHealthSlice,
    staff: staffReducer,
    appointmentType: appointmentTypeReducer,
    diseases: diseaseReducer,
    clinicians: clinicianSlice,
    procedureCode: procedureCodeReducer,
    diseases: diseaseReducer,
    patientProcedure: patientProcedureReducer,
    feeSchedule: feeScheduleReducer,
    codeDefinition: codeDefinitionReducer
  }
});

export default store;
