import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchMedicationMaster, getPatientMedications } from "../actions/medicationAction";

const medicationSlice = createSlice({
  name: "medications",
  initialState: {
    mastermedications: [],
    patientMedications: [],
    loading: {
      mastermedications: false,
      patientMedications: false
    },
    error: {
      mastermedications: [],
      patientMedications: []
    }
  },

  reducers: {
    setMedicationMaster(state, action) {
      state.data = action.payload;
    },
    setPatientMedications(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMedicationMaster.pending, (state) => {
        state.loading.mastermedications = true;
        state.error.mastermedications = [];
      })
      .addCase(fetchMedicationMaster.fulfilled, (state, action) => {
        state.loading.mastermedications = false;
        state.mastermedications = action.payload;
      })
      .addCase(fetchMedicationMaster.rejected, (state, action) => {
        state.loading.mastermedications = false;
        state.error.mastermedications = action.payload;
      })
      .addCase(getPatientMedications.pending, (state) => {
        state.loading.patientMedications = true;
        state.error.patientMedications = [];
      })
      .addCase(getPatientMedications.fulfilled, (state, action) => {
        state.loading.patientMedications = false;
        state.patientMedications = action.payload;
      })
      .addCase(getPatientMedications.rejected, (state, action) => {
        state.loading.patientMedications = false;
        state.error.patientMedications = action.payload;
      });
  }
});

export const selectMedicationMaster = (state) => state.medications.mastermedications;
export const selectMedicationMasterLoading = (state) => state.medications.loading;
export const selectMedicationMasterError = (state) => state.medications.error;
export const selectPatientMedication = (state) => state.medications.patientMedications;
export const selectPatientMedicationLoading = (state) => state.medications.loading;
export const selectPatientMedicationError = (state) => state.medications.error;
export const { setMedicationMaster, setPatientMedications } = medicationSlice.actions;
export default medicationSlice.reducer;
