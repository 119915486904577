import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import store from "./store/store";
import NotificationWrapper from "./components/organisms/NotificationWrapper/NotificationWrapper";
import AppLoading from "./components/molecules/AppLoading/AppLoading";
import App from "./App";
import "./index.css";

const msalInstance = new PublicClientApplication(msalConfig);
const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <NotificationWrapper />
        <AppLoading />
        <App />
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);
