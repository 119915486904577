import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { OfficeNewsService } from "../../../services/AdminService";

const AdminOfficeNews = () => {
  const [officeNews, setOfficeNews] = useState([]);

  useEffect(() => {
    const data = OfficeNewsService.getOfficeNews();
    setOfficeNews(data);
  }, []);

  const columns = [
    { field: "startdate", title: "Start Date" },
    { field: "enddate", title: "End Date" },
    { field: "title", title: "Title" },
    { field: "message", title: "Message" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "copy", // Delete Icon
      title: "Copy",
      onClick: (dataItem) => alert(`Copy ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout
      title="Office News"
      onAdd={() => console.log("Add new Office News")}
      addBtnLabel="Office News"
      saveButton="Save"
    >
      <DynamicGrid
        columns={columns}
        rows={officeNews}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminOfficeNews;
