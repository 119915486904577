import { createSlice } from "@reduxjs/toolkit";
import { test, saveFamilyHealth, getFamilyHealth } from "../actions/familHealthAction"; // Import your async thunks

const initialState = {
  familyHealthData: null, // To store fetched family health data
  loading: false, // For tracking the loading state of async actions
  error: null // To store any error messages if the async actions fail
};

const familyHealthSlice = createSlice({
  name: "familyHealth",
  initialState,
  reducers: {
    // You can add additional sync actions if necessary
  },
  extraReducers: (builder) => {
    builder
      // For fetching family health data

      // For saving family health data
      .addCase(saveFamilyHealth.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error state on pending
      })
      .addCase(saveFamilyHealth.fulfilled, (state, action) => {
        state.loading = false;
        // You can handle the saved data here if needed
        // e.g., update the familyHealthData with the saved version
      })
      .addCase(saveFamilyHealth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error message
      })
      // For saving family health data
      .addCase(getFamilyHealth.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error state on pending
      })
      .addCase(getFamilyHealth.fulfilled, (state, action) => {
        state.loading = false;
        state.familyHealthData = action.payload;

        // You can handle the saved data here if needed
        // e.g., update the familyHealthData with the saved version
      })
      .addCase(getFamilyHealth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error message
      });
  }
});

export const selectFamilyHealth = (state) => state.familyHealth.familyHealthData; // Selector for familyHealth data

export default familyHealthSlice.reducer;
