import React, { useState } from "react";
import DynamicForm from "../../../organisms/DynamicForm"; // Import DynamicForm component
import HealthInfo from "./Info";
import HealthIssues from "./HealthIssues";
import Medications from "./Medications";
import Allergies from "./Allergies";
import FamilyHealth from "./FamilyHealth";
import VitalSigns from "./VitalSigns";
import "./healthinfo.css";

const HealthInfoTabs = (patientId) => {
  // Define form structure with tabs and sections, including custom components
  const [formData, setFormData] = useState({});

  const formStructure = {
    title: "Health Information",
    tabs: [
      {
        name: "Health Info",
        sections: [
          {
            fields: [
              {
                label: "Pre-medication",
                name: "preMedication",
                type: "checkbox"
              },
              {
                label: "Health History",
                name: "defaultResponse",
                type: "textarea",
                rows: 3,
                placeholder: "Enter health history"
              },

              {
                label: "Health Alert",
                name: "healthAlert",
                type: "textarea",
                rows: 3,
                placeholder: "Enter health alert information"
              },

              {
                label: "Special Care",
                name: "specialCare",
                type: "textarea",
                rows: 3,
                placeholder: "Enter special care instructions"
              }
            ]
          }
        ]
      },
      {
        name: "Health Issue",
        sections: [
          {
            customSection: <HealthIssues patientId={patientId} />
          }
        ]
      },
      {
        name: "Medication",
        sections: [
          {
            customSection: <Medications patientId={patientId} /> // Custom component for Medications tab
          }
        ]
      },
      {
        name: "Allergies",
        sections: [
          {
            customSection: <Allergies patientId={patientId} /> // Custom component for Allergies tab
          }
        ]
      },
      {
        name: "Family Health",
        sections: [
          {
            customSection: <FamilyHealth /> // Custom component for Family Health tab
          }
        ]
      },
      {
        name: "Vital Signs",
        sections: [
          {
            customSection: <VitalSigns /> // Custom component for Vital Signs tab
          }
        ]
      }
    ]
  };

  // Function to handle form submission
  const handleFormSubmit = (data) => {
    console.log("Form submitted with data:", data);
  };

  return (
    <DynamicForm
      tabs={formStructure.tabs}
      handleFormSubmit={handleFormSubmit}
      formData={formData}
      setFormData={setFormData}
    />
  );
};

export default HealthInfoTabs;
