import React, { useState, useEffect } from "react";
import DatatableLayout from "../../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../../organisms/DynamicGrid";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllergyMaster } from "src/store/actions/allergyAction";
import { selectAllergyMaster } from "src/store/slices/allergySlice";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import ManageAllergy from "./ManageAllergy";

const AdminAllergies = () => {
  const dispatch = useDispatch();
  const masterAllergy = useSelector((state) => selectAllergyMaster(state));
  const { popups, openPopup, closePopup } = usePopupVisibility(); // Using the hook for managing popups
  const [editAllergy, setEditAllergy] = useState({});

  useEffect(() => {
    dispatch(fetchAllergyMaster());
  }, [dispatch, fetchAllergyMaster]);

  const columns = [
    { field: "allergyName", title: "Allergy", width: "250px" },
    { field: "showOnForms", title: "Show On Forms", width: "250px" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => openEditAllergy(dataItem)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.allergyName || dataItem.description}`)
    }
  ];

  const saveEditAllergyPopup = () => {
    closePopup("editAllergy");
  };

  const openEditAllergy = (editAllergy) => {
    setEditAllergy(editAllergy);
    openPopup("editAllergy");
  };

  return (
    <DatatableLayout
      title="Allergy"
      onAdd={openEditAllergy}
      addBtnLabel="Allergy"
      extraBtnLabel={["Show on Form", "Hide on Form"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={masterAllergy}
        allowSort={false}
        allowFilter={false}
        actions={actions}
        pageSize={15}
        pageable={true}
      />
      {popups.editAllergy && (
        <ManageAllergy
          show={popups.editAllergy}
          onClose={() => closePopup("editAllergy")}
          mode={editAllergy.allergyID ? "edit" : "add"}
          initialData={editAllergy || {}}
          onSubmit={saveEditAllergyPopup}
        />
      )}
    </DatatableLayout>
  );
};

export default AdminAllergies;
