import React, { useEffect, useState } from "react";
import DynamicForm from "src/components/organisms/DynamicForm";
import CommonPopup from "src/components/molecules/CommonPopup";
import { FamilyHealthModel } from "src/models/familyHealthModel";
import usePatient from "src/hooks/use-patient";
import { fetchDiseaseMaster } from "src/store/actions/diseaseAction";
import { selectDiseaseMaster } from "src/store/slices/diseaseSlice";
import { useDispatch, useSelector } from "react-redux";

const relationshipOptions = [
  { label: "Parent", value: 0 },
  { label: "Sibling", value: 1 }
];

const FamilyHealthPopup = ({
  show,
  onClose,
  mode = "add",
  initialData = {},
  onSave,
  clientId,
  createdBy
}) => {
  const dispatch = useDispatch();
  const patientId = usePatient()?.patientId;
  const masterDisease = useSelector((state) => selectDiseaseMaster(state)); // Get master disease data

  const [formData, setFormData] = useState({
    ...FamilyHealthModel.patientFamilyHealth,
    patientId,
    clientId,
    createdBy,
    ...initialData
  });

  // Fetch disease master data on component mount
  useEffect(() => {
    dispatch(fetchDiseaseMaster());
  }, [dispatch]);

  useEffect(() => {
    if (mode === "edit" && initialData) {
      const selectedDisease = masterDisease.find(
        (disease) => disease.diseaseName === initialData.diseaseName
      );
      setFormData({
        ...initialData,
        relationShipName:
          initialData.relationShipName ||
          relationshipOptions.find((opt) => opt.value === initialData.relationShip)?.label,
        diseaseDefId: selectedDisease ? selectedDisease.diseaseId : null, // Set diseaseDefId
        diseaseName: selectedDisease ? selectedDisease.diseaseName : "" // Set diseaseName
      });
    }
  }, [initialData, mode]);

  const handleInputChange = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = () => {
    const relName = relationshipOptions.find((opt) => opt.value === formData.relationShip)?.label;
    formData.relationShipName = relName;

    // Get selected disease from master disease list
    const selectedDisease = masterDisease.find(
      (disease) => disease.diseaseName === formData.diseaseName
    );

    // Add diseaseDefId and diseaseName to the payload
    const payload = {
      requestType: mode === "add" ? 1 : 2,
      patientFamilyHealth: {
        ...formData,
        diseaseDefId: selectedDisease ? selectedDisease.diseaseId : null, // Set diseaseDefId
        diseaseName: selectedDisease ? selectedDisease.diseaseName : "", // Set diseaseName
        patientId: patientId,
        clientId: 1,
        createdBy: 1
      }
    };

    onSave(payload);
  };

  const tabs = [
    {
      name: mode === "add" ? "Add Family Health" : "Edit Family Health",
      sections: [
        {
          fields: [
            {
              name: "relationShip",
              type: "select",
              label: "Relationship",
              options: relationshipOptions
            },
            {
              name: "familyMember",
              type: "input",
              label: "Family Member",
              value: formData.familyMember,
              placeholder: "Enter family member's name",
              required: true
            },
            {
              name: "diseaseName",
              type: "searchbox", // Change type to "searchbox"
              label: "Health Issue",
              value: formData.diseaseName,
              placeholder: "Search health issue",
              options: masterDisease.map((disease) => disease.diseaseName), // Use disease names from masterDisease
              required: true
              // onSelect: (selectedValue) => {
              //   const selectedDisease = masterDisease.find(
              //     (disease) => disease.diseaseName === selectedValue
              //   );
              //   handleInputChange(selectedDisease.diseaseName, "diseaseName"); // Store diseaseName
              // }
            }
          ]
        }
      ],
      footer: {
        right: [
          {
            type: "button",
            icon: "cancel",
            text: "Cancel",
            themeColor: "secondary",
            onClick: onClose
          },
          {
            type: "button",
            icon: "save",
            text: mode === "add" ? "Add" : "Update",
            themeColor: "primary",
            onClick: handleSave
          }
        ]
      }
    }
  ];

  return (
    <CommonPopup
      show={show}
      onClose={onClose}
      title={mode === "add" ? "Add Family Health" : "Edit Family Health"}
    >
      <DynamicForm
        tabs={tabs}
        formData={formData}
        handleInputChange={handleInputChange}
        mode={mode}
      />
    </CommonPopup>
  );
};

export default FamilyHealthPopup;
