export const PatientAllergy = {
  masterIds: [],
  patientId: 0,
  clientId: 0,
  createdBy: 0
};

export const EditPatientAllergy = {
  requestType: 1, // 1 for Add, 2 for Edit
  patientAllergy: {
    allergyMasterID: 0,
    allergyName: "",
    allergyReaction: "",
    allergySeverity: "",
    clientID: 4,
    createdAt: "",
    createdBy: 1,
    dateAdverseReaction: "",
    description: "",
    encounterID: "",
    id: 0,
    isActive: true,
    patientAllergyID: 0,
    patientID: 0,
    recordStatus: true,
    snomedReaction: "",
    updatedAt: "",
    updatedBy: null
  }
};

export const AddEditAllergy = {
  id: 0,
  createdBy: 1,
  updatedBy: 0,
  createdAt: null,
  updatedAt: null,
  isActive: true,
  allergyID: 0,
  allergyName: "",
  allergyCategory: "",
  description: "",
  showOnForms: true
};
