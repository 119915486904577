import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicGrid from "../../../../organisms/DynamicGrid";
import FormActionsBar from "../../../../organisms/FormActionBar";
import Button from "../../../../atoms/Button";
import VitalSignsPopup from "./AddEditVitalSigns"; // Import the VitalSignsPopup component
import { fetchVitalSignsByPatientId } from "../../../../../store/actions/vitalSignsActions"; // Action to fetch vitals
import { saveVitalSigns } from "../../../../../store/actions/vitalSignsActions";
import usePatient from "src/hooks/use-patient";

const VitalSigns = () => {
  const dispatch = useDispatch();
  const patientId = usePatient()?.patientId;
  const clinicId = useSelector((state) => state.clinics.selectedClinic.id || 0);
  const vitalSigns = useSelector((state) => state.VitalSigns.vitalSigns); // Fetch data from redux state
  console.log("vitalsigngs get data", vitalSigns, clinicId);
  const [showPopup, setShowPopup] = useState(false);
  const [editData, setEditData] = useState(null); // For edit mode

  // Fetch vital signs on component mount
  useEffect(() => {
    dispatch(fetchVitalSignsByPatientId(patientId));
  }, [dispatch, patientId]);

  const handleAdd = () => {
    setEditData(null); // Reset editData for add mode
    setShowPopup(true); // Show popup
  };

  const handleEdit = (dataItem) => {
    setEditData(dataItem); // Pass selected row data for edit mode
    setShowPopup(true); // Show popup
  };

  const columns = [
    { field: "height", title: "Height" },
    { field: "weight", title: "Weight" },
    { field: "bmipercentile", title: "BMI" },
    { field: "pulse", title: "Pulse (BPM)" },
    {
      field: "bloodPressure",
      title: "Blood Pressure",
      cell: (props) => `${props.dataItem.bpSystolic} / ${props.dataItem.bpDiastolic}`
    }
  ];

  const actions = [
    {
      icon: "edit",
      title: "Edit",
      onClick: (dataItem) => handleEdit(dataItem)
    }
    // {
    //   icon: "delete",
    //   title: "Delete",
    //   onClick: (dataItem) => console.log(`Implement delete logic for vitalSign ID: ${dataItem.id}`)
    // }
  ];

  return (
    <div style={{ height: "500px" }}>
      <FormActionsBar>
        <Button icon="plus" className="add-button" onClick={handleAdd}>
          Add Vital Signs
        </Button>
      </FormActionsBar>
      <DynamicGrid
        columns={columns}
        rows={vitalSigns || []} // Fetch data from redux
        key={vitalSigns?.length || 0}
        actions={actions}
        pageSize={5}
        pageable={true}
      />
      {showPopup && (
        <VitalSignsPopup
          show={showPopup}
          onClose={() => setShowPopup(false)}
          mode={editData ? "edit" : "add"}
          initialData={editData || {}}
        />
      )}
    </div>
  );
};

export default VitalSigns;
