export const VitalSignsModel = {
  requestType: 1, // 1 for Add, 2 for Edit
  patientVitalSign: {
    patientId: 0,
    clientId: 0,
    createdBy: 0,
    // dateTaken: new Date(),
    pulse: 0,
    bpSystolic: 0,
    bpDiastolic: 0,
    height: 0.0,
    weight: 0.0,
    bmiPercentile: 0.0,
    recordStatus: true
  }
};
