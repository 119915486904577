import { Checkbox } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/atoms/Button";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import FormActionsBar from "src/components/organisms/FormActionBar";
import MasterAllergy from "../../PopUps/MasterAllergy";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import { useSelector, useDispatch } from "react-redux";
import { getPatientAllergies } from "src/store/actions/allergyAction";
import { selectPatientAllergy } from "src/store/slices/allergySlice";
import ManagePatientAllergy from "./ManagePatientAllergy";

const Allergies = ({ patientId }) => {
  const { clinicId } = useParams();
  const { popups, openPopup, closePopup } = usePopupVisibility(); // Using the hook for managing popups
  const dispatch = useDispatch();
  const patientAllergy = useSelector((state) => selectPatientAllergy(state));
  const [editAllergy, setEditAllergy] = useState({});

  useEffect(() => {
    dispatch(getPatientAllergies(patientId.patientId));
  }, [patientId]);

  const columns = [
    { field: "allergyName", title: "Allergy", width: "250px" },
    { field: "allergyReaction", title: "Symptoms", width: "250px" },
    { field: "isActive", title: "Status", width: "250px" }
  ];

  const closeAllergyPopup = () => {
    closePopup("addAllergy");
    setTimeout(() => dispatch(getPatientAllergies(patientId.patientId)), 2000);
  };

  const saveEditAllergyPopup = () => {
    closePopup("editAllergy");
  };

  const openEditAllergy = (patientAllergy) => {
    setEditAllergy(patientAllergy);
    openPopup("editAllergy");
  };

  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => openEditAllergy(dataItem)
    }
  ];

  return (
    <div style={{ height: "500px" }}>
      <FormActionsBar>
        <Checkbox label={"Inactive"} />
        <Button icon="plus" themeColor="primary" onClick={() => openPopup("addAllergy")}>
          Allergies
        </Button>
      </FormActionsBar>
      <DynamicGrid
        key={patientAllergy.length}
        columns={columns}
        rows={patientAllergy}
        actions={actions}
        pageSize={5}
        pageable={true}
      />
      <div
        className="k-form-buttons"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "10px"
        }}
      >
        <Button themeColor="primary">Save</Button>
      </div>
      {popups.addAllergy && (
        <MasterAllergy show={popups.addAllergy} patientId={patientId} onClose={closeAllergyPopup} />
      )}
      {popups.editAllergy && (
        <ManagePatientAllergy
          show={popups.editAllergy}
          patientId={patientId}
          onClose={() => closePopup("editAllergy")}
          initialData={editAllergy}
          onSubmit={saveEditAllergyPopup}
        />
      )}
    </div>
  );
};

export default Allergies;
