import React, { useState } from "react";
import DynamicGrid from "../../../organisms/DynamicGrid";
import { Button } from "@progress/kendo-react-buttons";
import { getGenderLabel } from "src/utils/genderUtils";
import PatientVisitEditPopup from "./PatientVisitEdit";
import usePopupVisibility from "src/hooks/usePopupVisibility";

const PatientVisitsGrid = ({ gridRef, data = [] }) => {
  const { popups, openPopup, closePopup } = usePopupVisibility();
  const [selectedVisitId, setSelectedVisitId] = useState(null);
  // Prepare rows by transforming the input data structure
  const rows = data?.map((patient, index) => ({
    id: index + 1,
    name: `${patient.firstName} ${patient.middleName || ""} ${patient.lastName}`.trim(),
    age: `${new Date().getFullYear() - new Date(patient?.birthdate).getFullYear()}`,
    gender: `${getGenderLabel(patient?.gender)}`,
    recareDue: patient.recareDue || "-",
    schedule: patient.schedule || "-",
    visits: patient.visitViewModels?.map((visit) => ({
      date: visit.date || "-",
      time: visit.time || "-",
      chair: visit.chair || "-",
      duration: visit.duration ? `${visit.duration} min` : "-",
      status: visit.status || "-",
      clinician: visit.clinician || "-",
      treatment: visit.treatment || "No Description",
      note: visit.note || "No Notes",
      id: visit.id
    }))
  }));
  // Dynamic columns configuration for the parent grid
  const parentColumns = [
    { field: "name", title: "Family Member", width: "200px" },
    { field: "age", title: "Age", width: "50px", sortable: true, filterable: true },
    { field: "gender", title: "Gender", width: "100px", sortable: true, filterable: true },
    { field: "recareDue", title: "Recare Due", width: "100px", sortable: true, filterable: false },
    { field: "schedule", title: "Schedule", width: "150px", sortable: true, filterable: true }
  ];

  // Dynamic columns configuration for the nested grid (Detail Grid)
  const detailColumns = [
    { field: "date", title: "Date", width: "120px" },
    { field: "time", title: "Time", width: "100px" },
    { field: "chair", title: "Chair", width: "100px" },
    { field: "duration", title: "Duration", width: "120px" },
    { field: "status", title: "Status", width: "120px" },
    { field: "clinician", title: "Clinician", width: "100px" },
    { field: "treatment", title: "Treatment", width: "200px" },
    { field: "note", title: "Note", width: "200px" }
  ];

  // Dynamic rows data including visit data for each patient

  // Custom action buttons for the action column

  const detailsActions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => {
        setSelectedVisitId(dataItem.id);
        openPopup("editPatientVisit");
      }
    },
    {
      title: "Schedule",
      icon: "calendar",
      onClick: (dataItem) => alert(`Scheduling for ${dataItem.name}`)
    }
  ];

  // Use DynamicGrid as both parent and detail grid
  return data ? (
    <>
      <DynamicGrid
        ref={gridRef}
        columns={parentColumns}
        rows={rows}
        allowExpand={true} // Enable row expand/collapse
        allowSort={true} // Enable sorting
        DetailComponent={(props) => (
          <DynamicGrid
            columns={detailColumns} // Dynamic columns for detail view
            rows={props.dataItem.visits} // Pass nested visit data
            allowSort={true}
            actions={detailsActions}
            pageable={true}
            pageSize={10} // Optionally, you could pass separate actions for nested grid
          />
        )}
      />

      {popups.editPatientVisit && (
        <PatientVisitEditPopup
          show={popups.editPatientVisit}
          onClose={() => closePopup("editPatientVisit")}
          visitId={selectedVisitId}
        />
      )}
    </>
  ) : (
    ""
  );
};

export default PatientVisitsGrid;
