import React, { useEffect, useState } from "react";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Button from "src/components/atoms/Button";
import Label from "src/components/atoms/Label";
import { BulkInsurancePaymentsService } from "src/services/AdminService";
import NavbarTemplate from "src/components/templates/NavbarTemplate";

const AdminBulkInsurancePayments = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [insuranceBilling, setInsuranceBilling] = useState([]);

  useEffect(() => {
    const data = BulkInsurancePaymentsService.getbulkInsurancePayments();
    setInsuranceBilling(data);
  }, []);

  const columns = [
    { field: "date", title: "Date" },
    { field: "method", title: "Method" },
    { field: "amount", title: "Amount" },
    { field: "partial", title: "Partial" },
    { field: "insurance", title: "Insurance .Co" },
    { field: "comment", title: "Comment" },
    { field: "eob", title: "EOB" }
  ];

  const handleRefresh = () => {
    console.log("Data refreshed with dates:", { startDate, endDate });
    // Add logic to refresh the data based on selected dates
  };

  return (
    <NavbarTemplate>
      <h3>Bulk Insurance Payments</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          gap: "30px",
          marginTop: "16px"
        }}
      >
        <Label style={{ color: "white" }}>Dates: </Label>
        <DatePicker
          value={startDate}
          onChange={(e) => setStartDate(e.value)}
          placeholder="Start Date"
          style={{ marginRight: "16px", width: "200px !important" }}
        />
        <DatePicker
          value={endDate}
          onChange={(e) => setEndDate(e.value)}
          placeholder="End Date"
          style={{ marginRight: "16px" }}
        />
        <Button icon="refresh" onClick={handleRefresh} />
        <Button>DXC Portal</Button>
      </div>
      <DynamicGrid rows={insuranceBilling} columns={columns} allowSort={false} allowFilter={true} />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button icon="add" style={{ marginTop: "20px" }}>
          Insurance Payment
        </Button>
        <Button>Save</Button>
      </div>
    </NavbarTemplate>
  );
};

export default AdminBulkInsurancePayments;
