import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { AutomatedChargeService } from "../../../services/AdminService";
import { Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Button from "src/components/atoms/Button";

const AdminAutomatedCharges = () => {
  const [automatedCharges, setAutomatedCharges] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const data = AutomatedChargeService.getAutomatedCharges();
    setAutomatedCharges(data);
  }, []);

  const columns = [
    { field: "name", title: "Name" },
    { field: "patientid", title: "Patient ID" },
    { field: "type", title: "Type" },
    { field: "clinic", title: "Clinic" },
    { field: "financing", title: "Financing" },
    { field: "datecharge", title: "Date Charge" },
    { field: "timecharge", title: "Time Charge" },
    { field: "chargeamt", title: "Charge Amt" },
    { field: "status", title: "Status" },
    { field: "error", title: "Error" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "view", // Delete Icon
      title: "View",
      onClick: (dataItem) => alert(`View ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout title="Automated Charges">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          gap: "30px",
          marginTop: "16px"
        }}
      >
        <Label style={{ color: "white" }}>Dates: </Label>
        <DatePicker
          value={startDate}
          onChange={(e) => setStartDate(e.value)}
          placeholder="Start Date"
          style={{ marginRight: "16px", width: "200px !important" }}
        />
        <DatePicker
          value={endDate}
          onChange={(e) => setEndDate(e.value)}
          placeholder="End Date"
          style={{ marginRight: "16px" }}
        />
        <Button icon="refresh" />
      </div>
      <DynamicGrid
        columns={columns}
        rows={automatedCharges}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminAutomatedCharges;
