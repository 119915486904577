import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCliniciansApi,
  fetchProviderFeeApi,
  fetchSpecialtiesApi,
  manageProviderApi
} from "../../services/clinician-service";

// Fetch all clinicians

export const fetchClinicians = createAsyncThunk(
  "clinicians/fetchClinicians",
  async (clientId, { rejectWithValue }) => {
    try {
      const response = await fetchCliniciansApi(clientId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch clinicians");
    }
  }
);
// Add a new clinician
export const manageProvider = createAsyncThunk(
  "clinicians/manageProvider",
  async (clinicianData) => {
    const response = await manageProviderApi(clinicianData);
    return response.data;
  }
);

export const fetchSpecialties = createAsyncThunk(
  "clinicians/fetchSpecialties",
  async (clientId, { rejectWithValue }) => {
    try {
      const response = await fetchSpecialtiesApi();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch fetchSpecialties");
    }
  }
);

export const fetchProviderFee = createAsyncThunk(
  "clinicians/fetchProviderFee",
  async (clientId, { rejectWithValue }) => {
    try {
      const response = await fetchProviderFeeApi(clientId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch fetchProviderFee");
    }
  }
);
