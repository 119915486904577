import React, { useEffect, useState } from "react";
import DatatableLayout from "../../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../../organisms/DynamicGrid";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeByClientId, getEmployeeByClinicId } from "src/store/actions/staffAction";
import { selectEmployeesClient, selectEmployeesClinic } from "src/store/slices/staffSlice";
import useClinic from "src/hooks/use-clinic";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import ManageStaff from "./ManageStaff";

const SettingsStaff = () => {
  const [status, setStatus] = useState("Active");
  const dispatch = useDispatch();
  const { popups, openPopup, closePopup } = usePopupVisibility();
  const employeesByClient = useSelector((state) => selectEmployeesClient(state));
  const [editStaff, setEditStaff] = useState({});
  const clinic = useClinic();

  useEffect(() => {
    dispatch(getEmployeeByClientId(1));
  }, [dispatch, getEmployeeByClientId]);

  const columns = [
    { field: "lname", title: "Staff" },
    { field: "fname", title: "Username" },
    { field: "mobilePhone", title: "Mobile" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => openEditStaff(dataItem)
    }
  ];

  const saveEditStaffPopup = () => {
    closePopup("editStaff");
  };

  const openEditStaff = (editStaff) => {
    setEditStaff(editStaff);
    openPopup("editStaff");
  };

  return (
    <DatatableLayout
      title="Staff"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => openEditStaff({})}
      addBtnLabel="Staff"
    >
      <DynamicGrid
        columns={columns}
        rows={employeesByClient}
        allowSort={false}
        allowFilter={false}
        actions={actions}
        pageSize={10}
        pageable={true}
      />
      {popups.editStaff && (
        <ManageStaff
          show={popups.editStaff}
          onClose={() => closePopup("editStaff")}
          mode={editStaff.employeeId ? "edit" : "add"}
          initialData={editStaff || {}}
          onSubmit={saveEditStaffPopup}
        />
      )}
    </DatatableLayout>
  );
};

export default SettingsStaff;
