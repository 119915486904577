import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employers: [],
  loading: false,
  error: null,
  employerStatusIsActive: true
};

const employerSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    setEmployers: (state, action) => {
      state.employers = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setEmployerStatus: (state, action) => {
      state.employerStatusIsActive = action.payload;
    }
  }
});

export const { setEmployers, setLoading, setError, setEmployerStatus } = employerSlice.actions;
export default employerSlice.reducer;
