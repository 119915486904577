import { createSlice } from "@reduxjs/toolkit";
import { fetchVitalSignsByPatientId, saveVitalSigns } from "src/store/actions/vitalSignsActions";

const initialState = {
  vitalSigns: [],
  loading: false,
  error: null
};

const vitalSignsSlice = createSlice({
  name: "vitalSigns",
  initialState,
  reducers: {}, // Add synchronous reducers if needed
  extraReducers: (builder) => {
    // Fetch vital signs
    builder
      .addCase(fetchVitalSignsByPatientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVitalSignsByPatientId.fulfilled, (state, action) => {
        state.loading = false;
        state.vitalSigns = action.payload;
      })
      .addCase(fetchVitalSignsByPatientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Save vital signs
    builder
      .addCase(saveVitalSigns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveVitalSigns.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveVitalSigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default vitalSignsSlice.reducer;
