import React, { useState, useEffect } from "react";
import DatatableLayout from "../../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../../organisms/DynamicGrid";
import { useSelector, useDispatch } from "react-redux";
import { fetchDiseaseMaster } from "src/store/actions/diseaseAction";
import { selectDiseaseMaster } from "src/store/slices/diseaseSlice";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import ManageDisease from "./ManageHealthIssue";

const AdminHealthIssue = () => {
  const dispatch = useDispatch();
  const masterDisease = useSelector((state) => selectDiseaseMaster(state));
  const { popups, openPopup, closePopup } = usePopupVisibility(); // Using the hook for managing popups
  const [editDisease, setEditDisease] = useState({});

  useEffect(() => {
    dispatch(fetchDiseaseMaster());
  }, [dispatch, fetchDiseaseMaster]);

  const columns = [
    { field: "diseaseName", title: "Disease", width: "250px" },
    { field: "showOnForms", title: "Show On Forms", width: "250px" }
  ];

  const saveEditDiseasePopup = () => {
    closePopup("editDisease");
  };

  const openEditDisease = (editDisease) => {
    setEditDisease(editDisease);
    openPopup("editDisease");
  };

  const handleDelete = (dataItem) => {
    console.log(dataItem);
  };

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => openEditDisease(dataItem)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => handleDelete(dataItem)
    }
  ];
  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Health Issue"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={openEditDisease}
      addBtnLabel="Health Issue"
      extraBtnLabel={["Show on Form", "Hide on Form"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={masterDisease}
        allowSort={false}
        allowFilter={false}
        actions={actions}
        pageSize={15}
        pageable={true}
      />
      {popups.editDisease && (
        <ManageDisease
          show={popups.editDisease}
          onClose={() => closePopup("editDisease")}
          mode={editDisease.diseaseName ? "edit" : "add"}
          initialData={editDisease || {}}
          onSubmit={saveEditDiseasePopup}
        />
      )}
    </DatatableLayout>
  );
};

export default AdminHealthIssue;
