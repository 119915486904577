import { Switch } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import Button from "src/components/atoms/Button";
import CommonPopup from "src/components/molecules/CommonPopup";
import {
  getVisitSummaries,
  updateVisitSummaries
} from "src/services/settings/schedule/hours-and-views";
import { showNotification } from "src/store/slices/appSlice";
import { useDispatch } from "react-redux";

const VisitPopup = ({ show, onClose, title }) => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({});

  useEffect(() => {
    getVisitSummaries().then((response) => {
      console.log("response", response);
      if (response) {
        setFormState(response);
      }
    });
  }, []);

  const handleSwitchChange = (field) => {
    setFormState((prev) => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleSubmit = () => {
    try {
      updateVisitSummaries(formState);
      dispatch(
        showNotification({ message: "VisitSummaries updated successfully", type: "success" })
      );
    } catch (error) {
      dispatch(showNotification({ message: "An error occurred", type: "error" }));
    }

    console.log("submit", formState);
    onClose();
  };

  const formFields = [
    { label: "First Visit", field: "firstVisit" },
    { label: "Health Alert", field: "healthAlert" },
    { label: "Family", field: "family" },
    { label: "Financial", field: "financial" },
    { label: "Notes", field: "notes" },
    { label: "Account Status", field: "accountStatus" },
    { label: "Special Care", field: "specialCare" },
    { label: "Insurance", field: "insurance" },
    { label: "Diagnosed", field: "diagnosed" }
  ];

  return (
    <CommonPopup show={show} onClose={onClose} title={title} dialogProps={{ width: "800px" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1.5fr",
          gap: "20px",
          margin: "20px"
        }}
      >
        <div>
          {formFields.slice(0, 5).map(({ label, field }) => (
            <div style={{ margin: "20px", display: "flex", justifyContent: "end" }} key={field}>
              <label style={{ color: "white", marginRight: "20px" }}>{label}:</label>

              <Switch checked={formState[field]} onChange={() => handleSwitchChange(`${field}`)} />
            </div>
          ))}
        </div>
        <div>
          {formFields.slice(5).map(({ label, field }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "100px",
                marginTop: "20px"
              }}
              key={field}
            >
              <label
                style={{
                  color: "white",
                  marginRight: "20px"
                }}
              >
                {label}:
              </label>
              <Switch checked={formState[field]} onChange={() => handleSwitchChange(`${field}`)} />
            </div>
          ))}
        </div>
      </div>
      <div style={{ borderTop: "1px solid #ddd", margin: "20px 0" }}></div>
      <div style={{ textAlign: "right", margin: "20px" }}>
        <Button onClick={handleSubmit} type="submit" themeColor="primary">
          Submit
        </Button>
      </div>
    </CommonPopup>
  );
};
export default VisitPopup;
