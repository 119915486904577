import { createSlice } from "@reduxjs/toolkit";
import { addPatientProcedure, deletePatientProcedure } from "../actions/patientProcedureAction";

const patientProcedureSlice = createSlice({
  name: "patientProcedure",
  initialState: {
    patientProcedure: null,
    loading: {
      patientProcedure: false
    },
    error: {
      patientProcedure: null
    }
  },

  reducers: {
    setpatientProcedure(state, action) {
      state.data = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(addPatientProcedure.pending, (state) => {
        state.loading.patientProcedure = true;
        state.error.patientProcedure = null;
      })
      .addCase(addPatientProcedure.fulfilled, (state, action) => {
        state.loading.patientProcedure = false;
        state.patientProcedure = action.payload;
      })
      .addCase(addPatientProcedure.rejected, (state, action) => {
        state.loading.patientProcedure = false;
        state.error.patientProcedure = action.payload;
      });

    builder
      .addCase(deletePatientProcedure.pending, (state) => {
        state.loading.patientProcedure = true;
      })
      .addCase(deletePatientProcedure.fulfilled, (state, action) => {
        state.loading.patientProcedure = false;
      })
      .addCase(deletePatientProcedure.rejected, (state, action) => {
        state.loading.patientProcedure = false;
      });
  }
});

export const selectPatientProcedure = (state) => state.patientProcedure.patientProcedure;
export const selectPatientProcedureLoading = (state) => state.patientProcedure.loading;
export const selectPatientProcedureError = (state) => state.patientProcedure.error;
export const { setpatientProcedure } = patientProcedureSlice.actions;
export default patientProcedureSlice.reducer;
