import React, { useEffect, useState } from "react";
import "./ExamsAndHygiene.css";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { useDispatch, useSelector } from "react-redux";
import { selectPatientRecall } from "src/store/slices/patientSlice";
import { getPatientRecall } from "src/store/actions/patientAction";

const ExamsAndHygiene = ({ patientId = { patientId } }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // If patient data is not already in the Redux store, fetch it
    dispatch(getPatientRecall(patientId));
  }, [dispatch, patientId]);

  const examsAndHygieneData = useSelector((state) => selectPatientRecall(state));

  const examsAndHygieneColumns = [
    { field: "treatmentName", title: "Treatment" },
    { field: "datePrevious", title: "Previous" },
    { field: "dateDue", title: "Due" },
    { field: "dateScheduled", title: "Scheduled" },
    { field: "intervalDays", title: "Interval" },
    { field: "followUp", title: "FollowUp" },
    { field: "note", title: "Comment" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <div className="exams-and-hygiene-main-wrapper">
      <DynamicGrid
        columns={examsAndHygieneColumns}
        rows={examsAndHygieneData}
        actions={actions}
        allowSort={false}
        allowFilter={false}
        pageSize={10}
        pageable={true}
      />
    </div>
  );
};

export default ExamsAndHygiene;
