import httpClient from "./http-client";

const API_BASE_URL =  "/PatientVitalSign"; // Base endpoint for Patient APIs

export const VitalSignsService = {
  /**
   * Fetch vital signs for a specific patient
   * @param {number} patientId
   * @returns {Promise<any>}
   */
  getVitalSignsByPatientId: async (patientId) => {
    return await httpClient(`${API_BASE_URL}/getallpatientvitalsigns/${patientId}`, {
      method: "GET"
    });
  },

  /**
   * Save or update a vital sign record
   * @param {Object} vitalSignData
   * @returns {Promise<any>}
   */
  saveVitalSign: async (vitalSignData) => {
    return await httpClient(`${API_BASE_URL}/managepatientvitalsign`, {
      method: "POST",
      body: vitalSignData
    });
  }
};
