import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "src/services/http-client";
export const fetchFamilyVisitDetails = createAsyncThunk(
  "patient/fetchFamilyVisitDetails",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(`/PatientVisit/getFamilyVisitDetails/${patientId}`); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching Family Visit details");
    }
  }
);

export const fetchEditVisitDetails = createAsyncThunk(
  "patient/fetchEditVisitDetails",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(`/PatientVisit/getVisitDetails/${patientId}`); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching Family Visit details");
    }
  }
);

export const updateVisitDetails = createAsyncThunk(
  "patient/updateVisitDetails",
  async (patientVisit, { rejectWithValue }) => {
    try {
      const response = await httpClient.put(`/PatientVisit/updatePatientVisit`, patientVisit); // Replace with your API endpoint
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error updaing Visit details");
    }
  }
);
