import { createSlice } from "@reduxjs/toolkit";
import {
  fetchClinicians,
  addClinician,
  updateClinician,
  manageProvider,
  fetchSpecialties,
  fetchProviderFee
} from "../actions/clinicianActions";

const initialState = {
  clinicians: [],
  specialties: [],
  providerFee: [],
  loading: false,
  error: null
};

const clinicianSlice = createSlice({
  name: "clinicians",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClinicians.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClinicians.fulfilled, (state, action) => {
        state.loading = false;
        state.clinicians = action.payload;
      })
      .addCase(fetchClinicians.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder

      .addCase(manageProvider.pending, (state) => {
        state.loading = true;
      })
      .addCase(manageProvider.fulfilled, (state, action) => {
        state.loading = false;
        state.clinicians.push(action.payload);
      })
      .addCase(manageProvider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchSpecialties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpecialties.fulfilled, (state, action) => {
        state.loading = false;
        state.specialties = action.payload; // Store specialties in the state
      })
      .addCase(fetchSpecialties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchProviderFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProviderFee.fulfilled, (state, action) => {
        state.loading = false;
        state.providerFee = action.payload;
      })
      .addCase(fetchProviderFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default clinicianSlice.reducer;
